import { MutableRefObject } from 'react';
import { GridApi } from '@mui/x-data-grid-premium';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useStore } from 'effector-react';
import { appStore } from 'shared/store';
import { Text } from 'entities/Text';

export const getInitialState = (apiRef: MutableRefObject<GridApi>) => ({
  apiRef,
  initialState: {
    rowGrouping: {
      model: ['rotePlan'],
    },
    sort: {
      sortModel: [{ field: '__row_group_by_columns_group__', sort: 'asc' }],
    },
  },
});

// TODO: 99 check if its possible to move StatusComponent to entities
export const StatusComponent = (el: any) => {
  const t = useTranslations();
  const { shippingPoint: shippingPointName } = useStore(appStore);

  const products = el.row?.products?.filter((p: any) => p.shippingPointName === shippingPointName);

  if (!products) {
    return ''; // used for Grouping
  }
  if (
    products?.every((order: any) => {
      return order.productStatus === 'DONE';
    })
  ) {
    return <Text style={{ color: '#2E7D32' }}>{t('productStatus.DONE')}</Text>;
  }
  if (
    products?.every((order: any) => {
      return order.productStatus === null;
    })
  ) {
    return <Text>{t('productStatus.TO_DO')}</Text>;
  }
  return <Text style={{ color: '#0288D1' }}>{t('productStatus.IN_PROGRESS')}</Text>;
};

export const columns = (t: (v: string) => string) => [
  {
    field: 'customerNum',
    headerName: t('common.customerNumber'),
    width: 150,
  },
  {
    field: 'internalName',
    headerName: t('common.companyName'),
    flex: 1,
  },
  {
    field: 'deliveryNoteNum',
    headerName: t('common.deliveryNote'),
    width: 150,
  },
  {
    field: 'productStatus',
    headerName: t('common.productStatus'),
    width: 150,
    renderCell: StatusComponent,
  },
  {
    field: 'rotePlan',
    headerName: t('distribution.rotePlan'),
    width: 200,
  },
  {
    field: 'shippingPointName', // this column is hidden by default. its needed for correct filtering
    headerName: t('common.shippingPoint'),
    width: 200,
    valueGetter: (e: any) => {
      return [
        ...new Set(e?.row?.products?.map((product: any) => product?.shippingPointName)),
      ]?.join(', ');
    },
  },
];

export const distrGridColumnVM = {
  shippingPointName: false,
  // mobileCell: false,
  rotePlan: false,
};
