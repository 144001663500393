import { FC, useEffect, useState } from 'react';
import { columns } from './consts';
import Button from '@mui/lab/LoadingButton';
import { useTranslations } from 'shared/hooks/useTranslations';
import { RenderIf } from 'shared/helpers/renderIf';
import { Table } from 'entities/Table';
import { useStore } from 'effector-react';
import { globalStore } from 'shared/store';
import { loadAllProducts } from 'shared/store/globalStore';
import { Product } from './types';
import { AllProductsListType } from 'shared/types';

export const ProductsTable: FC<IProductsTable> = ({ onDelete, selectedProducts }) => {
  const t = useTranslations();
  const { allProductsList: options } = useStore(globalStore);

  const [dataToShow, setDataToShow] = useState<AllProductsListType>([]);

  useEffect(() => {
    loadAllProducts();
  }, []);

  useEffect(() => {
    setDataToShow(
      options?.reduce((acc: any[], curr) => {
        const foundProduct = selectedProducts?.find(
          (p) => Number(p?.linkArticle) === Number(curr?.id),
        );
        if (foundProduct) {
          return [...acc, { ...curr, quantity: foundProduct.quantity }];
        }
        return acc;
      }, []),
    );
  }, [options, selectedProducts]);

  return (
    <>
      <RenderIf condition={Boolean(dataToShow?.length)}>
        <Table
          sx={{ marginBottom: '24px', width: '100%' }}
          rows={dataToShow}
          columns={columns(t, onDelete)}
          getRowId={(item: any) => item.id}
        />
      </RenderIf>
      <RenderIf condition={Boolean(!dataToShow?.length)}>
        <Button
          loading
          variant="outlined"
          sx={{ width: '100%', height: '75px', marginBottom: '24px' }}
        />
      </RenderIf>
    </>
  );
};

interface IProductsTable {
  selectedProducts: Product[];
  onDelete?: (productId: string) => void;
}
