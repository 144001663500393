import { AvailableStatus, UpdateProductMutationVariables } from 'graphql/generatedModel';
import { handleLocalStorage } from '../handleLocalStorage';

export const getOrdersToEditForDistList = (
  ordersList: any[],
  // TODO: products must no longer be an array
  products: UpdateProductMutationVariables[],
) => {
  const shippingPointName = handleLocalStorage('shippingPointName');

  return [
    ...new Set(
      products
        ?.map((product) => {
          const fields = product.fields as [];
          return fields?.map((f: any) => f.orderId);
        })
        .flat(Infinity),
    ),
  ]
    .map((orderId) => {
      const foundOrder = ordersList?.find((order) => `${order?.orderId}` === `${orderId}`);
      if (foundOrder) {
        return {
          orderId: foundOrder.orderId,
          positionParts: foundOrder.products
            ?.map((fOp: any) => {
              if (
                //   products?.some((p) => p.productId === fOp.articleId) &&
                fOp?.shippingPointName === shippingPointName
              ) {
                return {
                  linkArticle: `${fOp?.articleId}`,
                  description: `${fOp?.description}`,
                  newQuantity: Number(fOp?.distributed),
                  partNum: foundOrder.deliveryCycleNum,
                  productStatus: fOp?.productStatus,
                };
              } else {
                return null;
              }
            })
            .filter((el: any) => el),
        };
      } else {
        return null;
      }
    })
    .filter((el) => {
      return (
        el?.positionParts?.length &&
        el?.positionParts?.every((pp: any) => pp?.productStatus === AvailableStatus.DONE) &&
        el?.positionParts?.some((pp: any) =>
          products?.some((p) => `${p.productId}` === `${pp.linkArticle}`),
        )
      );
    })
    .map((order) => ({
      ...order,
      positionParts: order?.positionParts?.map(({ productStatus, ...rest }: any) => rest),
    }));
};
