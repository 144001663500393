import { FC } from 'react';
import { RenderIf } from 'shared/helpers/renderIf';
import { DesktopSidebar } from './DesktopSidebar';
import { getLayout } from 'shared/helpers/getLayout';
import { ModileSidebar } from './MobileSidebar';

export const SideBar: FC = ({ children }) => {
  return (
    <>
      <RenderIf condition={getLayout() === 'DESKTOP'}>
        <DesktopSidebar>{children}</DesktopSidebar>
      </RenderIf>
      <RenderIf condition={getLayout() === 'MOBILE'}>
        <ModileSidebar>{children}</ModileSidebar>
      </RenderIf>
    </>
  );
};
