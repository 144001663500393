import { Button } from 'entities/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { RenderIf } from 'shared/helpers/renderIf';
import { toggleDoneShown } from 'shared/store/productionListStore';
import { productionStore } from 'shared/store';
import { useStore } from 'effector-react';

export const FilterProdList = () => {
  const { isDoneShown } = useStore(productionStore);

  return (
    <Button
      height="56px"
      width="56px"
      color={isDoneShown ? 'error' : 'primary'}
      variant={isDoneShown ? 'contained' : 'outlined'}
      handleClick={toggleDoneShown}
    >
      <RenderIf condition={!isDoneShown}>
        <FilterAltIcon />
      </RenderIf>
      <RenderIf condition={isDoneShown}>
        <FilterAltOffIcon />
      </RenderIf>
    </Button>
  );
};
