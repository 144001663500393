import { Auth as aws } from 'aws-amplify';
import { setHasAccess, setIsLoggedIn } from 'shared/store/appStore';

export const checkIsLoggedIn = async () => {
  const currentUser = await aws.currentAuthenticatedUser();
  setIsLoggedIn(Boolean(currentUser));
  try {
    if (currentUser?.attributes) {
      const { attributes } = currentUser;
      if (attributes?.['custom:role'] === 'admin') {
        setHasAccess(true);
        return;
      }
      const rights = attributes['custom:access_rights'];
      const { chc_sa, faw } = JSON.parse(rights);
      setHasAccess(chc_sa === 'trusted' || (faw && faw !== 'no'));
      return;
    }
  } catch (e) {
    setHasAccess(false);
  }
};
