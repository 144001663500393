import { Text } from 'entities/Text';
import styled from 'styled-components';

type IndicatorProps = {
  isActive?: boolean;
};

// NAV

export const Divider = styled.div`
  width: 52px;
  height: 1px;
  background-color: #bdbdbd;
  margin-left: 8px;
  @media (max-width: 675px) {
    display: none;
  }
`;

export const Indicator = styled('div')<IndicatorProps>`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  margin: 0 8px;
  background: ${({ isActive }) => (isActive ? '#2196f3' : 'grey')};
  @media (max-width: 675px) {
    /* display: none; */
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 75px;
  @media (max-width: 675px) {
    flex-direction: column;
  }
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (max-width: 675px) {
    flex-direction: row;
    width: 224px;
    margin-bottom: 10px;
  }
`;

export const NavText = styled(Text)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
  white-space: nowrap;
`;

// SELECT CUSTOMER

export const Contanier = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectContanier = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin: 0 0 44px;
  width: 100%;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const StepName = styled(Text)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin: 20px 0;
  width: 100%;
`;

// ADD PRODUCTS

export const AddProductSelectContanier = styled(SelectContanier)`
  margin: 30px 0;
  width: 100%;
`;

export const AddProductsContanier = styled(Contanier)`
  align-items: flex-start;
`;

// CONFIRM

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
`;
