import { FC, useEffect, useState } from 'react';
import { ButtonsContainer } from './styles';
import { useStore } from 'effector-react';
import { productionStore } from 'shared/store';
import { PrintButton } from 'features/PrintButton';
import { RenderIf } from 'shared/helpers/renderIf';
import { startModalStore } from 'shared/store';
import { EditOrderButton } from './editDistributedButton';
import { MoveToDoneButton } from './moveToDone';
import { ResetButton } from './resetButton';
import { ReplaceButton } from './ReplaceButton';
import { AvailableStatus } from 'graphql/generatedModel';
import { SkipButton } from './SkipButton';

// TODO: 99 move component to widgets

export const ModalButtons: FC<IModalButtons> = ({
  startClosing,
  closeMotherModal,
  ordersToDisplay,
  children,
}) => {
  //
  // Store
  const { currentOpenProduct } = useStore(productionStore);
  const { productsToUpdateDistributed: productsToUpdate } = useStore(startModalStore);

  const [dataToPrint, setDataToPrint] = useState<any>();

  // useEffects
  useEffect(() => {
    if (currentOpenProduct) {
      setDataToPrint({
        productNr: `${currentOpenProduct?.articleId}`,
        productName: currentOpenProduct?.description,
        shops: ordersToDisplay?.map((order: any) => ({
          count: getNumberToPrint(order),
          shopNr: order.customerNum,
          shopName: order.internalName,
        })),
      });
    }
  }, [currentOpenProduct, ordersToDisplay]);

  const getNumberToPrint = (order: any) => {
    switch (order.productStatus) {
      case AvailableStatus.TO_DO:
      case null:
        return order.justProduced;
      case AvailableStatus.IN_PROGRESS:
        return order.toDistribute;
      case AvailableStatus.PENDING:
        return order.distributed;
      case AvailableStatus.DONE:
        return order.distributed;
      default:
        return order.amount;
    }
  };

  const isMoveToDoneVisible = (orders: any) => {
    let donePendingCount = 0;
    let hasPending = false;

    orders?.forEach((order: any) => {
      if (
        order.productStatus === AvailableStatus.DONE ||
        order.productStatus === AvailableStatus.PENDING
      ) {
        donePendingCount++;
        if (order.productStatus === AvailableStatus.PENDING) {
          hasPending = true;
        }
      }
    });

    return donePendingCount === orders?.length && hasPending;
  };

  return (
    <ButtonsContainer>
      {children}
      {/* EDIT BUTTON */}
      <RenderIf condition={Boolean(productsToUpdate?.length)}>
        <EditOrderButton closeMotherModal={startClosing || closeMotherModal} />
      </RenderIf>

      {/* MoveToDoneButton */}
      {/* <RenderIf condition={Boolean(getProductStatus(currentOpenProduct) === 'Pending')}> */}
      <RenderIf condition={isMoveToDoneVisible(currentOpenProduct?.productionListOrders)}>
        <MoveToDoneButton closeMotherModal={closeMotherModal} />
      </RenderIf>

      {/* PRINT BUTTON */}
      <PrintButton dataToPrint={dataToPrint} />

      {/* RESTART BUTTON */}
      <RenderIf
        condition={Boolean(
          currentOpenProduct?.productionListOrders?.some(
            (order: any) => order.productStatus && order.productStatus !== 'To do',
          ),
        )}
      >
        <ResetButton closeMotherModal={closeMotherModal} />
      </RenderIf>

      {/* REPLACE BUTTON */}
      <RenderIf
        condition={Boolean(
          currentOpenProduct?.productionListOrders?.every(
            (order: any) => !order.productStatus || order.productStatus === 'To do',
          ),
        )}
      >
        <ReplaceButton closeMotherModal={closeMotherModal} />
      </RenderIf>

      {/* SKIP BUTTON */}
      <RenderIf
        condition={Boolean(
          currentOpenProduct?.productionListOrders?.every(
            (order: any) => !order.productStatus || order.productStatus === 'To do',
          ),
        )}
      >
        <SkipButton closeMotherModal={closeMotherModal} ordersToDisplay={ordersToDisplay} />
      </RenderIf>
    </ButtonsContainer>
  );
};

interface IModalButtons {
  startClosing?: () => void;
  closeMotherModal: () => void;
  ordersToDisplay: any[];
}
