import { FC, ReactNode } from 'react';
import ModalComponent from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { style } from './styles';

type UserAccountModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const Modal: FC<UserAccountModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <ModalComponent
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </ModalComponent>
  );
};
