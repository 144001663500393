import { Button } from 'entities/Button';
import EditIcon from '@mui/icons-material/Edit';
import { RenderIf } from 'shared/helpers/renderIf';
import { OrderModalButtonsContainer } from './styles';
import { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { distributionStore } from 'shared/store';
import { globalStore } from 'shared/store';
import { DATE_FORMAT } from 'shared/globalConsts';
import { EditedProduct } from 'widgets/DistributionListModals/OrderModal/types';
import { editOrders } from 'shared/api';
import { OrdersList, ProductInDistrList } from 'shared/types';
import { PrintButton } from 'features/PrintButton';
import { ArrElement } from 'shared/helpers/getTypeFromArray';
import { getProductionList } from 'shared/store/productionListStore';
import { getDistributionList } from 'shared/store/distributionListStore';

export const OrderModalButtons: FC<IOrderModalButtons> = ({
  productsData,
  productsToUpdate,
  discardProducts,
}) => {
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const unformattedDate = useStore(globalStore).date;
  const { openOrderId, lists } = useStore(distributionStore);
  const { selectedPart } = useStore(globalStore);

  const [isEditLoading, setIsEditLoading] = useState(false);
  //
  const [dataToPrint, setDataToPrint] = useState<any>();
  const [currentOrder, setCurrentOrder] = useState<ArrElement<OrdersList>>();

  // useEffect
  useEffect(() => {
    const foundOrder = lists?.[date]?.ordersList?.find((order) => order?.orderId === openOrderId);
    setCurrentOrder(foundOrder);
  }, [openOrderId, lists, date]); // eslint-disable-line

  useEffect(() => {
    if (currentOrder && productsData) {
      setDataToPrint({
        productNr: currentOrder?.orderId,
        productName: currentOrder?.internalName,
        shops: productsData?.map((product) => ({
          count: product.sumAmount || 0,
          shopNr: product.articleNum,
          shopName: product.description,
        })),
      });
    }
  }, [currentOrder, productsData]);

  // editing after done for distr list
  const handleSaveDistributed = async () => {
    if (openOrderId && !isEditLoading && productsToUpdate?.length) {
      setIsEditLoading(true);

      await editOrders({
        orders: [
          {
            orderId: openOrderId,
            positionParts: productsToUpdate?.map((el) => ({
              linkArticle: el?.id,
              description: el?.description,
              newQuantity: Number(el?.producedAmount || 0),
              partNum: selectedPart,
            })),
          },
        ],
      });
      getProductionList({ date: unformattedDate, forceLoad: true });
      getDistributionList({ date: unformattedDate, forceLoad: true });

      setIsEditLoading(false);
      discardProducts();
    }
  };

  return (
    <OrderModalButtonsContainer>
      <RenderIf condition={Boolean(productsToUpdate?.length)}>
        <Button
          color="success"
          variant="contained"
          height="56px"
          width="56px"
          isLoading={isEditLoading}
          sx={{ marginBottom: '20px' }}
          handleClick={handleSaveDistributed}
        >
          <EditIcon />
        </Button>
      </RenderIf>
      <PrintButton dataToPrint={dataToPrint} />
    </OrderModalButtonsContainer>
  );
};

interface IOrderModalButtons {
  productsData: ProductInDistrList[];
  productsToUpdate: EditedProduct[];
  discardProducts: () => void;
}
