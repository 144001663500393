import { FC, useCallback, useState } from 'react';
import { Table } from 'entities/Table';
import { OrderModalButtons } from 'features/OrderModalButtons';

import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { useTranslations } from 'shared/hooks/useTranslations';
import { filterByShippingPoint } from 'shared/helpers/filterByShippingPoint';
import { openProduct } from 'shared/store/distributionListStore';

import { useGridApiRef } from '@mui/x-data-grid-premium';

import { EditedProduct, IOrderModalComponent } from './types';
import { columns, orderColumnVM } from './consts';
import { OrdersModalContainer } from './styles';
import { getLayout } from 'shared/helpers/getLayout';

export const OrderModalComponent: FC<IOrderModalComponent> = ({ productsData }) => {
  //
  // Hooks
  const t = useTranslations();
  const apiRef = useGridApiRef();

  // State
  const [productsToUpdate, setProductsToUpdate] = useState<EditedProduct[]>([]);

  // Functions
  const handleOpenProduct = (e: any) => {
    if (e.row.productStatus !== 'DONE' && e.row.productStatus !== 'Done') {
      openProduct(e?.row?.articleId || null);
    }
  };

  const handleRowEditCommit = useCallback(
    (newValue, oldValue) => {
      if (newValue?.distributed !== oldValue?.distributed) {
        const value = newValue.distributed;
        setProductsToUpdate((prevValue: EditedProduct[]) => {
          const neededProduct = productsData?.find((p: any) => p.articleId === newValue.articleId);
          if (neededProduct) {
            const isValueSame =
              Number(neededProduct.distributed) === Number(newValue.distributed) &&
              neededProduct.description === newValue.description;
            const isRecordExisting = prevValue?.some((order) => order.id === newValue.articleId);
            if (isValueSame) {
              return [...prevValue.filter((el) => el.id !== newValue.articleId)];
            }
            if (!isValueSame && isRecordExisting) {
              return prevValue?.map((el) => {
                if (el.id === newValue.articleId) {
                  return { ...el, producedAmount: value };
                }
                return el;
              });
            }
            if (!isValueSame && !isRecordExisting) {
              return [
                ...prevValue,
                {
                  id: newValue.articleId,
                  producedAmount: value,
                  description: newValue.description,
                },
              ];
            }
            return prevValue;
          } else {
            return prevValue;
          }
        });
        return { ...newValue, isNew: false };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsData, productsToUpdate],
  );

  return (
    <OrdersModalContainer isMobile={getLayout() === 'MOBILE'}>
      <OrderModalButtons
        productsData={productsData}
        productsToUpdate={productsToUpdate}
        discardProducts={() => setProductsToUpdate([])}
      />
      <Table
        apiRef={apiRef}
        onRowClick={handleOpenProduct}
        getRowId={(item: any) => `${item.articleId}_${item.description}`}
        //
        columns={columns(t, [])}
        rows={productsData || []}
        //
        filterModel={filterByShippingPoint()}
        // onFilterModelChange={onFilterModelChange}
        //
        columnVisibilityModel={orderColumnVM}
        // onColumnVisibilityModelChange={setColumnVM}
        //
        // slots={GridToolbar}
        //
        processRowUpdate={handleRowEditCommit}
        onProcessRowUpdateError={(error: any) => console.log(error?.message)}
        isCellEditable={(e: any) =>
          e?.field === 'distributed' &&
          (e?.row?.productStatus === 'Done' || e?.row?.productStatus === 'DONE')
        }
      />
    </OrdersModalContainer>
  );
};

export const OrderModal = withModalHOC(OrderModalComponent);
