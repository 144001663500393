import { FC } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

export const Button: FC<IButton> = ({
  handleClick,
  height,
  width,
  isLoading,
  isDisabled,
  variant,
  color,
  sx = {},
  children,
}) => {
  return (
    <LoadingButton
      color={color ?? 'primary'}
      variant={variant ?? 'contained'}
      loading={isLoading}
      disabled={isDisabled}
      onClick={handleClick}
      sx={{
        ...sx,
        minWidth: 'unset',
        height: height ?? '42px',
        width: width ?? '240px',
      }}
    >
      {children}
    </LoadingButton>
  );
};

interface IButton {
  handleClick: () => void;
  height?: string;
  width?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  sx?: Object;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'error' | 'info' | 'inherit' | 'primary' | 'secondary' | 'success' | 'warning';
}
