import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 76px;
  right: 25px;

  display: flex;
  gap: 20px;

  // TODO: use global var instead of 800px
  @media (max-width: 800px) {
    top: -10px;
    right: 0px;
  }
`;
