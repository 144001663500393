import { FC, useMemo, useState } from 'react';
import Button from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { AvailableStatus, PageNamesEnum } from 'graphql/generatedModel';
import { useTranslations } from 'shared/hooks/useTranslations';
import { appStore, globalStore, updateOrders } from 'shared/store';
import { useStore } from 'effector-react';
import { DATE_FORMAT } from 'shared/globalConsts';
import { distributionStore } from 'shared/store';
import { Text } from 'entities/Text';
import { ButtonsContainer, MainContainer } from './styles';
import { getLayout } from 'shared/helpers/getLayout';

export const ProductModalComponent: FC<IProductModalComponent> = ({ onClose }) => {
  // Hooks
  const t = useTranslations();

  // Store
  const { selectedPart } = useStore(globalStore);
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const { shippingPoint: shippingPointName } = useStore(appStore);
  const { openOrderId, openProductId, lists } = useStore(distributionStore);

  // State
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // useMemo
  const product = useMemo(() => {
    const foundOrder = lists?.[date]?.ordersList?.find((order) => order?.orderId === openOrderId);
    const foundProduct = foundOrder?.products?.find(
      (product) => product?.articleId === openProductId,
    );
    setValue(`${Number(foundProduct?.sumAmount || 0) - Number(foundProduct?.distributed || 0)}`);
    return foundProduct;
  }, [date, lists, openOrderId, openProductId]);

  // Functions
  const handleInput = (e: any) => {
    if (Number(e.target.value) > 0) {
      setValue(e.target.value);
    } else {
      setValue('');
    }
  };

  const moveToPending = () => {
    updateProduct(AvailableStatus.PENDING);
  };

  const moveToDone = () => {
    updateProduct(AvailableStatus.DONE);
  };

  const updateProduct = async (productStatus: any) => {
    const distributedTotal = Number(product?.distributed || 0) + Number(value || 0);
    setIsLoading(true);
    ///
    await updateOrders({
      date,
      productId: Number(product?.articleId || 0),
      productName: `${product?.description}`,
      pageName: PageNamesEnum.DISTRIBUTION,
      shippingName: shippingPointName || '',
      fields: [
        {
          distributed: distributedTotal,
          orderId: Number(openOrderId),
          partNum: selectedPart,
          toDistribute: 0,
          productStatus,
        },
      ],
    });

    setIsLoading(false);
    onClose();
  };

  return (
    <MainContainer>
      <Text>{t('distribution.howManyItemsWereDistributed')}</Text>
      <TextField
        autoFocus
        value={value}
        onChange={handleInput}
        type="number"
        sx={{ width: '100%', margin: '40px 150px', input: { textAlign: 'center' } }}
      />
      <ButtonsContainer>
        <Button
          onClick={moveToPending}
          variant="outlined"
          // loading={isLoading}
          disabled={
            Number(product?.distributed) + Number(value || 0) >= Number(product?.sumAmount || 0) ||
            isLoading
          }
          sx={{
            height: '42px',
            width: getLayout() === 'DESKTOP' ? '200px' : 'calc(100vw - 40px)',
          }}
        >
          {t('distribution.moveToPending')}
        </Button>
        <Button
          onClick={moveToDone}
          variant="contained"
          loading={isLoading}
          disabled={Boolean(!value.length || isLoading)}
          sx={{
            height: '42px',
            width: getLayout() === 'DESKTOP' ? '200px' : 'calc(100vw - 40px)',
          }}
        >
          {t('distribution.moveToDone')}
        </Button>
      </ButtonsContainer>
    </MainContainer>
  );
};

interface IProductModalComponent {
  onClose: () => void;
}

export const ProductModal = withModalHOC(ProductModalComponent);
