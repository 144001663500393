import { ProductionList } from 'shared/types';
import { getProductStatus } from './getProductStatus';
import { handleLocalStorage } from './handleLocalStorage';

export const prepareProductsForTable = (
  orderedArticles: ProductionList = [],
  searchText = '',
  selectedPart: number,
  isDoneShown: boolean,
): ProductionList => {
  const shippingPointName = handleLocalStorage('shippingPointName');

  return orderedArticles
    ?.filter((el) => {
      const isFilteredByText =
        el?.description?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) ||
        el?.articleNum?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase());
      const isShippingPoontFiltered = el?.shippingPointName === shippingPointName;
      const isShownByDone = isDoneShown || getProductStatus(el) !== 'Done';
      const isShownBySelectedPart = el?.deliveryCycleNum
        ? el?.deliveryCycleNum === selectedPart
        : el;
      return isFilteredByText && isShippingPoontFiltered && isShownByDone && isShownBySelectedPart;
    })
    ?.sort((a: any, b: any) => {
      const productStatusA = getProductStatus(a);
      const productStatusB = getProductStatus(b);
      const aIsToDo = productStatusA === 'To do' || !productStatusA;
      const bIsToDo = productStatusB === 'To do' || !productStatusB;

      const aIsDone = productStatusA === 'Done';
      const bIsDone = productStatusB === 'Done';

      if (aIsDone && !bIsDone) return 1; // b comes before a
      if (!aIsDone && bIsDone) return -1; // a comes before b
      if (aIsToDo && !bIsToDo && !bIsDone) return 1; // b comes before a
      if (!aIsToDo && !aIsDone && bIsToDo) return -1; // a comes before b
      return 0;
    });
};
