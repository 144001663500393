import { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PlayerContainer } from './player';
import * as contentLoader from './content-loader.json';

export const Loader: FC<ILoaderProps> = ({
  animationJson,
  w,
  h,
  children,
  isLoading = true,
  useAnimationAppearance = true,
  animationDuration = 300,
  zIndex = 1000,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(isLoading);

  const initLoadingChanged = useRef<boolean>(false);

  let timer: ReturnType<typeof setTimeout> | undefined = undefined;
  useEffect(() => {
    initLoadingChanged.current = true;
    if (useAnimationAppearance) {
      if (isLoading) {
        if (timer) {
          clearTimeout(timer);
        }
        setShow(isLoading);
      } else {
        // Unmount player after fade out animation finish
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setTimeout(() => {
          setShow(isLoading);
        }, animationDuration);
      }
    }
  }, [isLoading]);
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showPlayer = useAnimationAppearance ? show : isLoading;
  const animationAppearance = useAnimationAppearance
    ? !initLoadingChanged.current && isLoading // Don't use fade in animation if isLoading is true on init
      ? 'none'
      : isLoading
      ? 'fadeIn'
      : 'fadeOut'
    : 'none';

  return (
    <Wrapper>
      {showPlayer ? (
        <PlayerContainer
          defaultAnimation={JSON.stringify(contentLoader)}
          {...(animationJson && { customAnimation: JSON.stringify(animationJson) })}
          animationAppearance={animationAppearance}
          animationDuration={animationDuration}
          zIndex={zIndex}
          {...props}
        />
      ) : null}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

type tpJSON = {
  [key: string]: any;
};

export interface ILoaderProps {
  isLoading: boolean;
  animationJson?: tpJSON | string;
  w?: number | undefined;
  h?: number | undefined;
  useAnimationAppearance?: boolean;
  animationDuration?: number;
  zIndex?: number;
}
