import { Text } from 'entities/Text';
import { Calendar } from 'widgets/Calendar';
import { MobileHeaderChildrenContainer, MobileHeaderLayout } from './styles';
import { FC } from 'react';

export const MobileHeader: FC<IMobileHeader> = ({ pageName, children }) => {
  return (
    <MobileHeaderLayout>
      {/* TODO: translations */}
      <Text
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontWeight: '500',
          lineHeight: '32px',
          textAlign: 'left',
          paddingLeft: '15px',
        }}
      >
        {pageName}
      </Text>
      <Calendar />
      <MobileHeaderChildrenContainer>{children}</MobileHeaderChildrenContainer>
    </MobileHeaderLayout>
  );
};

interface IMobileHeader {
  pageName?: string;
}
