import CancelIcon from '@mui/icons-material/Cancel';
import { InfoContainer, HeaderText, SubHeaderText } from './styles';
import { useTranslations } from 'shared/hooks/useTranslations';

export const NoOrders = () => {
  const t = useTranslations();

  return (
    <InfoContainer>
      <CancelIcon sx={{ fontSize: '5rem', color: '#D32F2F' }} />
      <HeaderText>{t('production.noOrdersForThisDay')}!</HeaderText>
      <SubHeaderText>{t('production.chooseAnotherDate')}</SubHeaderText>
    </InfoContainer>
  );
};
