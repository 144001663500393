import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { ProductModal } from 'widgets/DistributionListModals/ProductModal';
import { OrderModal } from 'widgets/DistributionListModals/OrderModal';
import { DATE_FORMAT } from 'shared/globalConsts';
import { globalStore } from 'shared/store';
import { distributionStore } from 'shared/store';
import { closeOrder, closeProduct } from 'shared/store/distributionListStore';
import { ProductInDistrList } from 'shared/types';

export const DistributedListModals = () => {
  // Hooks
  const { openOrderId, openProductId, lists } = useStore(distributionStore);
  const { date } = useStore(globalStore);

  // State
  const [productsList, setProductsList] = useState<ProductInDistrList[]>([]);
  const [headerTitle, setHeaderTitle] = useState<string>('');

  // useEffect
  useEffect(() => {
    const foundOrder = lists?.[date.format(DATE_FORMAT)]?.ordersList?.find(
      (order) => order?.orderId === openOrderId,
    );
    setHeaderTitle(`${foundOrder?.orderId} ${foundOrder?.internalName}`);
    setProductsList((foundOrder?.products as ProductInDistrList[]) || []);
  }, [openOrderId, lists]); // eslint-disable-line

  return (
    <>
      <OrderModal
        productsData={productsList}
        isOpen={Boolean(productsList?.length)}
        onClose={closeOrder}
        headerTitle={headerTitle}
      />
      <ProductModal isOpen={Boolean(openProductId)} onClose={closeProduct} />
    </>
  );
};
