import { registerServiceWorker } from 'shared/serviceWorker/registerServiceWorker';
import { updateHandler } from 'shared/serviceWorker/updateHandler';
import { configureAmplify } from './functions/configureAmplify';
import { checkIsLoggedIn } from './functions/checkIsLoggedIn';
import { isShippingPointSet } from './functions/checkIsShippingPointSet';
import { getAuthToken } from './functions/getAuthToken';
import { getPrinterData, initTranslations } from 'shared/store/settingsStore';
import { setLicenseKey } from './functions/setLicenseKey';
import { errorHandler } from 'shared/helpers/errorHandler';
import { loadNotifications } from 'shared/store/notificationsStore';
import { checkSelectedTheme } from './functions/checkSelectedTheme';

type LoadInitDataType = () => Promise<void>;
type LoadSecondaryDataType = () => Promise<void>;

export const initApp = (
  setIsLoading: (value: boolean) => void,
): [LoadInitDataType, LoadSecondaryDataType] => {
  const loadInitData = async () => {
    try {
      await firstStep();
      await secondStep();
      await thirdStep();
    } catch (e) {
      errorHandler(e);
      // history.push('/404');
    } finally {
      setIsLoading(false);
    }
  };
  const firstStep = async () => await Promise.all([setLicenseKey(), configureAmplify()]);

  const secondStep = async () =>
    await Promise.all([
      registerServiceWorker({ onUpdate: updateHandler }),
      checkIsLoggedIn(),
      getAuthToken(),
    ]);

  const thirdStep = async () =>
    await Promise.all([
      isShippingPointSet(),
      initTranslations(),
      checkSelectedTheme(),
      loadNotifications(),
    ]);

  // Functions
  const loadSecondaryData = async () => {
    try {
      getPrinterData();
    } catch (e) {
      errorHandler(e);
    }
  };

  // RETURN
  return [loadInitData, loadSecondaryData];
};
