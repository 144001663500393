import { FC, useState } from 'react';
import { ButtonsContainer, Contanier } from './styles';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Button } from 'entities/Button';
import { useStore } from 'effector-react';
import { globalStore } from 'shared/store';
import { DATE_FORMAT } from 'shared/globalConsts';
import { ProductsTable } from './productsTable';
import { Product } from './types';
import { getDistributionList } from 'shared/store/distributionListStore';
import { createOrder } from 'shared/api';
import { Loader } from 'app/pageLoader/loaderComponent';

export const Confirm: FC<ConfirmProps> = ({
  toPrevStep,
  closeModal,
  customerId,
  selectedProducts,
}) => {
  const t = useTranslations();
  const date = useStore(globalStore).date;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreateOrder = async () => {
    if (customerId && date) {
      setIsLoading(true);
      await createOrder({
        params: {
          date: date.format(DATE_FORMAT),
          customerId: `${customerId}`,
          positions: selectedProducts,
        },
      });
      // TODO: handle state directly instead of another request
      await getDistributionList({ date, forceLoad: true });
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <Contanier>
      <Loader isLoading={isLoading} />
      <ProductsTable selectedProducts={selectedProducts} />
      <ButtonsContainer>
        <Button isDisabled={isLoading} variant="outlined" width="100%" handleClick={toPrevStep}>
          {t('buttons.back')}
        </Button>
        <Button
          isLoading={isLoading}
          variant="contained"
          width="100%"
          handleClick={handleCreateOrder}
          isDisabled={Boolean(!selectedProducts.length)}
        >
          {t('buttons.save')}
        </Button>
      </ButtonsContainer>
    </Contanier>
  );
};

interface ConfirmProps {
  toPrevStep: () => void;
  closeModal: () => void;
  selectedProducts: Product[];
  customerId?: number;
}
