import { FC, useEffect, useMemo, useRef, ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Loader } from './loaderComponent';

const animationDuration: number = 500;

export const PageLoader: FC<IPageLoaderProps> = ({ isLoading, children }) => {
  const loadingUpdated = useRef<number>(new Date().getTime());
  useEffect(() => {
    loadingUpdated.current = new Date().getTime();
  }, [isLoading]);

  // Don't fire fade out animation if fade in isn't finished yet to avoid jumps
  const runFadeOutAnimation = useMemo(() => {
    return new Date().getTime() - loadingUpdated.current > animationDuration;
  }, [isLoading]); // eslint-disable-line

  return (
    <SLoader
      isLoading={isLoading}
      fadeOutAnimation={!isLoading}
      useFadeOutAnimation={runFadeOutAnimation}
      animationDuration={animationDuration}
      //   animationJson={loaderAnimation}
      zIndex={10000}
    >
      {children as ReactElement}
    </SLoader>
  );
};

const loaderAnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }
  50% {
    opacity: 1;
    transform: rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
`;
const loaderAnimationOut = keyframes`
  0% {
    opacity: 1;
    scale: 1;
    transform: rotate(0deg);
  }
  50% {
    opacity: 1;
    scale: 1;
    transform: rotate(45deg);
  }
  100% {
    opacity: 0;
    scale: 0;
    transform: rotate(45deg);
  }
`;
const bgAnimationIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const bgAnimationOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const animationLoader = (props: { fadeOutAnimation: boolean; useFadeOutAnimation: boolean }) => css`
  animation: ${props.fadeOutAnimation && props.useFadeOutAnimation
      ? loaderAnimationOut
      : loaderAnimationIn}
    ${animationDuration}ms;
`;
const animationBg = (props: { fadeOutAnimation: boolean }) => css`
  animation: ${!props.fadeOutAnimation ? bgAnimationIn : bgAnimationOut} 300ms;
`;

const SLoader = styled(Loader)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors?.whiteDark};
  ${animationBg};
  animation-fill-mode: forwards;
  z-index: 9999;
  background-color: ${({ theme }) => theme.backGroundColor};
  svg {
    ${animationLoader};
    animation-fill-mode: forwards;
  }
`;

interface IPageLoaderProps {
  isLoading: boolean;
}
