import { FC } from 'react';
import { AccordionProduct } from './AccordionProduct';
import { OrdersList } from 'shared/types';

export const AccordionProducts: FC<AccordionProductsProps> = ({ items }) => {
  return (
    <>
      {items?.map((item) => (
        <AccordionProduct item={item} />
      ))}
    </>
  );
};

type AccordionProductsProps = {
  items: OrdersList;
};
