import { Text } from 'entities/Text';
import styled from 'styled-components';

type IsNotReadIconProps = {
  isRead: boolean;
};

export const NotificationsContainer = styled.div`
  height: 100vh;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoNotificationsText = styled.div`
  height: 100vh;
  /* margin: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  height: calc(100vh - 102px);
  overflow-y: scroll;
`;

export const Notification = styled.div`
  border-bottom: 1px solid rgb(224, 224, 224);
  width: 100%;
  min-height: 75px;
  display: grid;
  grid-template-columns: 35px 1fr 50px;
`;

export const IsNotReadIcon = styled('div')<IsNotReadIconProps>`
  height: 100%;
  width: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: ${({ isRead }) => (isRead ? 'lightgreen' : 'red')};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Message = styled(Text)`
  padding: 10px;
`;

export const DateTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Indicator = styled.div`
  height: 24px;
  width: 24px;
  background-color: red;
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
