import { SUNMIReport } from './classes';
import { getFormatedCurrentDateTimeForCountry } from './common';
import { gqlClient } from 'shared/gql/gqlClient';
import { printOutForCustomers } from 'shared/gql/common.gql';
import { PrintOutForCustomersMutationVariables } from 'graphql/generatedModel';

const consts = require('./consts');

export function sunmiPrint(printData: any) {
  const rBuilder = new SUNMIReport();

  rBuilder.setAlignment(consts.alignMap.LEFT);
  rBuilder.setFont({ font: consts.fontsMap.FONT_A, fontStyle: consts.fontStylesMap.DOUBLE_HEIGHT });
  rBuilder.addLine({ line: `${getFormatedCurrentDateTimeForCountry()}` });
  rBuilder.feedLines(1);
  rBuilder.setAlignment(consts.alignMap.LEFT);
  rBuilder.setFont({
    font: consts.fontsMap.FONT_A,
    fontStyle: consts.fontStylesMap.BOLD_DOUBLE_HW,
  });
  rBuilder.addLine({ line: `Produkt` });
  rBuilder.setAlignment(consts.alignMap.LEFT);
  rBuilder.setFont({ font: consts.fontsMap.FONT_A, fontStyle: consts.fontStylesMap.DOUBLE_HEIGHT });
  rBuilder.addLine({ line: `${printData['productNr']} ${printData['productName']}` });
  rBuilder.feedLines(1);
  rBuilder.setAlignment(consts.alignMap.RIGHT);
  rBuilder.setFont({ font: consts.fontsMap.FONT_A, fontStyle: consts.fontStylesMap.DOUBLE_HEIGHT });
  rBuilder.addLine({ line: `Allg. Sammelplatz` });
  rBuilder.setAlignment(consts.alignMap.LEFT);
  rBuilder.setFont({ font: consts.fontsMap.FONT_A, fontStyle: consts.fontStylesMap.DOUBLE_HEIGHT });
  rBuilder.addLine({ line: `Menge.   Bezeichnung` });

  // This part needed for spaces count calculation
  let maxShopNrLength = 0;
  for (let i in printData['shops']) {
    const currShop = printData['shops'][i];
    const shopNrStr = `${currShop['shopNr']}`;
    if (shopNrStr.length > maxShopNrLength) {
      maxShopNrLength = shopNrStr.length;
    }
  }
  maxShopNrLength += 2;
  //

  let totalCount = 0;
  for (let i in printData['shops']) {
    const currShop = printData['shops'][i];
    totalCount += Number(currShop['count']);
    const countValStr = `${currShop['count']}`;
    let countStr = ' '.repeat(6 - countValStr.length) + countValStr;
    const shopNrStr = `${currShop['shopNr']}`;
    const shopNrNameStr =
      shopNrStr + ' '.repeat(maxShopNrLength - shopNrStr.length) + currShop['shopName'];

    rBuilder.setAlignment(consts.alignMap.LEFT);
    rBuilder.setFont({
      font: consts.fontsMap.FONT_A,
      fontStyle: consts.fontStylesMap.DOUBLE_HEIGHT,
    });
    rBuilder.addLine({ line: `${countStr}   ${shopNrNameStr}` });
  }
  rBuilder.feedLines(1);
  rBuilder.setAlignment(consts.alignMap.LEFT);
  rBuilder.setFont({ font: consts.fontsMap.FONT_A, fontStyle: consts.fontStylesMap.DOUBLE_HEIGHT });
  rBuilder.addLine({ line: `Gesamt: ${totalCount}` });
  rBuilder.cutPaper();
  const rBody = rBuilder.getReportBody();
  return rBody;
}

export const defaultPrint = async (params: PrintOutForCustomersMutationVariables) => {
  const printRes = await gqlClient(printOutForCustomers, params);
  return printRes.smartpicking?.printOutForCustomers;
};
