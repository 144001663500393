import { RenderIf } from 'shared/helpers/renderIf';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectComponent, { SelectChangeEvent } from '@mui/material/Select';

export const Select = <T,>({
  value,
  handleValue,
  label,
  options,
  width = '230px',
  keys = {},
  textColor,
  sx = {},
  defaultOpen = false,
}: ISelect<T>) => {
  const { optionLabel = 'name', optionValue = 'value' } = keys;
  const handleChange = (event: SelectChangeEvent<T>) => {
    handleValue(event.target.value as T);
  };

  const getBackGroundColor = () => {
    return options?.find((el) => el?.id === value)?.color || 'unset';
  };

  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <SelectComponent
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={handleChange}
        defaultOpen={defaultOpen}
        sx={{
          color: textColor || 'text.primary',
          backgroundColor: getBackGroundColor(),
          width,
          maxWidth: '500px',
          ...sx,
        }}
      >
        {options?.map((option) => {
          const Icon = option.icon;
          return (
            <MenuItem
              key={`select_item__${option[optionValue]}`}
              value={option[optionValue] as string}
              sx={{
                color: textColor || 'text.primary',
                backgroundColor: option.color || 'unset',
                ':hover': {
                  backgroundColor: option.color || 'unset',
                },
                '&.Mui-selected': {
                  backgroundColor: option.color || 'unset',
                  '&.Mui-focusVisible': { background: option.color || 'unset' },
                  ':hover': {
                    backgroundColor: option.color || 'unset',
                  },
                },
              }}
            >
              <RenderIf condition={Boolean(option.icon)}>
                {Icon && <Icon style={{ fill: '#1976d2', marginRight: '10px' }} />}
              </RenderIf>
              {option[optionLabel]}
            </MenuItem>
          );
        })}
      </SelectComponent>
    </FormControl>
  );
};

interface ISelect<T> {
  value: T;
  handleValue: (value: T) => any;
  label?: string;
  options?: any[];
  width?: string;
  defaultOpen?: boolean;
  textColor?: string;
  sx?: Object;
  keys?: {
    optionLabel?: string;
    optionValue?: string;
  };
}
