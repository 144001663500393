import { useState } from 'react';

export const useCloseModalWithDelay = (onClose: () => void, delay = 3500): [number, () => void] => {
  const [progress, setProgress] = useState(0);

  const startClosing = () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 7.5);
    }, 250);

    setTimeout(() => {
      onClose();
      setProgress(0);
      clearInterval(timer);
    }, delay);
  };

  return [progress, startClosing];
};
