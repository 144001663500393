import { withSideBarHOC } from 'shared/HOCs/withSideBarHOC';
import { useTranslations } from 'shared/hooks/useTranslations';
import { AppHeader } from 'widgets/AppHeader';
import { ProductionListContainer } from './styles';
import { ProductionListGrid } from 'widgets/ProductionListGrid';
import { ProductionListModals } from 'widgets/ProductionListModals';

export const ProductionListComponent = () => {
  const t = useTranslations();
  return (
    <ProductionListContainer>
      <AppHeader pageName={t('production.productionList')} />
      <ProductionListGrid />
      <ProductionListModals />
    </ProductionListContainer>
  );
};

export const ProductionList = withSideBarHOC(ProductionListComponent);
