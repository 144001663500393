module.exports = {
  sizeMap: {
    TEXT_XS: 10,
    TEXT_S: 12,
    TEXT_M: 13,
    TEXT_L: 14,
    TEXT_XL: 15,
    TEXT_XXL: 16,
  },

  styleMap: {
    BOLD: 'bold',
    ITALIC: 'italics',
  },

  alignMap: {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
  },

  fontsMap: {
    FONT_A: 0,
    FONT_B: 1,
    FONT_C: 2,
  },

  fontStylesMap: {
    DEFAULT: 0,
    DOUBLE_HEIGHT: 1,
    DOUBLE_WIDTH: 2,
    DOUBLE_HW: 3,
    BOLD: 4,
    BOLD_DOUBLE_HEIGHT: 5,
    BOLD_DOUBLE_WIDTH: 6,
    BOLD_DOUBLE_HW: 7,
  },
};
