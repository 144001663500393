export const handleLocalStorage = (values: string | string[] | { [key: string]: any }) => {
  if (typeof values === 'string') {
    const value = window.localStorage.getItem(values);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }
  if (Array.isArray(values)) {
    const result: any = {};
    values.forEach((value) => {
      const item = window.localStorage.getItem(value);
      if (item) {
        result[value] = JSON.parse(item);
      } else {
        result[value] = null;
      }
    });
    return result;
  }
  if (typeof values === 'object') {
    Object.keys(values).forEach((value) => {
      window.localStorage.setItem(value, JSON.stringify(values[value]));
    });
    return values;
  }
};
