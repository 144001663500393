import { FC } from 'react';
import { RenderIf } from 'shared/helpers/renderIf';
import {
  MobileCellComponentContainer,
  MobileCellHeader,
  MobileCellStatus,
  MobileDelNote,
} from './styles';
import { useTranslations } from 'shared/hooks/useTranslations';
import { openOrder } from 'shared/store/distributionListStore';
import { getProductStatus } from 'shared/helpers/getProductStatus';
import { getStatusColor } from 'shared/helpers/getStatusColor';

export const AccordionProduct: FC<AccordionProductProps> = ({ item }) => {
  const t = useTranslations();

  const status = getProductStatus(item);
  const handleClick = () => {
    openOrder(item?.orderId);
  };

  return (
    <>
      <RenderIf condition={Boolean(item?.customerNum && item?.internalName)}>
        <MobileCellComponentContainer onClick={handleClick}>
          <MobileCellHeader>{`${item?.customerNum} ${item?.internalName}`}</MobileCellHeader>
          <MobileCellStatus>
            {/* TODO: translations */}
            <span>Status:</span>
            <p style={{ color: getStatusColor(status) }}>{status}</p>
          </MobileCellStatus>
          <MobileDelNote>
            {/* TODO: translations */}
            <span>{t('common.deliveryNote')}</span>
            <p>{item?.deliveryNoteNum}</p>
          </MobileDelNote>
        </MobileCellComponentContainer>
      </RenderIf>
    </>
  );
};

type AccordionProductProps = {
  item: any;
};

// const MobileCellComponent = (el: any) => {
//   return (
//     <>
//       <RenderIf condition={Boolean(item?.customerNum && item?.internalName)}>
//         <MobileCellComponentContainer>
//           <MobileCellHeader>{`${item?.customerNum} ${item?.internalName}`}</MobileCellHeader>
//           <MobileCellStatus>
//             {/* TODO: translations */}
//             <span>Status:</span>
//             <p>TEST</p>
//           </MobileCellStatus>
//           <MobileDelNote>
//             {/* TODO: translations */}
//             <span>DEL NOTE:</span>
//             <p>{item?.deliveryNoteNum}</p>
//           </MobileDelNote>
//         </MobileCellComponentContainer>
//       </RenderIf>
//     </>
//   );
// };
