import { smartCalculation } from './index';

export enum SmartCalcErrors {
  CLEAR,
  ONLY_PRIORITY,
  ALL,
  LIMIT,
  COMMON,
}

export const getErrorByEnum = (t: (v: string) => string, enumValue: SmartCalcErrors | null) => {
  if (enumValue === SmartCalcErrors.ONLY_PRIORITY) {
    return t('errors.canSatisfyOnlyPriorityCustomers') || '';
  }
  if (enumValue === SmartCalcErrors.ALL) {
    return t('errors.cannotSatisfyAnyone') || '';
  }
  if (enumValue === SmartCalcErrors.LIMIT) {
    return t('errors.limitIsExceeded') || '';
  }
  if (enumValue === SmartCalcErrors.COMMON) {
    return t('errors.error') || '';
  }
  if (enumValue === SmartCalcErrors.CLEAR) {
    return '';
  }
  return t('errors.error') || '';
};

export const getSubErrorByEnum = (t: (v: string) => string, enumValue: SmartCalcErrors | null) => {
  if (enumValue === SmartCalcErrors.ONLY_PRIORITY) {
    return undefined;
  }
  if (enumValue === SmartCalcErrors.ALL) {
    return t('errors.cannotSatisfyAnyoneSubError') || '';
  }
  if (enumValue === SmartCalcErrors.LIMIT) {
    return t('errors.limitIsExceededSubError') || '';
  }
  if (enumValue === SmartCalcErrors.COMMON) {
    return undefined;
  }
  if (enumValue === SmartCalcErrors.CLEAR) {
    return undefined;
  }
  return undefined;
};

export const smartCalculationWithSorting = (
  orders: any[] = [],
  justProducedTotal: number,
): [any[], SmartCalcErrors | null] => {
  const [ord, errMessage] = smartCalculation(orders, justProducedTotal);
  return [sortOrders(ord), errMessage];
};

export const sortOrders = (orders: any[] = []) => {
  return [...orders]?.sort((a, b) => {
    if (
      a.distributionHigherLimitInPercent === 100 &&
      a.distributionLowerLimitInPercent === 100 &&
      !(b.distributionHigherLimitInPercent === 100 && b.distributionLowerLimitInPercent === 100)
    ) {
      return -1; // a comes before b
    } else if (
      b.distributionHigherLimitInPercent === 100 &&
      b.distributionLowerLimitInPercent === 100 &&
      !(a.distributionHigherLimitInPercent === 100 && a.distributionLowerLimitInPercent === 100)
    ) {
      return 1; // b comes before a
    } else if (a?.priority || b?.priority) {
      // at least on of items has priority
      return Number(a?.priority || Infinity) - Number(b?.priority || Infinity); // Sort by amount in decreasing order
    } else {
      // Both have distributionHigherLimitInPercent === 100 and distributionLowerLimitInPercent === 100 or neither has one. Neither has the priority
      return b.amount - a.amount; // Sort by amount in decreasing order
    }
  });
};

export const distributeWithoutCalculation = (orders = [], producedTotal = 0) => {
  const { result } = sortOrders(orders)?.reduce(
    (acc: any, curr: any) => {
      if (Number(curr?.amount) - Number(curr?.distributed) <= acc.remainingTotal) {
        console.log(1);
        return {
          result: [
            ...acc.result,
            { ...curr, justProduced: Number(curr?.amount) - Number(curr?.distributed) },
          ],
          remainingTotal: Number(acc.remainingTotal - curr?.amount + curr?.distributed),
        };
      } else if (acc.remainingTotal) {
        console.log(2);
        return {
          result: [...acc.result, { ...curr, justProduced: Number(acc.remainingTotal) }],
          remainingTotal: 0,
        };
      } else {
        console.log(3);
        return {
          result: [...acc.result, { ...curr, justProduced: 0 }],
          remainingTotal: 0,
        };
      }
    },
    {
      result: [],
      remainingTotal: producedTotal,
    },
  );
  return result;
};
