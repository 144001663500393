import { Button } from 'entities/Button';
import { RenderIf } from 'shared/helpers/renderIf';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useStore } from 'effector-react';
import { appStore } from 'shared/store';
import { updateTheme } from 'shared/store/appStore';
import { ThemeSelectorContainer } from './styles';
import { Text } from 'entities/Text';

export const ThemeSelector = () => {
  const { themeColor } = useStore(appStore);

  const handleUpdateTheme = () => {
    console.log('color', themeColor);
    updateTheme(themeColor === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeSelectorContainer>
      <Button handleClick={handleUpdateTheme} width="42px" height="42px">
        <RenderIf condition={themeColor === 'dark'}>
          <LightModeIcon />
        </RenderIf>
        <RenderIf condition={themeColor === 'light'}>
          <DarkModeIcon />
        </RenderIf>
      </Button>
      {/* TODO: translations */}
      <RenderIf condition={themeColor === 'dark'}>
        <Text>Use light theme</Text>
      </RenderIf>
      <RenderIf condition={themeColor === 'light'}>
        <Text>Use dark theme</Text>
      </RenderIf>
    </ThemeSelectorContainer>
  );
};
