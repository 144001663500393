export const settingsTabs = (t: (v: string) => string) => [
  {
    label: t('settings.language'),
  },
  // {
  //   label: t('common.shippingPoint'),
  // },
  {
    label: t('settings.displays'),
  },
  {
    label: t('settings.bindDisplays'),
  },
  {
    label: t('settings.printers'),
  },
];
