import { FC, useState } from 'react';
import { FinishModal } from '../FinishModal';
import { Box, Button } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';
import { Text } from 'entities/Text';

export const Disclaimer: FC<any> = ({ product, discardProduct }) => {
  const t = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, startClosing] = useCloseModalWithDelay(discardProduct, 7000);

  return (
    <Box
      height={267}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="25px"
    >
      <Text> {t('production.productInProgressWarning')}</Text>
      <Button variant="contained" onClick={() => setIsModalOpen(true)}>
        {t('production.openProductInProgress')}
      </Button>
      <FinishModal
        isOpen={isModalOpen}
        progress={progress}
        productProps={product}
        headerTitle={product.description}
        //
        onClose={() => setIsModalOpen(false)}
        instantClose={() => {
          discardProduct();
          setIsModalOpen(false);
        }}
        startClosing={startClosing}
      />
    </Box>
  );
};
