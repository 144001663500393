import styled from 'styled-components';

export const AppHeaderContainer = styled.div`
  margin: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 42px 56px;
  /* width: 100%; */
`;

export const TopLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const BottomLine = styled(TopLine)``;

export const MobileHeaderLayout = styled.div`
  display: grid;
  grid-template-rows: 56px 56px;
  grid-gap: 10px;
  flex-direction: column;
  margin: 0 20px 20px;
  padding-top: 30px;
  position: relative;
`;

export const MobileHeaderChildrenContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  position: absolute;
  top: 20px;
  right: 0;
`;
