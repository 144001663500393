import styled from 'styled-components';

type DistributedComponentProps = {
  bgColor: '#ffc7c3' | '#ffffaab2';
};

export const DistributedComponent = styled('div')<DistributedComponentProps>`
  width: calc(100% + 20px);
  height: 100%;
  margin: 0 -10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: green;
  background: ${({ bgColor }) => bgColor};
`;
