import { FC, useState } from 'react';
import { SidebarHandlers } from './SidebarHandlers';
import { Navigation } from 'features/Navigation';
import { AppLogo } from 'shared/images';
import { Text } from 'entities/Text';

import { appStore } from 'shared/store';
import { useStore } from 'effector-react';

import {
  BoldP,
  SLabel,
  Drawer,
  MenuIcon,
  FakeCloser,
  MainContainer,
  AppIconWrapper,
  DrawerContainer,
  MenuIconContainer,
  DrawerInnerContainer,
} from './styled';

export const DesktopSidebar: FC = ({ children }) => {
  const { themeColor } = useStore?.(appStore);

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  return (
    <MainContainer>
      <Drawer variant="permanent" open={isSideBarOpen}>
        <DrawerContainer>
          <FakeCloser onClick={() => setIsSideBarOpen(false)} />
          <DrawerInnerContainer>
            <MenuIconContainer
              onClick={() => {
                setIsSideBarOpen((prevValue: boolean) => !prevValue);
              }}
            >
              <AppIconWrapper>
                <MenuIcon>
                  <AppLogo />
                </MenuIcon>
                <SLabel>
                  <BoldP>smart</BoldP>
                  <Text>Picking</Text>
                </SLabel>
              </AppIconWrapper>
            </MenuIconContainer>
            <Navigation />
          </DrawerInnerContainer>
          <SidebarHandlers themeColor={themeColor} />
        </DrawerContainer>
      </Drawer>
      {children}
    </MainContainer>
  );
};
