import styled from 'styled-components';

export const FinishModalContainer = styled.div`
  overflow: scroll;
  min-width: 50vw;
  @media (max-width: 800px) {
    margin: 20px auto;
  }
`;

export const MainContainer = styled.div`
  display: grid;
  grid-gap: 15px 100px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 24px;
  p:nth-child(odd) {
    justify-self: end;
  }
  p:nth-child(n + 2) {
    font-size: 34px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0.25px;
  }
  p:nth-child(-n + 2) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 48px 0 24px;
`;
