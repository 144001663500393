import styled from 'styled-components';

export const ReplaceModalContainer = styled.div`
  display: grid;
  grid-template-columns: 350px 350px;
  grid-template-rows: 1fr;
  grid-gap: 25px;

  margin: 24px;
  overflow: unset;

  // TODO: use global var instead of 800px
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  & p {
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
`;

export const ReplaceModalContainerInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 25px 56px;
  grid-gap: 25px;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 25px;
  grid-column: 1 / -1;
`;
