import { FC } from 'react';
import { useStore } from 'effector-react';
import { Button } from '@mui/material';
import { signOut } from 'shared/store/appStore';
import { withModalHOC } from '../../shared/HOCs/withModalHOC';
import { useTranslations } from 'shared/hooks/useTranslations';
import { settingsStore } from 'shared/store';
import { LogOutContainer } from './styles';
import { Text } from 'entities/Text';

const LogOutModal: FC<ILogOutModal> = ({ onClose }) => {
  const t = useTranslations();
  useStore(settingsStore);

  return (
    <div>
      <Text sx={{ marginTop: '30px' }} textAlign="center">
        {t('login.logOutQuestion')}
      </Text>
      <LogOutContainer>
        <Button variant="outlined" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button variant="contained" onClick={() => signOut()}>
          {t('login.logOut')}
        </Button>
      </LogOutContainer>
    </div>
  );
};

interface ILogOutModal {
  onClose: () => void;
}

export default withModalHOC(LogOutModal);
