import { FC } from 'react';
import { DataGridPremium, DataGridPremiumProps } from '@mui/x-data-grid-premium';

export const Table: FC<ITable> = ({
  rowStyles = {},
  cellStyles = {},
  cellFocusedStyles = {},
  ...props
}) => {
  return (
    <DataGridPremium
      disableRowSelectionOnClick
      hideFooter
      sx={{
        '& .MuiDataGrid-row': { cursor: 'pointer', ...rowStyles },
        '& .MuiDataGrid-cell': cellStyles,
        '& .MuiDataGrid-cell:focus': { outline: 'unset', ...cellFocusedStyles },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
      }}
      {...props}
    />
  );
};

interface ITable extends DataGridPremiumProps {
  rowStyles?: Object;
  cellStyles?: Object;
  cellFocusedStyles?: Object;
}
