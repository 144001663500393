import DoneAllIcon from '@mui/icons-material/DoneAll';
import { InfoContainer, HeaderText, SubHeaderText } from './styles';
import { Button } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import { toggleDoneShown } from 'shared/store/productionListStore';

export const DoneProducts = () => {
  const t = useTranslations();

  return (
    <InfoContainer>
      <DoneAllIcon sx={{ fontSize: '5rem', color: '#2E7D32' }} />
      <HeaderText>{t('production.readyForDelivery')}!</HeaderText>
      <SubHeaderText>
        {t('production.everythingIsDistributed')}
        <Button
          sx={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
          onClick={() => toggleDoneShown()}
        >
          {t('production.showOrders')}
        </Button>
      </SubHeaderText>
    </InfoContainer>
  );
};
