import { FC } from 'react';
import { RenderIf } from 'shared/helpers/renderIf';
import { ButtonsContainer, Header, MainContainer, SettingsTemplateContainer } from './styles';
import { Button } from 'entities/Button';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getLayout } from 'shared/helpers/getLayout';

export const SettingsTemplate: FC<ISettingsTemplate> = ({
  headerTitle,
  onReset,
  onSave,
  children,
}) => {
  const t = useTranslations();
  return (
    <SettingsTemplateContainer>
      <MainContainer>
        <RenderIf condition={Boolean(headerTitle)}>
          <Header>{headerTitle}</Header>
        </RenderIf>
        {children}
      </MainContainer>
      <RenderIf condition={Boolean(onReset || onSave)}>
        <ButtonsContainer>
          <RenderIf condition={Boolean(onReset)}>
            <Button handleClick={() => onReset?.()} variant="outlined" color="error">
              {t('common.reset')}
            </Button>
          </RenderIf>
          <RenderIf condition={Boolean(onSave)}>
            <Button
              handleClick={() => onSave?.()}
              width={getLayout() === 'MOBILE' ? '100%' : undefined}
            >
              {t('buttons.save')}
            </Button>
          </RenderIf>
        </ButtonsContainer>
      </RenderIf>
    </SettingsTemplateContainer>
  );
};

interface ISettingsTemplate {
  headerTitle?: string;
  onReset?: () => void;
  onSave?: () => void;
  ignoreHeight?: boolean;
}
