import { CognitoUser } from 'amazon-cognito-identity-js';

export enum AuthErrors {
  MFA_REQUIRED = 'MFA_REQUIRED',
}

export type ThemeColor = 'light' | 'dark';

export type SignInOptions = {
  user: string;
  password: string;
};

export type ConfirmMFAOptions = {
  mfaCode: string;
  cognitoUser: CognitoUser;
};

export type AppStoreType = {
  isLoggedIn: boolean;
  isNewVersionReleased: boolean;
  hasAccess: boolean;
  isShippingPointSet: boolean;
  shippingPoint: string | null;
  themeColor: ThemeColor;
};

export const initAppStore: AppStoreType = {
  isLoggedIn: false,
  isNewVersionReleased: false,
  hasAccess: false,
  isShippingPointSet: false,
  shippingPoint: null,
  themeColor: 'light',
};
