import { useStore } from 'effector-react';
import { RenderIf } from 'shared/helpers/renderIf';
import { productionStore } from 'shared/store';
import { closeProduct } from 'shared/store/productionListStore';
import { OrderModal } from './OrderModal';
import { FinishModal } from './FinishModal';
import { useMemo } from 'react';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';

export const ProductionListModals = () => {
  const { currentOpenProduct } = useStore(productionStore);
  const [progress, startClosing] = useCloseModalWithDelay(closeProduct, 3500); // eslint-disable-line

  const isProductInProgress = useMemo(() => {
    return currentOpenProduct?.productionListOrders?.some((order: any) => {
      return order.productStatus === 'In progress' || order.productStatus === 'IN_PROGRESS';
    });
  }, [currentOpenProduct]); //eslint-disable-line

  return (
    <>
      <RenderIf condition={Boolean(currentOpenProduct)}>
        <OrderModal
          isOpen={Boolean(!isProductInProgress)}
          headerTitle={currentOpenProduct?.description}
          onClose={closeProduct}
          progress={progress}
          startClosing={startClosing}
        />
        <FinishModal
          isOpen={Boolean(isProductInProgress)}
          headerTitle={currentOpenProduct?.description}
          onClose={closeProduct}
          progress={progress}
          startClosing={startClosing}
        />
      </RenderIf>
    </>
  );
};
