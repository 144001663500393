import { FC } from 'react';

type RenderIfProps = {
  condition: boolean;
  children: any;
};

export const RenderIf: FC<RenderIfProps> = ({ condition, children }) => {
  if (condition) {
    return children;
  } else {
    return null;
  }
};
