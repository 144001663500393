import { useStore } from 'effector-react';
import { settingsStore } from 'shared/store';

export const useTranslations = () => {
  const { translations, lang, isTranslationsInit } = useStore(settingsStore);

  const translateFunction = (value: string, substitutions = {}) => {
    if (isTranslationsInit) {
      const [partition, key] = value.split('.');
      const res = translations?.[lang]?.[partition]?.[key];

      if (typeof res === 'function') return res(substitutions);
      if (typeof res === 'string') return res;
      return '';
    } else {
      return value.split('.')[1];
    }
  };

  return translateFunction;
};
