import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useLocation } from 'react-router-dom';
import { Select } from 'entities/Select';
import { DATE_FORMAT } from 'shared/globalConsts';
import { RenderIf } from 'shared/helpers/renderIf';
import { globalStore } from 'shared/store';
import { setNewPart } from 'shared/store/globalStore';
import { distributionStore } from 'shared/store';
import { productionStore } from 'shared/store';
import { availableParts } from './consts';

export const PartSelector = () => {
  //
  // Hooks
  const location = useLocation();

  // Store
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const { selectedPart } = useStore(globalStore);
  const { lists } = useStore(distributionStore);
  const { productsData } = useStore(productionStore);

  // Statge
  const [selectOptions, setSelectOptions] = useState<any[]>([]);

  // useEffects
  useEffect(() => {
    if (location.pathname === '/production_list') {
      setOptions(productsData?.[date]?.productsList);
    }
    if (location.pathname === '/distribution_list') {
      setOptions(lists?.[date]?.ordersList);
    }
  }, [date, productsData, lists, location.pathname]); // eslint-disable-line

  // Functions
  const setOptions = (list: any[]) => {
    const uniqueParts = [...new Set(list?.map((item) => item?.deliveryCycleNum))];
    if (selectedPart > uniqueParts?.length) {
      setNewPart(1);
    }
    setSelectOptions(() => {
      if (uniqueParts?.length < 2) {
        return [availableParts[0]];
      } else {
        return availableParts?.filter((part) => uniqueParts.includes(part.value));
      }
    });
  };

  return (
    <>
      <RenderIf condition={selectOptions?.length > 1}>
        <Select
          width="195px"
          value={selectedPart}
          options={selectOptions}
          handleValue={(newValue: number) => setNewPart(newValue)}
        />
      </RenderIf>
    </>
  );
};
