import { createEvent, createEffect } from 'effector';
import { getDistributionList as getDistListRequest } from 'shared/gql/common.gql';
import { createMyGate } from 'shared/helpers/createMyGate';
import { DATE_FORMAT } from 'shared/globalConsts';
import { gqlClient } from 'shared/gql/gqlClient';
import { getDistListProps } from './types';
import { errorHandler } from 'shared/helpers/errorHandler';
import { checkMustLoad } from 'shared/helpers/checkMustLoad';
import { distributionStore } from '..';

export const getDistributionList = createEffect(async ({ date, forceLoad }: getDistListProps) => {
  const formattedDate = date.format(DATE_FORMAT);

  // list for a specific date must be updated once in a minute
  const mustLoad = forceLoad || checkMustLoad(distributionStore.getState().lists, formattedDate);

  if (mustLoad) {
    try {
      startLoading();
      const res = await gqlClient(getDistListRequest, { date: formattedDate });
      const ordersList = res.smartpicking.getDistributionList;
      return {
        [formattedDate]: {
          lastUpdated: new Date().getTime(),
          ordersList,
        },
      };
    } catch (e) {
      errorHandler(e);
      return;
    }
  }
  return;
});

export const startLoading = createEvent();
export const openOrder = createEvent<string>();
export const closeOrder = createEvent();

export const openProduct = createEvent<number | null>();
export const closeProduct = createEvent();

export const DistributionGate = createMyGate(getDistributionList);

distributionStore
  .on(startLoading, (state) => {
    return {
      ...state,
      isDistrListLoading: true,
    };
  })
  .on(openOrder, (state, openedOrderId) => {
    if (state.openOrderId === openedOrderId) return state;
    return {
      ...state,
      openOrderId: openedOrderId,
    };
  })
  .on(closeOrder, (state) => {
    if (!state.openOrderId) return state;
    return {
      ...state,
      openOrderId: null,
    };
  })
  .on(openProduct, (state, openProductId) => {
    if (state.openProductId === openProductId) return state;
    return {
      ...state,
      openProductId: openProductId,
    };
  })
  .on(closeProduct, (state) => {
    if (!state.openProductId) return state;
    return {
      ...state,
      openProductId: null,
    };
  })
  .on(getDistributionList.doneData, (state, newList) => {
    return {
      ...state,
      isDistrListLoading: false,
      lists: { ...state.lists, ...newList },
    };
  });
