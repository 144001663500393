import { createEffect, createEvent } from 'effector';
import { createMyGate } from 'shared/helpers/createMyGate';
import { AvailableStatuses, HandleInfoProps, getProdListProps } from './types';
import { DATE_FORMAT } from 'shared/globalConsts';
import { errorHandler } from 'shared/helpers/errorHandler';
import { gqlClient } from 'shared/gql/gqlClient';
import { getProductionList as getProdListRequest } from 'shared/gql/common.gql';
import { checkMustLoad } from 'shared/helpers/checkMustLoad';
import { ProductType } from 'shared/types';
import { discardStartModalState } from '../startModalStore';
import { getProductStatus } from 'shared/helpers/getProductStatus';
import { productionStore } from '..';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';

const handleInfoStatus = createEffect(({ list }: HandleInfoProps) => {
  const shippingPointName = handleLocalStorage('shippingPointName'); // TODO: take from store
  const listFiltered = list?.filter((item) => item?.shippingPointName === shippingPointName);
  switch (true) {
    case !listFiltered.length:
      updateInfoStatus(AvailableStatuses.noOrders);
      break;
    case listFiltered?.every((product) => getProductStatus(product) === 'Done'):
      updateInfoStatus(AvailableStatuses.isAllDone);
      break;
    default:
      updateInfoStatus(AvailableStatuses.default);
  }
});

export const getProductionList = createEffect(async ({ date, forceLoad }: getProdListProps) => {
  const formattedDate = date.format(DATE_FORMAT);

  // list for a specific date must be updated once in a minute
  const mustLoad =
    forceLoad || checkMustLoad(productionStore.getState().productsData, formattedDate);

  if (mustLoad) {
    try {
      startLoading();
      const res = await gqlClient(getProdListRequest, { date: formattedDate });
      const productsList = res.smartpicking.getProductionList;
      return {
        [formattedDate]: {
          lastUpdated: new Date().getTime(),
          productsList,
        },
      };
    } catch (e) {
      errorHandler(e);
      return;
    }
  }
  return;
});

export const startLoading = createEvent();
export const toggleDoneShown = createEvent();
export const openProduct = createEvent<ProductType | null>();
export const closeProduct = createEvent();
export const updateInfoStatus = createEvent<AvailableStatuses>();

export const ProductionGate = createMyGate(getProductionList);
export const InfoStatusGate = createMyGate(handleInfoStatus);

productionStore
  .on(startLoading, (state) => {
    return {
      ...state,
      isProdListLoading: true,
    };
  })
  .on(toggleDoneShown, (state) => {
    return {
      ...state,
      isDoneShown: !state.isDoneShown,
    };
  })
  .on(openProduct, (state, newOpenProduct) => {
    return {
      ...state,
      currentOpenProduct: newOpenProduct,
    };
  })
  .on(updateInfoStatus, (state, newStatus) => {
    if (state.infoStatus === newStatus) return state;
    return {
      ...state,
      infoStatus: newStatus,
    };
  })
  .on(closeProduct, (state) => {
    discardStartModalState();
    if (!state.currentOpenProduct) return state;
    return {
      ...state,
      currentOpenProduct: null,
    };
  })
  .on(getProductionList.doneData, (state, newProductData) => {
    return {
      ...state,
      isProdListLoading: false,
      productsData: { ...state.productsData, ...newProductData },
    };
  });
