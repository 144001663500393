import { deliveryNoteNumCell, editableCell } from '../styles';

export const columns = (t: (v: string) => string) => [
  {
    field: 'customerNum',
    headerName: t('common.customerNumber'),
    width: 120,
  },
  {
    field: 'internalName',
    headerName: t('common.companyName'),
    width: 200,
    flex: 1,
  },
  {
    field: 'deliveryNoteNum',
    headerName: t('common.deliveryNote'),
    width: 125,
    renderCell: deliveryNoteNumCell,
  },
  {
    field: 'amount',
    headerName: t('common.ordered'),
    width: 125,
    type: 'number',
    valueFormatter: (e: any) => +e.value,
  },
  {
    field: 'distributed', // already distributed amount
    headerName: t('common.distributed'),
    width: 125,
    type: 'number',
  },

  {
    field: 'justProduced',
    headerName: t('common.toDistribute'),
    width: 125,
    editable: true,
    type: 'number',
    renderCell: editableCell,
  },

  {
    // (To distribute + Distributed) - Ordered
    field: 'difference',
    headerName: t('common.difference'),
    type: 'number',
    width: 125,
    valueGetter: ({ row }: any) => {
      const { distributed = 0, justProduced = 0, amount = 0 } = row;
      return Number(distributed) + Number(justProduced) - Number(amount) || 0;
    },
  },
];

export const aggregationModel = {
  amount: 'sum',
  distributed: 'sum',
  justProduced: 'sum',
  difference: 'sum',
};
