import { MAIN_COLOR } from 'shared/globalConsts';
import styled from 'styled-components';

type SPSelectorCellProps = {
  isSelected?: boolean;
};

export const SPItemsContainer = styled.div`
  /* background-color: yellow; */
  background-color: #fff;

  margin-top: -4px;
  padding: 9px 0 5px;
  border: 1px solid rgba(25, 118, 210, 0.5);
  border-top: unset;
  border-radius: 0 0 4px 4px;
  position: relative;
  z-index: 999;
`;

export const SPItem = styled.div<SPSelectorCellProps>`
  padding: 10px;
  background-color: ${({ isSelected }) => (isSelected ? MAIN_COLOR : '#fff')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : 'unset')};
`;
