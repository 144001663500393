import { FC, useState } from 'react';
import Button from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/DriveFileRenameOutline';
import { RenderIf } from 'shared/helpers/renderIf';
import { useStore } from 'effector-react';
import { globalStore } from 'shared/store';
import { startModalStore } from 'shared/store';
import { productionStore } from 'shared/store';
import { editOrders } from 'shared/api';
import { getProductionList } from 'shared/store/productionListStore';
import { getDistributionList } from 'shared/store/distributionListStore';
import { getLayout } from 'shared/helpers/getLayout';

export const EditOrderButton: FC<IEditButton> = ({ closeMotherModal }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //
  const { date } = useStore(globalStore);
  const { currentOpenProduct: product } = useStore(productionStore);
  const { productsToUpdateDistributed: productsToUpdate } = useStore(startModalStore);
  //

  const handleSaveDistributed = async () => {
    if (productsToUpdate?.length) {
      setIsLoading(true);
      closeMotherModal();

      await editOrders({
        orders: productsToUpdate?.map((el: any) => {
          return {
            orderId: el?.orderId,
            positionParts: [
              {
                linkArticle: `${product?.articleId}`,
                description: el?.description,
                //
                newQuantity: el?.producedAmount,
                partNum: el?.partNum,
              },
            ],
          };
        }),
      });

      getProductionList({ date, forceLoad: true });
      getDistributionList({ date, forceLoad: true });
    }
  };

  return (
    <Button
      color="success"
      variant={getLayout() === 'DESKTOP' ? 'contained' : 'text'}
      loading={isLoading}
      onClick={handleSaveDistributed}
      sx={{
        margin: 0,
        height: '56px',
        width: '56px',
        minWidth: 'unset',
      }}
    >
      <RenderIf condition={!isLoading}>
        <EditIcon />
      </RenderIf>
    </Button>
  );
};

interface IEditButton {
  closeMotherModal: () => void;
}
