import { SmartCalcErrors } from './helpers';

export const smartCalculation = (
  orders: any[] = [],
  justProducedTotal: number,
): [any[], SmartCalcErrors | null] => {
  const [totalOrdered = 0, totalDistributed = 0] = orders?.reduce(
    ([accOrdered, accDistributed], curr: any) => {
      return [accOrdered + curr?.amount, accDistributed + curr?.distributed];
    },
    [0, 0],
  );

  // EQUAL
  if (+totalOrdered - totalDistributed - justProducedTotal === 0) {
    return [
      orders.map((el: any) => ({
        ...el,
        justProduced: +el.amount - el.distributed,
      })),
      null,
    ];
  }

  // LESS
  if (+totalOrdered - totalDistributed - justProducedTotal > 0) {
    const { arrayDONE, array100, arrayRest, ordered100, orderedRest } = getMINDataReduced(orders);
    if (ordered100 + orderedRest <= justProducedTotal) {
      // Can satisfy all minimums
      return [
        [
          ...arrayDONE,
          ...array100,
          ...subtractByCycleMIN(arrayRest, totalOrdered - justProducedTotal - totalDistributed),
        ],
        null,
      ];
    } else if (ordered100 && ordered100 <= justProducedTotal) {
      // Can satisfy minimums for priority customers
      return [[...arrayDONE, ...array100, ...arrayRest], SmartCalcErrors.ONLY_PRIORITY];
    } else {
      // Cannot satisfy any customer
      return [orders, SmartCalcErrors.ALL];
    }
  }

  // MORE
  if (+totalOrdered - totalDistributed - justProducedTotal < 0) {
    const { arrayDONE, array100, arrayRest, ordered100, orderedRest } = getMAXDataReduced(orders);

    if (ordered100 + orderedRest < justProducedTotal) {
      // excceded the limit
      return [
        [
          ...arrayDONE,
          ...array100,
          ...subtractByCycleMAX(arrayRest, orderedRest + ordered100 - totalOrdered),
        ],
        SmartCalcErrors.LIMIT,
      ];
    } else {
      // within brackets
      return [
        [
          ...arrayDONE,
          ...array100,
          ...subtractByCycleMAX(arrayRest, totalOrdered - justProducedTotal - totalDistributed),
        ],
        null,
      ];
    }
  }

  return [orders, SmartCalcErrors.COMMON];
};

const getMINDataReduced = (orders: any[]) => {
  return orders.reduce(
    (acc, curr) => {
      if (curr?.productStatus === 'DONE' || curr?.productStatus === 'Done') {
        return {
          ...acc,
          arrayDONE: [...acc.arrayDONE, { ...curr, justProduced: 0 }],
        };
      } else if (curr?.distributionLowerLimitInPercent >= 100) {
        return {
          ...acc,
          array100: [...acc.array100, { ...curr, justProduced: curr.amount }],
          ordered100:
            acc.ordered100 + Math.ceil(curr.amount * (curr.distributionLowerLimitInPercent / 100)),
        };
      } else {
        return {
          ...acc,
          arrayRest: [...acc.arrayRest, curr],
          orderedRest:
            acc.orderedRest + Math.ceil(curr.amount * (curr.distributionLowerLimitInPercent / 100)),
        };
      }
    },
    {
      arrayDONE: [],
      array100: [],
      arrayRest: [],
      ordered100: 0,
      orderedRest: 0,
    },
  );
};

const getMAXDataReduced = (orders: any[]) => {
  return orders.reduce(
    (acc, curr) => {
      if (curr?.productStatus === 'DONE' || curr?.productStatus === 'Done') {
        return {
          ...acc,
          arrayDONE: [...acc.arrayDONE, { ...curr, justProduced: 0 }],
        };
      } else if (curr?.distributionHigherLimitInPercent <= 100) {
        return {
          ...acc,
          array100: [
            ...acc.array100,
            { ...curr, justProduced: Number(curr.amount - curr.distributed) },
          ],
          ordered100:
            acc.ordered100 +
            Math.floor(curr.amount * (curr.distributionHigherLimitInPercent / 100)) -
            curr.distributed,
        };
      } else {
        return {
          ...acc,
          arrayRest: [
            ...acc.arrayRest,
            {
              ...curr,
              justProduced: Number(curr.amount - curr.distributed),
            },
          ],
          orderedRest:
            acc.orderedRest +
            Math.floor(curr.amount * (curr.distributionHigherLimitInPercent / 100)) -
            curr.distributed,
        };
      }
    },
    {
      arrayDONE: [],
      array100: [],
      arrayRest: [],
      ordered100: 0,
      orderedRest: 0,
    },
  );
};

const subtractByCycleMIN = (orders: any[], iterationsNumber: number) => {
  const res = JSON.parse(JSON.stringify(orders))?.sort((a: any, b: any) => {
    if (
      a.amount - Math.floor(a.amount * (a.distributionLowerLimitInPercent / 100)) >
      b.amount - Math.floor(b.amount * (b.distributionLowerLimitInPercent / 100))
    ) {
      return -1;
    } else if (
      a.amount - Math.floor(a.amount * (a.distributionLowerLimitInPercent / 100)) <
      b.amount - Math.floor(b.amount * (b.distributionLowerLimitInPercent / 100))
    ) {
      return 1;
    }
    return a.amount - b.amount;
  });
  let limit = Math.ceil(Math.abs(iterationsNumber));
  for (let i = 0; i < limit; i++) {
    if (limit > iterationsNumber + 9999) {
      limit = 0; // infinite loop preventing
    }
    const currentItem = res[i % res.length];
    const justProduced = currentItem?.justProduced || currentItem?.amount;
    const canSubtract =
      justProduced >= currentItem?.amount * (currentItem?.distributionLowerLimitInPercent / 100);
    if (canSubtract && justProduced >= 1) {
      currentItem.justProduced = justProduced - 1;
    } else {
      limit++;
    }
  }
  return res?.map((el: any) => {
    if (el.justProduced) {
      return el;
    } else {
      return { ...el, justProduced: el.amount };
    }
  });
};

const subtractByCycleMAX = (orders: any[], iterationsNumber: number) => {
  const res = JSON.parse(JSON.stringify(orders))
    ?.sort((a: any, b: any) => {
      if (
        Math.floor(a.amount * (a.distributionHigherLimitInPercent / 100)) - a.amount >
        Math.floor(b.amount * (b.distributionHigherLimitInPercent / 100)) - b.amount
      ) {
        return -1;
      } else if (
        Math.floor(a.amount * (a.distributionHigherLimitInPercent / 100)) - a.amount <
        Math.floor(b.amount * (b.distributionHigherLimitInPercent / 100)) - b.amount
      ) {
        return 1;
      }
      return a.amount - b.amount;
    })
    ?.map((el: any) => ({ ...el, justProduced: el.amount }));
  let limit = Math.ceil(Math.abs(iterationsNumber));
  for (let i = 0; i < limit; i++) {
    if (limit > iterationsNumber + 9999) {
      limit = 0; // infinite loop preventing
    }
    const currentItem = res[i % res.length];
    const canSubtract =
      Math.floor(currentItem?.amount * (currentItem?.distributionHigherLimitInPercent / 100)) >=
      currentItem?.justProduced;
    if (canSubtract) {
      currentItem.justProduced = currentItem?.justProduced + 1;
    } else {
      limit++;
    }
  }
  return res;
};
