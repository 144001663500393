import { Translations } from 'shared/types';

export const getPreparedTranslations = (unpreparedTranslations: Translations) => {
  return JSON.parse(JSON.stringify(unpreparedTranslations), (_key, value) => {
    if (typeof value === 'string' && value?.startsWith('*****')) {
      return (params: any) => {
        const names = Object.keys(params);
        const neededSubstitutons = [...value?.matchAll(new RegExp(/\$\{(\w+)\}/, 'gi'))].map(
          (a) => a[1],
        );
        const containsAllNeededNames = checker(neededSubstitutons, names);
        const vals = Object.values(params);
        if (containsAllNeededNames) {
          // eslint-disable-next-line no-new-func
          return new Function(...names, `return \`${value?.slice(5)}\``)(...vals);
        } else {
          return value?.slice(5);
        }
      };
    } else {
      return value;
    }
  });
};

const checker = (target: string[], arr: string[]) => target?.every((v) => arr?.includes(v));
