import { FC, useState } from 'react';
import PencilIcon from '@mui/icons-material/ModeEdit';
import { ReplaceModal } from './replaceModal';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';
import { Button } from 'entities/Button';
import { getLayout } from 'shared/helpers/getLayout';

export const ReplaceButton: FC<IResetButton> = ({ closeMotherModal }) => {
  // Hooks
  const t = useTranslations();

  const [progress, startClosing] = useCloseModalWithDelay(closeMotherModal, 4000);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        variant={getLayout() === 'DESKTOP' ? 'outlined' : 'text'}
        handleClick={() => {
          setIsModalOpen(true);
        }}
        height="56px"
        width="56px"
      >
        <PencilIcon />
      </Button>

      <ReplaceModal
        isOpen={isModalOpen}
        progress={progress}
        headerTitle={t('production.replaceProduct')}
        startClosing={startClosing}
        instantClose={closeMotherModal}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

interface IResetButton {
  closeMotherModal: () => void;
}
