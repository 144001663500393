import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LinkIcon, LinkLabel, LinkText } from './styled';
import { SvgIconProps } from '@mui/material';
import { MAIN_COLOR } from 'shared/globalConsts';

export const SideBarIcon: FC<IMenuProps> = ({ routeName, icon: Icon, label }) => {
  const location = useLocation();

  const getColor = () => {
    if (location.pathname === routeName) {
      return MAIN_COLOR;
    } else {
      return 'unset';
    }
  };

  return (
    <Link to={routeName || '/'}>
      <LinkIcon>{Icon ? <Icon sx={{ color: getColor() }} /> : label?.charAt(0)}</LinkIcon>
      <LinkLabel title={label || ''} color={getColor()}>
        <LinkText color="">{label}</LinkText>
      </LinkLabel>
    </Link>
  );
};

export interface IMenuProps {
  icon?: (props: SvgIconProps) => any;
  label?: string | null;
  routeName?: string;
}
