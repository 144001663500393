import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';

export const detectDefaultLanguage = () => {
  let userLang = handleLocalStorage('lang');
  if (!userLang) {
    userLang = navigator.language?.slice(0, 2);
  }
  userLang = ['de', 'en'].find((currentLang) => currentLang === userLang);
  return userLang || 'de'; // only english and german are alowed languages
};
