import { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { Loader } from 'app/pageLoader/loaderComponent';

// PAGES
import { ProductionList } from 'pages/ProductionList';
import { DistributionList } from 'pages/DistributionList';
import { useStore } from 'effector-react';
import { appStore } from 'shared/store';
import history from 'shared/helpers/history';

const Login = lazy(() => import('pages/Login'));
const Page404 = lazy(() => import('pages/404'));
const NoAccess = lazy(() => import('pages/NoAccess'));
const ShippingPoint = lazy(() => import('pages/ShippingPoint'));

export const AppRouter: FC = () => {
  const { isLoggedIn, isShippingPointSet, hasAccess } = useStore(appStore);
  const getRedirectPath = () => {
    switch (true) {
      case isLoggedIn && hasAccess && isShippingPointSet:
        return '/production_list';
      case isLoggedIn && hasAccess && !isShippingPointSet:
        return '/shipping_point';
      case isLoggedIn && !hasAccess:
        return '/noAccess';
      case !isLoggedIn:
        if (
          process.env.REACT_APP_ENV === 'production' &&
          window.location.href.indexOf('smartpicking.hssoft.com') > -1
        ) {
          window.location.replace('https://console.hssoft.com/account/login');
        }
        return '/login';
      default:
        if (process.env.REACT_APP_ENV === 'production') {
          window.location.replace('https://console.hssoft.com/account/login');
        }
        return '/login';
    }
  };

  return (
    <Router history={history}>
      <Suspense fallback={<Loader isLoading />}>
        <Routes>
          <Route
            path="/production_list"
            element={
              <PrivateRoute isAllowed={isLoggedIn && isShippingPointSet}>
                <ProductionList />
              </PrivateRoute>
            }
          />
          <Route
            path="/distribution_list"
            element={
              <PrivateRoute isAllowed={isLoggedIn && isShippingPointSet}>
                <DistributionList />
              </PrivateRoute>
            }
          />
          <Route
            path="/shipping_point"
            element={
              <PrivateRoute isAllowed={isLoggedIn && !isShippingPointSet}>
                <ShippingPoint />
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PrivateRoute isAllowed={!isLoggedIn}>
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/noAccess"
            element={
              <PrivateRoute isAllowed={isLoggedIn && !hasAccess}>
                <NoAccess />
              </PrivateRoute>
            }
          />
          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<Navigate to={getRedirectPath()} replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

const PrivateRoute: FC<any> = ({ isAllowed = true, children }) => {
  if (isAllowed) {
    return children;
  }

  return <Navigate to="/" />;
};
