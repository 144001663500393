import styled from 'styled-components';

export const LogOutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 20px;
  gap: 25px;
`;
