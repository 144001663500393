import { Select } from 'entities/Select';
import { SettingsTemplate } from 'entities/SettingsTemplate';
import { options } from './consts';
import { useStore } from 'effector-react';
import { settingsStore } from 'shared/store';
import { setLanguage } from 'shared/store/settingsStore';
import { useState } from 'react';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Loader } from 'app/pageLoader/loaderComponent';
import { ThemeSelector } from 'features/ThemeSelector';
import { Box } from '@mui/material';

export const LanguageSelector = () => {
  const t = useTranslations();
  const [lang, setLang] = useState(useStore(settingsStore).lang);
  const { shippingPoints } = useStore(settingsStore);

  const saveLanguage = () => {
    setLanguage(lang);
  };

  return (
    <SettingsTemplate headerTitle={t('settings.selectLanguage')} onSave={saveLanguage}>
      <Loader isLoading={Boolean(!shippingPoints?.length)} />
      <Select
        width="100%"
        value={lang}
        handleValue={(newValue: 'de' | 'en') => {
          setLang(newValue);
        }}
        label={t('settings.language')}
        options={options}
        keys={{
          optionValue: 'id',
          optionLabel: 'name',
        }}
      />
      <Box sx={{ marginTop: '20px' }}>
        <ThemeSelector />
      </Box>
    </SettingsTemplate>
  );
};
