import { Moment } from 'moment';
import { OrdersList } from 'shared/types';

export type ListsType = {
  [key: string]: {
    lastUpdated: number;
    ordersList: OrdersList;
  };
};

export type DistributionListStoreType = {
  lists: ListsType;
  isDistrListLoading: boolean;
  openOrderId: string | null;
  openProductId: number | null;
};

export const initDistributionListStore = {
  lists: {},
  isDistrListLoading: false,
  openOrderId: null,
  openProductId: null,
};

export type getDistListProps = {
  date: Moment;
  forceLoad?: boolean;
};
