import moment, { Moment } from 'moment';
import { AllProductsListType } from 'shared/types';

export type GlobalStoreType = {
  date: Moment;
  selectedPart: number;
  isProductsListLoad: boolean;
  allProductsList: AllProductsListType;
  searchText: string;
};

export const initGlobalStore = {
  date: moment(),
  selectedPart: 1,
  isProductsListLoad: false,
  allProductsList: [],
  searchText: '',
};
