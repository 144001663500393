import styled from 'styled-components';
import { Box } from '@mui/material';
import { RenderIf } from 'shared/helpers/renderIf';
import StarIcon from '@mui/icons-material/Star';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getStatusColor } from 'shared/helpers/getStatusColor';
import { getStatusText } from 'shared/helpers/getStatusText';
import { getProductStatus } from 'shared/helpers/getProductStatus';
import { GridLogicOperator } from '@mui/x-data-grid-premium';
import { AvailableStatuses } from 'shared/store/productionListStore/types';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import { getLayout } from 'shared/helpers/getLayout';
import {
  MobileCellAmount,
  MobileCellComponentContainer,
  MobileCellHeader,
  MobileCellStatus,
} from './styles';

// TODO: 99 refactor all the consts files columns

type ResponsibleComponentProps = {
  backgroundColor: string;
};

export const responsiblesList = (t: (v: string) => string) => [
  {
    id: 1,
    name: t('common.distributor1'),
    color: {
      title: 'red',
      hex: '#FFC7C3',
    },
  },
  {
    id: 2,
    name: t('common.distributor2'),
    color: {
      title: 'yellow',
      hex: '#F7f79e',
    },
  },
  {
    id: 3,
    name: t('common.distributor3'),
    color: {
      title: 'green',
      hex: '#CDF3BD',
    },
  },
  {
    id: 4,
    name: t('common.distributor4'),
    color: {
      title: 'blue',
      hex: '#C3D4FF',
    },
  },
];

const StatusComponent = (el: any) => {
  const t = useTranslations();
  return (
    <p style={{ color: getStatusColor(getProductStatus(el?.row)) }}>
      {getStatusText(t, getProductStatus(el?.row))}
    </p>
  );
};

const ResponsibleComponentStyled = styled.p<ResponsibleComponentProps>`
  padding: 7px 10px;
  border-radius: 100px;
  color: #000;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ResponsibleComponent = (el: any) => {
  const t = useTranslations();
  const responsible = responsiblesList(t)?.find(
    (responsible) => responsible.id === el?.row?.productionListOrders?.[0]?.responsibleId,
  );
  return (
    <ResponsibleComponentStyled backgroundColor={responsible?.color?.hex || 'unset'}>
      {responsible?.name}
    </ResponsibleComponentStyled>
  );
};

const DescriptionComponent = (el: any) => {
  return (
    <>
      {el?.row?.description}
      <RenderIf condition={el?.row?.isIndividualDescription}>
        <StarIcon style={{ fill: '#1976d2', marginLeft: '20px' }} />
      </RenderIf>
    </>
  );
};

const MobileCellComponent = (el: any) => {
  const status = getProductStatus(el?.row);

  return (
    <>
      <MobileCellComponentContainer>
        <MobileCellHeader>{`${el?.row?.articleNum} ${el?.row?.description}`}</MobileCellHeader>
        <MobileCellStatus>
          {/* TODO: translations */}
          <span>Status:</span>
          <p style={{ color: getStatusColor(status) }}>{status}</p>
        </MobileCellStatus>
        <MobileCellAmount>
          {/* TODO: translations */}
          <span>Bestellt:</span>
          <p>{el?.row?.sumAmount}</p>
        </MobileCellAmount>
      </MobileCellComponentContainer>
    </>
  );
};

export const ProductImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
`;

export const ProductImageMobile = styled(ProductImage)`
  width: 100px;
  height: 100px;
  /* margin: 8px; */
  /* object-fit: cover;
  border-radius: 4px; */
`;

export const getColumns = (t: (value: string) => string) => {
  switch (getLayout()) {
    case 'MOBILE':
      return mobileColumns(t);
    default:
      return columns(t);
  }
};
export const mobileColumns = (t: (value: string) => string) => [
  {
    field: 'imageUrl',
    headerName: t('common.picture'),
    width: 120,
    renderCell: (params: any) => {
      if (params.value) {
        return <ProductImageMobile src={params.value} alt={t('common.picture')} />;
      } else {
        return '';
      }
    },
  },
  {
    field: 'articleNum',
    headerName: t('common.productNumber'),
    flex: 1,
    renderCell: MobileCellComponent,
  },
];

export const columns = (t: (value: string) => string) => [
  {
    field: 'imageUrl',
    headerName: t('common.picture'),
    width: 72,
    renderCell: (params: any) => {
      if (params.value) {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <ProductImage src={params.value} alt={t('common.picture')} />
          </Box>
        );
      } else {
        return '';
      }
    },
  },
  {
    field: 'articleNum',
    headerName: t('common.productNumber'),
    width: 100,
  },
  {
    field: 'description',
    headerName: t('common.productName'),
    flex: 1,
    renderCell: DescriptionComponent,
  },
  {
    field: 'sumAmount',
    headerName: t('common.ordered'),
    width: 100,
    valueFormatter: (e: any) => +e.value,
  },
  {
    field: 'productStatus',
    headerName: t('common.productStatus'),
    width: 150,
    disableReorder: true,
    filterable: true,
    valueGetter: (e: any) => getProductStatus(e?.row),
    renderCell: StatusComponent,
  },
  {
    field: 'responsibleId',
    headerName: t('common.responsible'),
    width: 150,
    renderCell: ResponsibleComponent,
  },
  {
    field: 'toProduce',
    headerName: t('production.leftover'),
    width: 100,
    valueGetter: (params: any) => {
      const totalDistributed = params.row.productionListOrders?.reduce((acc: number, curr: any) => {
        return acc + curr.distributed;
      }, 0);
      if (totalDistributed) {
        return Number(totalDistributed || 0) - Number(params.row.sumAmount || 0);
      }
    },
  },
  {
    field: 'distributed',
    headerName: t('production.produced'),
    width: 100,
    valueGetter: (params: any) => {
      return params.row.productionListOrders?.reduce((acc: number, curr: any) => {
        return acc + Number(curr.distributed || 0);
      }, 0);
    },
  },
  {
    field: 'shippingPointName',
    headerName: t('common.shippingPoint'),
    width: 200,
  },
];

// TODO: remove infoStatus
export const filterModelFunc = (infoStatus?: AvailableStatuses) => {
  const shippingPointName = handleLocalStorage('shippingPointName');

  return {
    logicOperator: GridLogicOperator.And,
    items: [
      ...(shippingPointName
        ? [
            // {
            //   id: 2,
            //   field: 'shippingPointName',
            //   value: shippingPointName,
            //   operator: 'equals',
            // },
          ]
        : []),
    ],
  };
};

export const orderColumnVM = {
  shippingPointName: false,
};
