import styled from 'styled-components';

export const MainContainer = styled.div`
  padding: 30px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
    padding: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 0;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
