import { Auth as aws } from 'aws-amplify';

export const getGuid = async (): Promise<string | null> => {
  const currentUserInfo = await aws.currentAuthenticatedUser();
  const attributes = currentUserInfo?.attributes;
  if (attributes && attributes?.['custom:company']) {
    return attributes?.['custom:company'];
  }
  return null;
};
