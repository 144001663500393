import { FC, useEffect, useState } from 'react';
import { FormControlLabel, TextField, Radio, RadioGroup } from '@mui/material';

import { RenderIf } from 'shared/helpers/renderIf';
import { RadioInputContainer, TextFieldBox } from './styles';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useStore } from 'effector-react';
import { startModalStore } from 'shared/store';
import {
  updateAmountType,
  updateCalcNumber,
  updateFixedNumber,
} from 'shared/store/startModalStore';
import { getLayout } from 'shared/helpers/getLayout';

export const AmountInputs: FC<IAmountInputs> = ({ initValue, isManual }) => {
  // Hooks
  const t = useTranslations();

  // Store
  const { fixedNumber, calcNumber, amountType } = useStore(startModalStore);

  // State
  const [basketsAmount, setBasketsAmount] = useState<''>('');
  const [itemsInBaskets, setItemsInBaskets] = useState<''>('');
  const [leftover, setLeftover] = useState<''>('');

  useEffect(() => {
    updateCalcNumber(getItemsInAllBaskets());
  }, [basketsAmount, itemsInBaskets, leftover]); // eslint-disable-line

  useEffect(() => {
    if (initValue && !isManual) {
      updateFixedNumber(initValue);
    }
  }, [initValue, isManual]);

  useEffect(() => {
    if (isManual) {
      updateAmountType('fixed');
    }
  }, [isManual]);

  // Functions
  const handleAmountType = (e: any) => {
    updateAmountType(e.target.value);
  };

  const handleFixedNumber = (e: any) => {
    updateFixedNumber(e.target.value);
  };

  const getItemsInAllBaskets = () => {
    const v1 = basketsAmount?.length ? +basketsAmount : 0;
    const v2 = itemsInBaskets?.length ? +itemsInBaskets : 0;
    const v3 = leftover?.length ? +leftover : 0;
    return v1 * v2 + v3;
  };

  const getRadioGroupStyles = (): any => {
    switch (getLayout()) {
      case 'MOBILE':
        return {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '14px',
        };
      default:
        return {
          gap: '14px',
          margin: '7px 0',
        };
    }
  };

  const getInputStyles = (): any => {
    switch (getLayout()) {
      case 'MOBILE':
        return {
          input: { textAlign: 'center' },
          width: '90vw',
          alignSelf: 'center',
          marginTop: '20px',
        };
      default:
        return { input: { textAlign: 'center' }, width: '300px' };
    }
  };

  return (
    <RadioInputContainer>
      <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleAmountType}
        sx={getRadioGroupStyles()}
      >
        <FormControlLabel
          value="fixed"
          disabled={isManual}
          control={<Radio />}
          label={t('production.fixedAmount')}
          checked={amountType === 'fixed'}
          sx={{ color: 'text.primary' }}
        />
        <FormControlLabel
          value="calculate"
          disabled={isManual}
          control={<Radio />}
          label={t('production.calculate')}
          checked={amountType === 'calculate'}
          sx={{ color: 'text.primary' }}
        />
      </RadioGroup>

      {/* INPUTS */}
      <RenderIf condition={amountType === 'fixed'}>
        <TextField
          value={fixedNumber}
          disabled={isManual}
          onChange={handleFixedNumber}
          type="number"
          sx={getInputStyles()}
        />
      </RenderIf>
      <RenderIf condition={amountType === 'calculate'}>
        <TextFieldBox>
          <TextField
            value={itemsInBaskets}
            onChange={(e: any) => setItemsInBaskets(e.target.value)}
            type="number"
            placeholder={t('production.perBasket')}
            sx={{ input: { textAlign: 'center', width: '76px' } }}
          />
          x
          <TextField
            value={basketsAmount}
            onChange={(e: any) => setBasketsAmount(e.target.value)}
            type="number"
            placeholder={t('common.baskets')}
            sx={{ input: { textAlign: 'center', width: '76px' } }}
          />
          +
          <TextField
            value={leftover}
            onChange={(e: any) => setLeftover(e.target.value)}
            type="number"
            placeholder={t('common.rest')}
            sx={{ input: { textAlign: 'center', width: '76px' } }}
          />
          =
          <TextField
            value={calcNumber}
            type="number"
            sx={{ input: { textAlign: 'center', width: '76px' } }}
          />
        </TextFieldBox>
      </RenderIf>
    </RadioInputContainer>
  );
};

interface IAmountInputs {
  initValue?: number;
  isManual: boolean;
}
