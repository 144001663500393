import { FC } from 'react';
import { Typography } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

export const Text: FC<IText> = ({ sx = {}, ...props }) => {
  return (
    <Typography
      sx={{
        ...sx,
        color: 'text.primary',
      }}
      {...props}
    />
  );
};

interface IText extends DefaultComponentProps<any> {}
