import { FC } from 'react';
import { Button } from 'entities/Button';
import { ButtonsContainer, InputsContainer } from '../styles';
import { RenderIf } from 'shared/helpers/renderIf';
import { getProductStatus } from 'shared/helpers/getProductStatus';
import {
  SmartCalcErrors,
} from 'shared/helpers/smartCalculation/helpers';
import { ProductToUpdate, ProductType } from 'shared/types';
import { closeProduct } from 'shared/store/productionListStore';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Select } from 'entities/Select';
import { responsiblesList } from 'widgets/ProductionListGrid/consts';
import { MobileProdContainer, ProdListText, TableContainer } from './styled';
import { Disclaimer } from '../disclaimerButton';
import { AmountInputs } from '../AmountInputs';
import { Table } from 'entities/Table';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { getColumns } from '../consts';
import { ModalButtons } from 'widgets/ProductionListModals/ModalButtons';

export const MobileOrderModal: FC<MobileOrderModalProps> = ({
  product,
  isLoading,
  error,
  selectedUser,
  setSelectedUser,
  isStatusToDo,
  getIsStartDisabled,
  handleStartClick,
  setIsNextModalOpen,
  productInProgressId,
  setProductInProgressId,
  productsList,
  getInitAmountValue,
  isManualDistributionEnabled,
  ordersToDisplay = [],
  productsToUpdate,
  startClosing,
}) => {
  const t = useTranslations();
  const apiRef = useGridApiRef();

  return (
    <MobileProdContainer>
      <ModalButtons
        closeMotherModal={closeProduct}
        startClosing={startClosing}
        ordersToDisplay={ordersToDisplay}
      />
      <ProdListText>{product?.description}</ProdListText>
      {/* RESPONSIBLE SELECT */}
      <div style={{ width: '100%' }}>
        {/* <StartModalHeader>{t('common.responsible')}</StartModalHeader> */}
        {/* TODO: update to show previously selected responsible by default */}
        <Select
          key={product?.articleId || ''}
          defaultOpen={false}
          width="100%"
          sx={{
            maxWidth: 'unset',
            minWidth: '90vw',
            alignSelf: 'center',
          }}
          value={selectedUser}
          handleValue={setSelectedUser}
          options={responsiblesList(t)?.map((el) => ({ ...el, color: el.color.hex }))}
          textColor="#000"
          keys={{
            optionValue: 'id',
          }}
        />
      </div>
      <ProdListText>{t('production.listToDistribute')}</ProdListText>
      <TableContainer>
        <Table
          apiRef={apiRef}
          getRowId={(item: any) => `${item.customerId}_${item.orderId}`}
          rows={ordersToDisplay}
          columns={getColumns(t, productsToUpdate)}
          rowHeight={108}
          slots={{
            columnHeaders: () => null,
          }}
        />
      </TableContainer>
      <ProdListText>{t('production.amount')}</ProdListText>
      <InputsContainer>
        {/* REST COMPONENTS */}
        <RenderIf
          condition={Boolean(
            !product?.productionListOrders?.every((order: any) => order.productStatus === 'DONE'),
          )}
        >
          {/* AMOUNT INPUTS */}
          <RenderIf condition={Boolean(!productInProgressId)}>
            <AmountInputs initValue={getInitAmountValue()} isManual={isManualDistributionEnabled} />
          </RenderIf>
        </RenderIf>

        {/* DISCLAIMER */}
        <RenderIf condition={Boolean(productInProgressId)}>
          <Disclaimer
            product={productsList?.find((p) => p.articleId === productInProgressId)}
            discardProduct={() => setProductInProgressId(null)}
          />
        </RenderIf>

        {/* ERRORS */}
        {/* TODO: reenable ErrorMessage */}
        {/* <RenderIf condition={Boolean(error)}>
          <ErrorMessage title={getErrorByEnum(t, error)}>
            <Text>{getSubErrorByEnum(t, error)}</Text>
          </ErrorMessage>
        </RenderIf> */}
      </InputsContainer>
      {/* BUTTONS */}
      <ButtonsContainer>
        <Button
          handleClick={closeProduct}
          isDisabled={isLoading}
          variant="outlined"
          sx={{
            margin: 0,
            height: '42px',
          }}
        >
          {t('buttons.cancel')}
        </Button>
        <RenderIf
          condition={
            (error === SmartCalcErrors.ALL || error === SmartCalcErrors.LIMIT) &&
            getProductStatus(product) !== 'Done'
          }
        >
          <Button
            handleClick={() => setIsNextModalOpen(true)}
            isDisabled={!selectedUser}
            variant="contained"
            sx={{
              margin: 0,
              height: '42px',
            }}
          >
            {t('buttons.next')}
          </Button>
        </RenderIf>
        <RenderIf
          condition={
            (error !== SmartCalcErrors.ALL && error !== SmartCalcErrors.LIMIT) ||
            getProductStatus(product) === 'Done'
          }
        >
          <Button
            handleClick={handleStartClick}
            isDisabled={getIsStartDisabled()}
            variant="contained"
            isLoading={isLoading}
            sx={{
              margin: 0,
              height: '42px',
            }}
          >
            {getProductStatus(product) === 'Done' ? t('buttons.restart') : t('buttons.start')}
          </Button>
        </RenderIf>
      </ButtonsContainer>
    </MobileProdContainer>
  );
};

type MobileOrderModalProps = {
  product: ProductType;
  isLoading: boolean;
  error: SmartCalcErrors | null;

  selectedUser: number | string;
  setSelectedUser: (v: number | string) => void;

  isManualDistributionEnabled: boolean;

  productInProgressId: number | null;
  setProductInProgressId: (v: number | null) => void;

  setIsNextModalOpen: (v: boolean) => void;
  startClosing: () => void;
  ordersToDisplay: any[];
  handleStartClick: () => void;
  productsList?: any[];

  productsToUpdate: ProductToUpdate[];
  isStatusToDo: (v: any) => boolean;
  getInitAmountValue: () => number;
  getIsStartDisabled: () => boolean;
  // handleRowEditCommit: any;
};
