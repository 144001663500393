import { AppHeader } from 'widgets/AppHeader';
import { withSideBarHOC } from 'shared/HOCs/withSideBarHOC';
import { useTranslations } from 'shared/hooks/useTranslations';
import { DistributionListGrid } from 'widgets/DistributionListGrid';
import { DistributionListContainer } from './styles';
import { DistributedListModals } from 'widgets/DistributionListModals';
import { CreateOrderButton } from 'features/CreateOrder';
import { DistributionListAccordion } from 'widgets/DistributionListAccordion';
import { RenderIf } from 'shared/helpers/renderIf';
import { getLayout } from 'shared/helpers/getLayout';

export const DistributionListComponent = () => {
  const t = useTranslations();

  return (
    <DistributionListContainer>
      <AppHeader pageName={t('distribution.distributionList')}>
        <CreateOrderButton />
      </AppHeader>
      <RenderIf condition={getLayout() === 'MOBILE'}>
        <DistributionListAccordion />
      </RenderIf>
      <RenderIf condition={getLayout() === 'DESKTOP'}>
        <DistributionListGrid />
      </RenderIf>
      <DistributedListModals />
    </DistributionListContainer>
  );
};

export const DistributionList = withSideBarHOC(DistributionListComponent);
