import { SideBarIcon } from 'entities/SideBarIcon';

import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';
import { useTranslations } from 'shared/hooks/useTranslations';

export const Navigation = () => {
  const t = useTranslations();
  return (
    <>
      <SideBarIcon
        routeName="/production_list"
        label={t('production.productionList')}
        icon={ArticleIcon}
      />
      <SideBarIcon
        routeName="/distribution_list"
        label={t('distribution.distributionList')}
        icon={PeopleIcon}
      />
    </>
  );
};
