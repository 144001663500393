export type NotificationType = {
  message: string | null;
  notificationId: string;
  timestamp: number;
  isRead?: boolean;
};

export type NotificationsStoreType = {
  notifications: NotificationType[];
};

export const initNotificationsStore = {
  notifications: [],
};
