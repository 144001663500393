import { GraphQLClient } from 'graphql-request';
import { environment } from 'environment';
import { setHasAccess } from 'shared/store/appStore';
import { getAuthToken } from 'shared/hooks/initApp/functions/getAuthToken';

const state = process.env.REACT_APP_ENV as keyof typeof environment;
const client = new GraphQLClient(environment[state].api);

export const gqlClient = async (gql: string, variables: Record<string, any> = {}) => {
  const token = await getAuthToken();
  if (token) {
    const res = await client.request(gql, variables, {
      Authorization: encodeURIComponent(token),
    });
    return res;
  } else {
    // so far all requests require auth token.
    setHasAccess(false);
  }
};
