import { Player } from '@lottiefiles/react-lottie-player';
import { FC, useEffect, useMemo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

export const PlayerContainer: FC<IPlayerContainerProps> = ({
  defaultAnimation,
  customAnimation,
  w,
  h,
  animationAppearance,
  animationDuration,
  zIndex,
  ...restProps
}) => {
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    const abortController = new AbortController();
    return () => {
      setIsMounted(false);
      abortController.abort();
    };
  }, []);

  const component = useMemo(() => {
    return (
      <Player
        autoplay
        loop
        src={customAnimation ? JSON.parse(customAnimation) : JSON.parse(defaultAnimation)}
        style={{ height: h ? `${h}px` : '200px', width: w ? `${w}px` : '200px' }}
      ></Player>
    );
  }, [isMounted]); //eslint-disable-line

  return (
    <>
      {isMounted ? (
        <Wrapper
          {...restProps}
          animationAppearance={animationAppearance}
          animationDuration={animationDuration}
          zIndex={zIndex}
        >
          {component}
        </Wrapper>
      ) : null}
    </>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeAnimation = (
  props: Pick<IPlayerContainerProps, 'animationAppearance' | 'animationDuration'>,
) => css`
  animation: ${() => {
      switch (props.animationAppearance) {
        case 'none':
          return 'none';
        case 'fadeIn':
          return fadeIn;
        case 'fadeOut':
          return fadeOut;
        default:
          return 'none';
      }
    }}
    ${props.animationDuration}ms;
`;

export const Wrapper = styled.div<
  Pick<IPlayerContainerProps, 'animationAppearance' | 'animationDuration' | 'zIndex'>
>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fadeAnimation}
  z-index: ${({ zIndex }) => zIndex};
`;

interface IPlayerContainerProps {
  defaultAnimation: string;
  customAnimation?: string | undefined;
  w?: number | undefined;
  h?: number | undefined;
  animationAppearance: 'none' | 'fadeIn' | 'fadeOut';
  animationDuration: number;
  zIndex: number;
}
