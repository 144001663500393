import { useState } from 'react';
import { Button } from 'entities/Button';
import PlusIcon from '@mui/icons-material/Add';
import { CreateOrderModal } from './CreateOrderModal';
import { useTranslations } from 'shared/hooks/useTranslations';

export const CreateOrderButton = () => {
  const t = useTranslations();
  const [isCreateOrderOpen, setIsCreateOrderOpen] = useState(false);

  return (
    <>
      <Button
        height="56px"
        width="56px"
        variant="contained"
        handleClick={() => setIsCreateOrderOpen(true)}
      >
        <PlusIcon />
        {/* {t('common.createOrder')} */}
      </Button>
      <CreateOrderModal
        isOpen={isCreateOrderOpen}
        onClose={() => setIsCreateOrderOpen(false)}
        headerTitle={t('common.createOrder')}
      />
    </>
  );
};
