import {
  ButtonsContainer,
  ErrorMessage,
  InputsContainer,
  OrderModalContainer,
  ProductImage,
  StartContainer,
  StartModalHeader,
} from '../styles';
import { ModalButtons } from '../../ModalButtons';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Table } from 'entities/Table';
import { RenderIf } from 'shared/helpers/renderIf';
import { Button } from 'entities/Button';
import { responsiblesList } from 'widgets/ProductionListGrid/consts';
import { aggregationModel, getColumns } from '../consts';
import { Disclaimer } from '../disclaimerButton';
import { Select } from 'entities/Select';
import { AmountInputs } from '../AmountInputs';
import { Text } from 'entities/Text';
import { getProductStatus } from 'shared/helpers/getProductStatus';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  SmartCalcErrors,
  getErrorByEnum,
  getSubErrorByEnum,
} from 'shared/helpers/smartCalculation/helpers';
import { FC } from 'react';
import { ProductToUpdate, ProductType } from 'shared/types';
import productPlaceholder from 'shared/images/productPlaceholder.svg';
import { closeProduct } from 'shared/store/productionListStore';

export const DesktopOrderModal: FC<DesktopOrderModalProps> = ({
  product,
  isLoading,
  error,
  selectedUser,
  setSelectedUser,
  isManualDistributionEnabled,
  setIsManualDistributionEnabled,
  productInProgressId,
  setProductInProgressId,
  setIsNextModalOpen,
  startClosing,
  ordersToDisplay,
  handleStartClick,
  productsList,
  productsToUpdate,
  isStatusToDo,
  getInitAmountValue,
  getIsStartDisabled,
  handleRowEditCommit,
}) => {
  const t = useTranslations();
  const apiRef = useGridApiRef();

  return (
    <OrderModalContainer>
      <ModalButtons
        closeMotherModal={closeProduct}
        startClosing={startClosing}
        ordersToDisplay={ordersToDisplay}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isManualDistributionEnabled}
              onChange={(e: any) => setIsManualDistributionEnabled(e.target.checked)}
            />
          }
          label={t('production.manualDistributing')}
          sx={{ marginRight: '0', color: 'text.primary' }}
        />
      </ModalButtons>

      <StartContainer>
        {/*  */}
        {/* PICTURE */}
        <ProductImage src={product?.imageUrl?.length ? product?.imageUrl : productPlaceholder} />

        {/* MAIN TABLE */}
        <Box height="350px" width="100%" overflow="scroll">
          <StartModalHeader>{t('production.listToDistribute')}</StartModalHeader>
          <Box height="286px">
            <Table
              apiRef={apiRef}
              editMode="row"
              getRowId={(item: any) => `${item.customerId}_${item.orderId}`}
              rows={ordersToDisplay || []}
              columns={getColumns(t, productsToUpdate)}
              processRowUpdate={handleRowEditCommit}
              onProcessRowUpdateError={(error: any) => console.log(error?.message)}
              aggregationModel={aggregationModel}
              isCellEditable={(e: any) => {
                if (e?.field === 'distributed') {
                  return e?.row?.productStatus === 'Done' || e?.row?.productStatus === 'DONE';
                }
                if (e?.field === 'justProduced') {
                  return isManualDistributionEnabled;
                }
                return false;
              }}
            />
          </Box>
        </Box>

        {/* RESPONSIBLE SELECT */}
        <div>
          <StartModalHeader>{t('common.responsible')}</StartModalHeader>
          <Select
            key={product?.articleId || ''}
            defaultOpen={isStatusToDo(product)}
            width="350px"
            value={selectedUser}
            handleValue={setSelectedUser}
            options={responsiblesList(t)?.map((el) => ({ ...el, color: el.color.hex }))}
            textColor="#000"
            keys={{
              optionValue: 'id',
            }}
          />
        </div>
        <InputsContainer>
          {/* REST COMPONENTS */}
          <RenderIf condition={Boolean(productInProgressId)}>
            <Disclaimer
              product={productsList?.find((p) => p.articleId === productInProgressId)}
              discardProduct={() => setProductInProgressId(null)}
            />
          </RenderIf>
          <RenderIf
            condition={Boolean(
              !product?.productionListOrders?.every((order: any) => order.productStatus === 'DONE'),
            )}
          >
            {/* DISCLAIMER */}

            {/* AMOUNT INPUTS */}
            <RenderIf condition={Boolean(!productInProgressId)}>
              <StartModalHeader>{t('production.amount')}</StartModalHeader>

              <AmountInputs
                initValue={getInitAmountValue()}
                isManual={isManualDistributionEnabled}
              />
            </RenderIf>
          </RenderIf>
          {/* ERRORS */}
          <RenderIf condition={Boolean(error)}>
            <ErrorMessage title={getErrorByEnum(t, error)}>
              <Text>{getSubErrorByEnum(t, error)}</Text>
            </ErrorMessage>
          </RenderIf>
        </InputsContainer>
      </StartContainer>
      {/* BUTTONS */}
      <ButtonsContainer>
        <Button
          handleClick={closeProduct}
          isDisabled={isLoading}
          variant="outlined"
          sx={{
            margin: 0,
            height: '42px',
            width: '200px',
          }}
        >
          {t('buttons.cancel')}
        </Button>
        <RenderIf
          condition={
            (error === SmartCalcErrors.ALL || error === SmartCalcErrors.LIMIT) &&
            getProductStatus(product) !== 'Done'
          }
        >
          <Button
            handleClick={() => setIsNextModalOpen(true)}
            isDisabled={!selectedUser}
            variant="contained"
            sx={{
              margin: 0,
              height: '42px',
              width: '200px',
            }}
          >
            {t('buttons.next')}
          </Button>
        </RenderIf>
        <RenderIf
          condition={
            (error !== SmartCalcErrors.ALL && error !== SmartCalcErrors.LIMIT) ||
            getProductStatus(product) === 'Done'
          }
        >
          <Button
            handleClick={handleStartClick}
            isDisabled={getIsStartDisabled()}
            variant="contained"
            isLoading={isLoading}
            sx={{
              margin: 0,
              height: '42px',
              width: '200px',
            }}
          >
            {getProductStatus(product) === 'Done' ? t('buttons.restart') : t('buttons.start')}
          </Button>
        </RenderIf>
      </ButtonsContainer>
    </OrderModalContainer>
  );
};

type DesktopOrderModalProps = {
  product: ProductType;
  isLoading: boolean;
  error: SmartCalcErrors | null;

  selectedUser: number | string;
  setSelectedUser: (v: number | string) => void;

  isManualDistributionEnabled: boolean;
  setIsManualDistributionEnabled: (v: boolean) => void;

  productInProgressId: number | null;
  setProductInProgressId: (v: number | null) => void;

  setIsNextModalOpen: (v: boolean) => void;
  startClosing: () => void;
  ordersToDisplay: any[];
  handleStartClick: () => void;
  productsList?: any[];

  productsToUpdate: ProductToUpdate[];
  isStatusToDo: (v: any) => boolean;
  getInitAmountValue: () => number;
  getIsStartDisabled: () => boolean;
  handleRowEditCommit: any;
};
