import { FC } from 'react';
import { Modal } from 'entities/Modal';
import { RenderIf } from 'shared/helpers/renderIf';
import { Divider, LinearProgress, Drawer } from '@mui/material';
import { getLayout } from 'shared/helpers/getLayout';
import { Text } from 'entities/Text';

export const withModalHOC =
  (Component: FC<any>): FC<any> =>
  ({ isOpen, onClose, headerTitle, progress, ...props }) => {
    return (
      <>
        <RenderIf condition={getLayout() === 'MOBILE'}>
          <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
            <Component onClose={onClose} {...props} />
          </Drawer>
        </RenderIf>
        <RenderIf condition={getLayout() === 'DESKTOP'}>
          <Modal isOpen={isOpen} onClose={onClose}>
            <RenderIf condition={Boolean(headerTitle)}>
              <Text sx={{ padding: '16px 24px', fontWeight: 500 }}>{headerTitle}</Text>
              <Divider />
            </RenderIf>
            <RenderIf condition={Boolean(progress)}>
              <LinearProgress
                sx={{ position: 'absolute', width: '100%', zIndex: 99999 }}
                variant="determinate"
                value={progress}
              />
            </RenderIf>
            <Component onClose={onClose} {...props} />
          </Modal>
        </RenderIf>
      </>
    );
  };
