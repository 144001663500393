import { Loader } from 'app/pageLoader/loaderComponent';
import { useStore } from 'effector-react';
import { Accordion } from 'entities/Accordion';
import { AccordionProducts } from 'entities/AccordionProducts';
import { NoOrders } from 'features/NoRows/noOrders';
import { useMemo } from 'react';
import { DATE_FORMAT } from 'shared/globalConsts';
import { getLayout } from 'shared/helpers/getLayout';
import { RenderIf } from 'shared/helpers/renderIf';
import { useTranslations } from 'shared/hooks/useTranslations';
import { appStore, distributionStore, globalStore } from 'shared/store';
import { DistributionGate } from 'shared/store/distributionListStore';

export const DistributionListAccordion = () => {
  const t = useTranslations();
  const { date, selectedPart, searchText } = useStore(globalStore);
  const { shippingPoint: shippingPointName } = useStore(appStore);

  const { lists, isDistrListLoading: isLoading } = useStore(distributionStore);

  // useMemo
  const ordersData = useMemo(() => {
    const res = lists?.[date.format(DATE_FORMAT)]?.ordersList
      ?.filter((el) => {
        const isShippingPoontFiltered = el?.products?.some(
          (product) => product?.shippingPointName === shippingPointName,
        );
        const isFilteredByText =
          el?.internalName?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) ||
          el?.customerNum?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase());
        const isShownBySelectedPart = el?.deliveryCycleNum
          ? el?.deliveryCycleNum === selectedPart
          : el;
        return isFilteredByText && isShippingPoontFiltered && isShownBySelectedPart;
      })
      ?.reduce((acc: any, curr) => {
        const rotePlan: any = curr?.rotePlan ?? t('distribution.noTourAssigned');
        if (!acc[rotePlan]) {
          acc[rotePlan] = [];
        }
        acc[rotePlan].push(curr);
        return acc;
      }, []);
    return res;
  }, [date, lists, selectedPart, searchText]); //eslint-disable-line

  // TODO: move to helpers
  const getHeight = () => {
    switch (getLayout()) {
      case 'MOBILE':
        return 'calc(100vh - 225px)';
      default:
        return 'calc(100vh - 178px)';
    }
  };

  return (
    <div style={{ height: getHeight(), margin: '0 20px', overflowY: 'scroll' }}>
      <DistributionGate date={date} />
      <RenderIf condition={isLoading}>
        <Loader isLoading={isLoading} />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <RenderIf condition={Boolean(!lists?.[date.format(DATE_FORMAT)]?.ordersList?.length)}>
          <NoOrders />
        </RenderIf>
        <RenderIf condition={Boolean(lists?.[date.format(DATE_FORMAT)]?.ordersList?.length)}>
          <Accordion
            items={Object.entries(ordersData || [])?.map(([summary, items]: any) => {
              return {
                summary,
                Component: AccordionProducts,
                componentProps: { items },
              };
            })}
          />
        </RenderIf>
      </RenderIf>
    </div>
  );
};
