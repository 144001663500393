import InfoIcon from '@mui/icons-material/Info';
import { InfoContainer, HeaderText, SubHeaderText } from './styles';
import { useTranslations } from 'shared/hooks/useTranslations';

export const FilteredOut = () => {
  const t = useTranslations();

  return (
    <InfoContainer>
      <InfoIcon sx={{ fontSize: '5rem', color: 'orange' }} />
      <HeaderText>{t('production.noOrdersForParameters')}</HeaderText>
      <SubHeaderText>{t('production.changeDateOrFilters')}</SubHeaderText>
    </InfoContainer>
  );
};
