import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export const availableParts = [
  {
    value: 1,
    name: 'Fahrt I',
    icon: LocalShippingIcon,
  },
  {
    value: 2,
    name: 'Fahrt II',
    icon: LocalShippingIcon,
  },

  {
    value: 3,
    name: 'Fahrt III',
    icon: LocalShippingIcon,
  },
  {
    value: 4,
    name: 'Fahrt IV',
    icon: LocalShippingIcon,
  },
  {
    value: 5,
    name: 'Fahrt V',
    icon: LocalShippingIcon,
  },
  {
    value: 6,
    name: 'Fahrt VI',
    icon: LocalShippingIcon,
  },
  {
    value: 7,
    name: 'Fahrt VII',
    icon: LocalShippingIcon,
  },
  {
    value: 8,
    name: 'Fahrt VIII',
    icon: LocalShippingIcon,
  },
  {
    value: 9,
    name: 'Fahrt IX',
    icon: LocalShippingIcon,
  },
  {
    value: 10,
    name: 'Fahrt X',
    icon: LocalShippingIcon,
  },
];
