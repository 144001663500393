import styled from 'styled-components';

type OrdersModalContainerProps = {
  isMobile: boolean;
};

export const OrdersModalContainer = styled('div')<OrdersModalContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100vw' : '90vw')};
  height: ${({ isMobile }) => (isMobile ? 'unset' : '80vh')};
  /* height: 80vh; */
  padding: 20px;
`;
