import { FC } from 'react';
import { Divider, Indicator, NavContainer, NavItem, NavText } from './styles';
import CheckMark from '@mui/icons-material/Check';
import { useTranslations } from 'shared/hooks/useTranslations';

const steps = (t: (v: string) => string) => [
  t('distribution.selectCustomer'),
  t('common.addProducts'),
  t('buttons.confirmAndSave'),
];

export const Nav: FC<INav> = ({ step }) => {
  const t = useTranslations();

  return (
    <NavContainer>
      {steps(t)?.map((el, i) => (
        <>
          {i ? <Divider /> : ''}
          <NavItem>
            <Indicator isActive={step >= i + 1}>{step > i + 1 ? <CheckMark /> : i + 1}</Indicator>
            <NavText>{el}</NavText>
          </NavItem>
        </>
      ))}
    </NavContainer>
  );
};

interface INav {
  step: 1 | 2 | 3;
}
