import { FC } from 'react';
import { Moment } from 'moment';
import { MuiCalendar } from 'entities/MuiCalendar';
import { useStore } from 'effector-react';
import { globalStore } from 'shared/store';
import { setNewDate } from 'shared/store/globalStore';

export const Calendar: FC = () => {
  const { date } = useStore(globalStore);

  const handleDateChange = (date: Moment | null) => {
    if (date) {
      setNewDate(date);
    }
  };

  return <MuiCalendar date={date} onChangeDate={handleDateChange} />;
};
