import { FC, useState } from 'react';
import { AppBar, Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';

import { Navigation } from 'features/Navigation';
import {
  DrawerContainer,
  DrawerInnerContainer,
  FakeCloser,
  MobileDrawer,
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from './styled';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { SidebarHandlers } from './SidebarHandlers';
import { useStore } from 'effector-react';
import { appStore } from 'shared/store';
import { changeSearchText } from 'shared/store/globalStore';

export const ModileSidebar: FC = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const { themeColor } = useStore?.(appStore);

  return (
    <Box>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => setIsSideBarOpen((prevValue) => !prevValue)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          />
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            {/* TODO: translation */}
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e: any) => changeSearchText(e.currentTarget.value)}
            />
          </Search>
        </Toolbar>
      </AppBar>
      <MobileDrawer open={isSideBarOpen} variant="permanent">
        <DrawerContainer>
          <DrawerInnerContainer>
            <Toolbar />
            <Navigation />
          </DrawerInnerContainer>
          <SidebarHandlers themeColor={themeColor} />
        </DrawerContainer>
        <FakeCloser onClick={() => setIsSideBarOpen(false)} />
      </MobileDrawer>
      <Toolbar />
      {children}
    </Box>
  );
};
