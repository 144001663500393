import { FC, useEffect, useState } from 'react';
import { RenderIf } from 'shared/helpers/renderIf';
import { withModalHOC } from 'shared/HOCs/withModalHOC';

import { Nav } from './nav';
import { SelectCustomer } from './selectCustomer';
import { AddProducts } from './addProducts';
import { Confirm } from './confirm';
import { loadAllProducts } from 'shared/store/globalStore';
import { getClisentsList } from 'shared/store/settingsStore';
import { Product } from './types';

export const CreateOrderModalComponent: FC<CreateOrderModalProps> = ({ onClose }) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);

  //
  const [customerId, setCustomerId] = useState<number>();
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  useEffect(() => {
    getClisentsList();
    loadAllProducts();
  }, []);

  const addProduct = (newProduct: Product) => {
    setSelectedProducts((prevValue) => [...prevValue, newProduct]);
  };

  const deleteProduct = (productId: string) => {
    setSelectedProducts((prevValue) =>
      prevValue?.filter((el) => Number(el.linkArticle) !== Number(productId)),
    );
  };
  //

  return (
    <>
      <Nav step={step} />
      <RenderIf condition={step === 1}>
        <SelectCustomer
          toNextStep={() => setStep(2)}
          customerId={customerId}
          setCustomerId={setCustomerId}
        />
      </RenderIf>
      <RenderIf condition={step === 2}>
        <AddProducts
          toNextStep={() => setStep(3)}
          selectedProducts={selectedProducts}
          addProduct={addProduct}
          deleteProduct={deleteProduct}
        />
      </RenderIf>
      <RenderIf condition={step === 3}>
        <Confirm
          toPrevStep={() => setStep(2)}
          closeModal={onClose}
          customerId={customerId}
          selectedProducts={selectedProducts}
        />
      </RenderIf>
    </>
  );
};

interface CreateOrderModalProps {
  onClose: () => void;
}

export const CreateOrderModal = withModalHOC(CreateOrderModalComponent);
