import styled from 'styled-components';
import ResetIcon from '@mui/icons-material/ResetTv';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

export const DisplaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 422px;
  gap: 20px;
`;

export const CellComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const resetCell = (openReset: (value: string) => void) => (el: any) => {
  const onResetClick = () => {
    openReset(el.row.sku);
  };
  return (
    <CellComponent onClick={onResetClick}>
      <ResetIcon />
    </CellComponent>
  );
};

export const deleteCell = (openSN: (value: number) => void) => (el: any) => {
  const onDeleteClick = () => {
    openSN(el.row.serialNumber);
  };
  return (
    <CellComponent onClick={onDeleteClick}>
      <DeleteIcon />
    </CellComponent>
  );
};
