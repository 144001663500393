import { FC } from 'react';
import { SideBar } from 'widgets/Sidebar';

export const withSideBarHOC =
  (Component: FC<any>): FC<any> =>
  (props) =>
    (
      <SideBar>
        <Component {...props} />
      </SideBar>
    );
