export const options = [
  {
    id: 'de',
    name: 'German',
  },
  {
    id: 'en',
    name: 'English',
  },
];
