import { FC } from 'react';
import { Text } from 'entities/Text';
import styled from 'styled-components';

export const MobileProdContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: 32px 56px 32px 1fr 32px 118px 42px;
  position: relative;
  margin: 40px 5vw;
  align-items: center;
  max-width: 90vw;
  /* margin:  */
`;

export const TableContainer = styled.div`
  max-height: calc(100vh - 700px);
  height: calc(100vh - 700px);
  max-width: 100vw;
  @media (max-width: 800px) {
    max-width: 90vw;
  }
`;

export const ProdListText: FC<any> = ({ children }) => {
  return (
    <Text
      sx={{
        fontSize: '25x',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0.15px',
        textAlign: 'left',
      }}
    >
      {children}
    </Text>
  );
};
