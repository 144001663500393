import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import { setTheme, updateTheme } from 'shared/store/appStore';

export const checkSelectedTheme = async () => {
  const selectedTheme = handleLocalStorage('selectedTheme');
  if (!selectedTheme) {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    updateTheme(darkThemeMq.matches ? 'dark' : 'light');
  } else {
    setTheme(selectedTheme);
  }
};
