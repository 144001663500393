import { Checkbox, TextField } from '@mui/material';
import { Loader } from 'app/pageLoader/loaderComponent';
import { useStore } from 'effector-react';
import { SettingsTemplate } from 'entities/SettingsTemplate';
import { ChangeEvent, useState } from 'react';
import { useTranslations } from 'shared/hooks/useTranslations';
import { appStore, settingsStore } from 'shared/store';
import { updatePrinterSettings } from 'shared/store/settingsStore';
import { PrinterFieldsContainer, UseLocalConnectionContainer } from './styles';
import { Text } from 'entities/Text';

export const Printers = () => {
  const t = useTranslations();

  const { isLocalConnection, localIP, printerId } = useStore(settingsStore).printerData;
  const { isPrinterDataInit } = useStore(settingsStore);
  const { shippingPoint } = useStore(appStore);

  const [printerID, setPrinterID] = useState<string>(printerId);
  const [host, setHost] = useState<string>(localIP);
  const [checked, setChecked] = useState<boolean>(isLocalConnection);

  const handleUpdate = () => {
    printerID &&
      updatePrinterSettings({
        params: {
          localIP: host,
          printerId: printerID,
          isLocalConnection: checked,
          shippingPoint: shippingPoint || '',
        },
      });
  };

  return (
    <>
      <SettingsTemplate
        headerTitle={t('settings.printersTitle')}
        // TODO: 7: 7 fix
        // onReset={() => console.log('CLOSE')}
        onSave={handleUpdate}
      >
        <Loader isLoading={!isPrinterDataInit} />
        <PrinterFieldsContainer>
          <UseLocalConnectionContainer>
            <Checkbox
              disabled={!isPrinterDataInit}
              defaultChecked={isLocalConnection}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked)}
            />
            <Text>{t('common.useLocalConnection')}</Text>
          </UseLocalConnectionContainer>
          <div />
          <TextField
            defaultValue={printerId}
            disabled={!isPrinterDataInit}
            onChange={(e: any) => setPrinterID(e.target.value)}
            label={t('settings.printers')}
            placeholder={t('settings.printerPlaceholder')}
          />
          <TextField
            defaultValue={localIP}
            disabled={!checked || !isPrinterDataInit}
            onChange={(e: any) => setHost(e.target.value)}
            label={t('settings.ipAddress')}
            placeholder={t('settings.ipAddressEnter')}
          />
        </PrinterFieldsContainer>
      </SettingsTemplate>
    </>
  );
};
