import { AccordionDetails, AccordionSummary, Accordion as MUIAccordion } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid-premium';
import { FC } from 'react';

export const Accordion: FC<AccordionProps> = ({ items }) => {
  return (
    <>
      {items?.map(({ Component, ...item }) => (
        <MUIAccordion>
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p>{item.summary}</p>
          </AccordionSummary>
          <AccordionDetails>
            <Component {...item.componentProps} />
          </AccordionDetails>
        </MUIAccordion>
      ))}
    </>
  );
};

type AccordionItem = {
  summary: string;
  Component: any;
  componentProps: any;
};

type AccordionProps = {
  items: Array<AccordionItem>;
};
