import { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';

import { Button } from 'entities/Button';
import { globalStore } from 'shared/store';
import { Calendar } from 'widgets/Calendar';
import { useLocation } from 'react-router-dom';
import { productionStore } from 'shared/store';
import { SearchBar } from 'features/SearchBar';
import { distributionStore } from 'shared/store';
import { RenderIf } from 'shared/helpers/renderIf';
import { PartSelector } from 'features/PartSelector';
import { FilterProdList } from 'features/FilterProdList';
import { getProductionList } from 'shared/store/productionListStore';
import { ShippingPointSelector } from 'widgets/ShippingPointSelector';
import { getDistributionList } from 'shared/store/distributionListStore';
import SyncIcon from '@mui/icons-material/Autorenew';

import { AppHeaderContainer, TopLine, BottomLine, ButtonsContainer } from './styles';
import { getShippingPoints } from 'shared/store/settingsStore';
import { Notifications } from 'widgets/Notifications';
import { Text } from 'entities/Text';

export const DesktopHeader: FC<IAppHeader> = ({ pageName, children }) => {
  const location = useLocation();

  const { date } = useStore(globalStore);
  const { isProdListLoading } = useStore(productionStore);
  const { isDistrListLoading } = useStore(distributionStore);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getShippingPoints();
  }, []);

  useEffect(() => {
    if (!isProdListLoading && !isDistrListLoading) {
      setIsLoading(false);
    }
  }, [isProdListLoading, isDistrListLoading]);

  const handleSync = () => {
    if (!isLoading) {
      setIsLoading(true);
      if (location.pathname === '/production_list') {
        getProductionList({ date, forceLoad: true });
      }
      if (location.pathname === '/distribution_list') {
        getDistributionList({ date, forceLoad: true });
      }
    }
  };

  return (
    <AppHeaderContainer>
      <TopLine>
        <Text variant="h4" sx={{ color: 'text.primary' }}>
          {pageName || ''}
        </Text>
        <ButtonsContainer>
          <Button handleClick={handleSync} width="unset" variant="outlined">
            <SyncIcon
              sx={{
                animation: `spin ${isLoading ? '2s' : '0s'} ease-in infinite`,
              }}
            />
          </Button>
          <Notifications />
          <ShippingPointSelector />
        </ButtonsContainer>
      </TopLine>
      <BottomLine>
        <ButtonsContainer>
          <Calendar />
          <SearchBar />
          <RenderIf condition={location.pathname === '/production_list'}>
            <FilterProdList />
          </RenderIf>
          <PartSelector />
        </ButtonsContainer>
        {children}
      </BottomLine>
    </AppHeaderContainer>
  );
};

interface IAppHeader {
  pageName?: string;
}
