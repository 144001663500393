import styled from 'styled-components';

export const PrinterFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px 56px;
  gap: 20px;
`;

export const UseLocalConnectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
