import { FC } from 'react';
import { useStore } from 'effector-react';
import { Button } from 'entities/Button';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import { settingsStore } from 'shared/store';
import { Contanier, SelectContanier, StepName } from './styles';
import { Loader } from 'app/pageLoader/loaderComponent';
import { getLayout } from 'shared/helpers/getLayout';

export const SelectCustomer: FC<SelectCustomerProps> = ({
  toNextStep,
  customerId,
  setCustomerId,
}) => {
  const t = useTranslations();
  const { customersList } = useStore(settingsStore);

  const handleCustomerNameUpdate = (_event: any, newValue: any) => {
    setCustomerId(newValue?.customerId);
  };

  return (
    <Contanier>
      <StepName>{t('common.customer')}</StepName>
      <Loader isLoading={Boolean(!customersList?.length)} />
      <SelectContanier>
        <Autocomplete
          // TODO: translation
          loadingText="LOADING>>>"
          disablePortal
          id="combo-box-demo"
          options={customersList?.filter((el) => el?.customerId)}
          sx={{ width: getLayout() === 'DESKTOP' ? 185 : '100%' }}
          value={
            customersList?.find((el) => {
              return el?.customerId === customerId;
            }) || null
          }
          getOptionLabel={(option: any) => option.customerNo || ''}
          renderInput={(params) => {
            return <TextField {...params} label={t('common.customerNumber')} />;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.customerId}>
                {option?.customerNo}
              </li>
            );
          }}
          onChange={handleCustomerNameUpdate}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={customersList?.filter((el) => el?.customerId)}
          sx={{ width: '100%' }}
          value={
            customersList?.find((el) => {
              return el?.customerId === customerId;
            }) || null
          }
          getOptionLabel={(option: any) => option.internalOrFullName || ''}
          renderInput={(params) => {
            return <TextField {...params} label={t('common.companyName')} />;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.customerId}>
                {option?.internalOrFullName}
              </li>
            );
          }}
          onChange={handleCustomerNameUpdate}
        />
      </SelectContanier>
      <Button
        variant="contained"
        width="100%"
        handleClick={toNextStep}
        isDisabled={Boolean(!customerId)}
      >
        {t('buttons.save')}
      </Button>
    </Contanier>
  );
};

interface SelectCustomerProps {
  toNextStep: () => void;
  customerId?: number;
  setCustomerId: (v: number) => void;
}
