import { FC, useState } from 'react';
import RestartIcon from '@mui/icons-material/RestartAlt';
import { ConfirmationModal } from 'entities/ConfirmationModal';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';
import { getDistributorId } from 'shared/helpers/getDistributorId';
import { Button } from 'entities/Button';
import { useStore } from 'effector-react';
import { globalStore } from 'shared/store';
import { DATE_FORMAT } from 'shared/globalConsts';
import { productionStore } from 'shared/store';
import { discardProduct } from 'shared/store';
import { getLayout } from 'shared/helpers/getLayout';

export const ResetButton: FC<IResetButton> = ({ closeMotherModal }) => {
  //
  // Hooks
  const t = useTranslations();
  const [progress, startClosing] = useCloseModalWithDelay(closeMotherModal, 2000);

  // State
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const { currentOpenProduct: product } = useStore(productionStore);

  // State
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const startResettingProduct = async () => {
    startClosing();
    await discardProduct({
      params:
        product?.productionListOrders
          ?.filter((o1: any) => o1.productStatus) // no need to reset "to do" product
          ?.map((order: any) => {
            return {
              dateFor: date,
              productId: Number(product?.articleId),
              productName: `${product?.description}`,
              partNum: product?.deliveryCycleNum,
              orderId: Number(order?.orderId),
              productStatus: order.productStatus,
              responsibleId: getDistributorId(order?.responsibleId as number),
              customerNum: order.customerNum,
            };
          }) || [],
    });
    closeMotherModal();
  };

  return (
    <>
      <Button
        variant={getLayout() === 'DESKTOP' ? 'outlined' : 'text'}
        color="error"
        handleClick={() => {
          setIsResetModalOpen(true);
        }}
        height="56px"
        width="56px"
      >
        <RestartIcon />
      </Button>

      <ConfirmationModal
        isOpen={isResetModalOpen}
        progress={progress}
        headerTitle={t('common.areYouSure')}
        text={t('production.resetProgressQuestion')}
        onClose={() => setIsResetModalOpen(false)}
        onConfirm={startResettingProduct}
      />
    </>
  );
};

interface IResetButton {
  closeMotherModal: () => void;
}
