import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';

import { DistributionGate, openOrder } from 'shared/store/distributionListStore';
import { filterByShippingPoint } from 'shared/helpers/filterByShippingPoint';
import { useTranslations } from 'shared/hooks/useTranslations';
import { appStore, globalStore } from 'shared/store';
import { NoOrders } from 'features/NoRows/noOrders';
import { DATE_FORMAT } from 'shared/globalConsts';
import { distributionStore } from 'shared/store';
import { Table } from 'entities/Table';

import { columns, distrGridColumnVM, getInitialState } from './consts';
import { useUpdateDeviceStatus } from 'shared/hooks/useCheckDeviceStatus';

export const DistributionListGrid = () => {
  //
  // Hooks
  useUpdateDeviceStatus('distribution');
  const t = useTranslations();
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden(getInitialState(apiRef));
  const { shippingPoint: shippingPointName } = useStore(appStore);

  // Store
  const { date, selectedPart, searchText } = useStore(globalStore);
  const { lists, isDistrListLoading: isLoading } = useStore(distributionStore);

  // useMemo
  const ordersData = useMemo(() => {
    return lists?.[date.format(DATE_FORMAT)]?.ordersList
      ?.filter((el) => {
        const isShippingPoontFiltered = el?.products?.some(
          (product) => product?.shippingPointName === shippingPointName,
        );
        const isFilteredByText =
          el?.internalName?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) ||
          el?.customerNum?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase());
        const isShownBySelectedPart = el?.deliveryCycleNum
          ? el?.deliveryCycleNum === selectedPart
          : el;
        return isFilteredByText && isShippingPoontFiltered && isShownBySelectedPart;
      })
      .map((order) => ({
        ...order,
        rotePlan: order?.rotePlan || t('distribution.noTourAssigned'),
      }));
  }, [date, lists, selectedPart, searchText]); //eslint-disable-line

  // Functions
  const handleOrderClick = (e: any) => {
    openOrder(e?.row?.orderId);
  };

  return (
    <div style={{ height: 'calc(100vh - 178px)', margin: '0 20px' }}>
      <DistributionGate date={date} />
      <Table
        apiRef={apiRef}
        loading={isLoading}
        onRowClick={handleOrderClick}
        initialState={initialState}
        // NOTE: add type
        getRowId={(item: any) => `${item.orderId}_${item.deliveryCycleNum}`}
        //
        columns={[...columns(t)]}
        rows={ordersData || []}
        //
        filterModel={filterByShippingPoint()}
        // onFilterModelChange={handleFilterModelChange}
        //
        columnVisibilityModel={distrGridColumnVM}
        // onColumnVisibilityModelChange={setColumnVM}
        //
        defaultGroupingExpansionDepth={-1}
        slots={{
          noRowsOverlay: NoOrders, // no rows at all
        }}
        // rowHeight={52}
        // slots={GridToolbarSearch}
      />
    </div>
  );
};
