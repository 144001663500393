import { deleteCell, resetCell } from './styles';

export const getColumns = (
  openSN: (value: number) => void,
  openReset: (value: string) => void,
  t: (value: string) => string,
) => [
  {
    field: 'priority',
    headerName: '',
    width: 30,
    editable: true,
    align: 'center' as any,
    // renderCell: resetCell(openReset),
  },
  {
    field: 'reset',
    headerName: t('common.reset'),
    width: 70,
    renderCell: resetCell(openReset),
  },
  {
    field: 'serialNumber',
    headerName: t('settings.serialNumber'),
    width: 200,
  },
  {
    field: 'sku',
    headerName: t('settings.sku'),
    width: 100,
  },
  {
    field: 'customerName',
    headerName: t('common.companyName'),
    flex: 1,
  },
  {
    field: 'delete',
    headerName: t('buttons.delete'),
    width: 70,
    renderCell: deleteCell(openSN),
  },
];
