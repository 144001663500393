import { Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import styled from 'styled-components';
import { styled as styledDrawer } from '@mui/material/styles';
import { Text } from 'entities/Text';
import { styled as muiStyled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

// DESKTOP

export const MainContainer = styled.div`
  display: flex;
  margin-left: 65px;
  height: 100vh;
  width: calc(100vw - 65px);
`;

export const Drawer = styledDrawer(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    height: '100vh',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'absolute',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const openedMixin = (theme: Theme): CSSObject => ({
  width: 240,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'unset',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 65,
});

export const MobileDrawer = styledDrawer(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: '240px',
  flexShrink: 0,
  transform: 'translate(-240px)',
  height: '100vh',
  position: 'absolute',
  zIndex: 5,
  // boxSizing: 'border-box',
  // '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' },
  //
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMobileMixin(theme),
    '& .MuiDrawer-paper': openedMobileMixin(theme),
  }),
  ...(!open && {
    ...closedMobileMixin(theme),
    '& .MuiDrawer-paper': closedMobileMixin(theme),
  }),
}));

export const openedMobileMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: '240px',
  boxSizing: 'border-box',
  transform: 'translate(0)',
  overflow: 'unset',
});

export const closedMobileMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: '240px',
  boxSizing: 'border-box',
  transform: 'translate(-240px)',
  overflowX: 'hidden',
});

export const MenuIconContainer = styled.div`
  height: 64px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 65px 1fr;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);

  & p {
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    overflow: hidden;
  }
`;

export const MenuIcon = styled.div`
  height: 65px;
  width: 65px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & svg {
    width: 30px;
    height: auto;
  }
`;

export const DrawerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const FakeCloser = styled.div`
  position: absolute;
  height: 100vh;
  width: calc(100vw - 240px);
  background-color: transparent;
  left: 100%;
  top: 0;
`;

export const DrawerInnerContainer = styled.div`
  /*  */
`;

export const AppIconWrapper = styled.div`
  height: 65px;

  display: grid;
  grid-template-columns: 65px 1fr;

  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  text-decoration: none;
  transition: background 250ms linear;
  user-select: none;
`;

export const BoldP = styled(Text)`
  font-weight: 700;
`;

export const SLabel = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding-left: 5px;
`;

// MOBILE

export const Search = muiStyled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = muiStyled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = muiStyled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

// const drawerWidth = 240;
// export const MobileDrawer = muiStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: 'nowrap',
//     boxSizing: 'border-box',
//     ...(open && {
//       ...openedMixin(theme),
//       '& .MuiDrawer-paper': openedMixin(theme),
//     }),
//     ...(!open && {
//       ...closedMixin(theme),
//       '& .MuiDrawer-paper': closedMixin(theme),
//     }),
//   }),
// );
