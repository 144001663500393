import { sessionStorageHelper, SessionStoragePaths } from 'shared/helpers/sessionStorage';

export enum SW_MESSAGE_TYPE {
  skipWaitng = 'SKIP_WAITING',
  activated = 'ACTIVATED',
  logOut = 'LOG_OUT',
  logOutFromOtherTab = 'LOG_OUT_FROM_OTHER_TAB',
}

export const appVersionUpdate = () => {
  // Run app version update handler on page load
  appVersionHandler(
    sessionStorageHelper.get(SessionStoragePaths.appVersion || ''),
    process.env.REACT_APP_VERSION || '',
  );

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.onmessage = (event) => {
      // User confirmed app update or force update was executed. Run app version update handler and reload page.
      if (event.data && event.data.type === SW_MESSAGE_TYPE.activated) {
        appVersionHandler(process.env.REACT_APP_VERSION || '', event.data.version);
        window.location.reload();
      }
    };
  }
};

const appVersionHandler = (currentVersion: string, newVersion: string): void => {
  const [majorCurrent, minorCurrent] = currentVersion?.split?.('.') || [];
  const currentVersionNum: number = Number(`${majorCurrent}.${minorCurrent}`);
  const [majorNew, minorNew] = newVersion?.split?.('.');
  const newVersionNum: number = Number(`${majorNew}.${minorNew}`);
  if (!isNaN(newVersionNum) && !isNaN(currentVersionNum) && newVersionNum > currentVersionNum) {
    sessionStorage.removeItem('user_data');
  }
  sessionStorageHelper.set(SessionStoragePaths.appVersion, newVersion);
};
