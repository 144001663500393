import { useStore } from 'effector-react';
import { RenderIf } from 'shared/helpers/renderIf';
import { productionStore } from 'shared/store';
import { AvailableStatuses } from 'shared/store/productionListStore/types';
import { DoneProducts } from './allDone';
import { NoOrders } from './noOrders';
import { FilteredOut } from './FilteredOut';

export const NoRows = () => {
  const { infoStatus } = useStore(productionStore);

  return (
    <>
      <RenderIf condition={infoStatus === AvailableStatuses.isAllDone}>
        <DoneProducts />
      </RenderIf>
      <RenderIf condition={infoStatus === AvailableStatuses.noOrders}>
        <NoOrders />
      </RenderIf>
      <RenderIf condition={infoStatus === AvailableStatuses.default}>
        <FilteredOut />
      </RenderIf>
    </>
  );
};
