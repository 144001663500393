import { createEffect, createEvent } from 'effector';
import { notificationsStore } from '..';
import { gqlClient } from 'shared/gql/gqlClient';
import { getNotifications, markNotificationAsRead } from 'shared/gql/common.gql';
import { NotificationType } from './types';
import { NotificationMessage } from 'graphql/generatedModel';
import { updatePrinterStatus } from '../settingsStore';
import { newVersionIsReleased } from '../appStore';

export const loadNotifications = createEffect(async () => {
  const res = await gqlClient(getNotifications);
  return res.smartpicking.getNotifications;
});

export const readNotifications = createEffect(async (notificationIds: string[]) => {
  const success = await gqlClient(markNotificationAsRead, { notificationIds });
  console.log('success:', success);
  if (success) {
    return notificationIds;
  }
});

export const notificationReceived = createEvent<NotificationType>();

notificationsStore
  .on(loadNotifications.doneData, (state, notifications) => ({
    ...state,
    notifications,
  }))
  .on(readNotifications.doneData, (state, readNotifications) => ({
    ...state,
    notifications: state.notifications?.map((notification) => {
      if (readNotifications?.includes(notification?.notificationId)) {
        return { ...notification, isRead: true };
      } else {
        return notification;
      }
    }),
  }))
  .on(notificationReceived, (state, newNotification) => {
    if (
      newNotification.message === NotificationMessage.PRINTER_IS_OFFLINE ||
      newNotification.message === NotificationMessage.PRINTER_IS_ONLINE
    ) {
      updatePrinterStatus(
        Boolean(newNotification.message === NotificationMessage.PRINTER_IS_ONLINE),
      );
    }
    if (newNotification.message === NotificationMessage.NEW_VERSION_IS_DEPLOYED) {
      // TODO: mark notification as read at this point
      newVersionIsReleased();
    }
    const notificationsList = notificationsStore.getState().notifications;
    return {
      ...state,
      notifications: [...notificationsList, newNotification],
    };
  });
