import { ProductToUpdate } from 'shared/types';

export type StartModalStoreType = {
  fixedNumber?: number;
  calcNumber: number;
  amountType: 'fixed' | 'calculate';
  productsToUpdateDistributed?: ProductToUpdate[];
};

export const initStartModalStore = {
  fixedNumber: undefined,
  calcNumber: 0,
  amountType: 'fixed' as 'fixed',
};
