import { AvailableStatus, UpdateProductMutationVariables } from 'graphql/generatedModel';

export const getOrdersToEditForProdList = (
  productsData: any,
  // TODO: products must no longer be an array
  products: UpdateProductMutationVariables[],
) => {
  const orderMap1 = new Map();
  const productsDataPrepared = productsData[products?.[0]?.date]?.productsList
    ?.map((curr: any) => {
      curr?.productionListOrders?.forEach((pLO: any) => {
        const orderId = pLO?.orderId;
        const productStatus = pLO?.productStatus;

        const ispLOInProducts = products?.some((pr) => {
          const referenceFields = pr.fields as [];
          return referenceFields?.some((field: any) => field?.orderId === pLO?.orderId);
        });

        if (!orderMap1.has(orderId)) {
          orderMap1.set(orderId, productStatus === AvailableStatus.DONE && ispLOInProducts);
        } else {
          orderMap1.set(
            orderId,
            orderMap1.get(orderId) && productStatus === AvailableStatus.DONE && ispLOInProducts,
          );
        }
      });
      return curr.productionListOrders;
    })
    .flat(Infinity);

  const filteredOrders1: any[] = [];
  for (const item of productsDataPrepared) {
    if (orderMap1.get(item.orderId)) {
      filteredOrders1.push(item);
      orderMap1.delete(item.orderId); // Remove processed order to prevent duplicates
    }
  }

  return products
    ?.map((prod) => {
      const res: any[] = [];
      const fields = prod.fields as [];
      fields?.forEach((f: any) => {
        if (filteredOrders1?.some((fO) => fO.orderId === f.orderId)) {
          res.push({
            orderId: f.orderId,
            positionParts: [
              {
                linkArticle: `${prod?.productId}`,
                description: `${prod?.productName}`,
                newQuantity: f.distributed,
                partNum: f.partNum,
              },
            ],
          });
        }
      });
      return res;
    })
    .flat(Infinity);
};
