import { FC, useState } from 'react';
import { useStore } from 'effector-react';
import { Button } from 'entities/Button';
import { DATE_FORMAT } from 'shared/globalConsts';
import { ConfirmationModal } from 'entities/ConfirmationModal';
import SkipIcon from '@mui/icons-material/ProductionQuantityLimits';
import { AvailableStatus, PageNamesEnum } from 'graphql/generatedModel';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';
import { appStore, globalStore, productionStore, updateOrders } from 'shared/store';
import { TextField } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getLayout } from 'shared/helpers/getLayout';

export const SkipButton: FC<ISkipButton> = ({ closeMotherModal, ordersToDisplay }) => {
  // Hooks
  const t = useTranslations();
  const [progress, startClosing] = useCloseModalWithDelay(closeMotherModal, 4000);

  // Store
  const { shippingPoint: shippingPointName } = useStore(appStore);
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const { currentOpenProduct } = useStore(productionStore);

  // State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState('');

  // Functions
  const handleSkip = async () => {
    startClosing();
    await updateOrders({
      date,
      productId: Number(currentOpenProduct?.articleId),
      productName: `${currentOpenProduct?.description}`,
      // TODO: 99 remove pageName when BE is ready
      pageName: PageNamesEnum.PRODUCTION,
      shippingName: shippingPointName || '',
      fields: ordersToDisplay?.map((order: any) => {
        return {
          orderId: Number(order?.orderId),
          partNum: currentOpenProduct?.deliveryCycleNum,
          productStatus: AvailableStatus.DONE,
          distributed: 0,
          toDistribute: 0,
          note: reason,
        };
      }),
    });
  };

  return (
    <>
      <Button
        // variant="outlined"
        variant={getLayout() === 'DESKTOP' ? 'outlined' : 'text'}
        color="secondary"
        handleClick={() => {
          setIsModalOpen(true);
        }}
        height="56px"
        width="56px"
      >
        <SkipIcon />
      </Button>
      <ConfirmationModal
        text={t('common.skipProductReasonQuestion')}
        progress={progress}
        isOpen={isModalOpen}
        onConfirm={handleSkip}
        onClose={() => setIsModalOpen(false)}
        isDisabled={Boolean(!reason?.length)}
        noText={t('buttons.cancel')}
        yesText={t('buttons.skip')}
      >
        <TextField
          fullWidth
          value={reason}
          placeholder={t('common.reason')}
          onChange={(e: any) => setReason(e.target.value)}
        />
      </ConfirmationModal>
    </>
  );
};

interface ISkipButton {
  closeMotherModal: () => void;
  ordersToDisplay: any[];
}
