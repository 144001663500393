import { FC, useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { useTranslations } from 'shared/hooks/useTranslations';
import { RenderIf } from 'shared/helpers/renderIf';
import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { ButtonsContainer, ReplaceModalContainer, ReplaceModalContainerInner } from './styles';
import { useStore } from 'effector-react';
import { globalStore } from 'shared/store';
import { loadAllProducts } from 'shared/store/globalStore';
import { replaceProduct } from 'shared/api';
import { productionStore } from 'shared/store';
import { getProductionList } from 'shared/store/productionListStore';
import { Text } from 'entities/Text';

export const ReplaceModalComponent: FC<IReplaceModal> = ({
  startClosing,
  instantClose,
  onClose,
}) => {
  const t = useTranslations();

  const [selectedProduct, setSelectedProduct] = useState('');

  const { date } = useStore(globalStore);

  const { currentOpenProduct: product } = useStore(productionStore) as any;
  const { allProductsList: availableProducts } = useStore(globalStore);
  const isLoading = Boolean(!useStore(globalStore).isProductsListLoad);

  useEffect(() => {
    loadAllProducts();
  }, []);

  const handleProductSelection = (_event: any, newValue: any) => {
    setSelectedProduct(newValue?.id);
  };

  const handleSave = async () => {
    startClosing();

    const success = await replaceProduct({
      orders: product?.productionListOrders?.map((order: any) => ({
        orderId: order?.orderId,
        positionParts: [
          {
            linkArticle: product?.articleId,
            description: product?.description,
            linkReplacementArticle: `${selectedProduct}`,
          },
        ],
      })),
    });

    if (success && date) {
      await getProductionList({ date, forceLoad: true });
      instantClose();
    }
  };

  return (
    <ReplaceModalContainer>
      <ReplaceModalContainerInner>
        <Text>{t('common.existing')}</Text>
        <TextField
          margin="normal"
          id="product"
          label={t('common.productName')}
          name="productName"
          value={product?.description}
          sx={{ margin: 0 }}
        />
      </ReplaceModalContainerInner>
      <ReplaceModalContainerInner>
        <Text>{t('common.new')}</Text>
        <RenderIf condition={!isLoading}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={availableProducts || []}
            sx={{ width: '100%' }}
            onChange={handleProductSelection}
            getOptionLabel={(option: any) => option.description || ''}
            renderInput={(params) => <TextField {...params} label={t('common.productName')} />}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {option?.description}
              </li>
            )}
            componentsProps={{
              popper: {
                placement: 'top',
              },
            }}
          />
        </RenderIf>
        <RenderIf condition={isLoading}>
          <Button loading variant="outlined" />
        </RenderIf>
      </ReplaceModalContainerInner>

      <ButtonsContainer>
        <Button variant="outlined" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={!selectedProduct}>
          {t('buttons.save')}
        </Button>
      </ButtonsContainer>
    </ReplaceModalContainer>
  );
};

interface IReplaceModal {
  startClosing: () => void;
  instantClose: () => void;
  onClose: () => void;
}

export const ReplaceModal = withModalHOC(ReplaceModalComponent);
