import { handleLocalStorage } from './handleLocalStorage';

export const filterByShippingPoint = () => {
  const shippingPointName = handleLocalStorage('shippingPointName');

  return {
    items: [
      ...(shippingPointName
        ? [
            {
              id: 1,
              field: 'shippingPointName',
              value: shippingPointName,
              operator: 'contains',
            },
          ]
        : []),
    ],
  };
};
