import { FC, useState } from 'react';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { RenderIf } from 'shared/helpers/renderIf';
import { ConfirmationModal } from 'entities/ConfirmationModal';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';
import { useStore } from 'effector-react';
import { settingsStore } from 'shared/store';
import { Button } from 'entities/Button';
import { defaultPrint } from 'shared/helpers/printFunction/sunmiPrint';
import { getLayout } from 'shared/helpers/getLayout';

export const PrintButton: FC<IPrintButton> = ({ dataToPrint }) => {
  //
  // Hooks
  const t = useTranslations();
  const [progress, startClosing] = useCloseModalWithDelay(() => setIsPrintModalOpen(false), 3500);

  // State
  const { isPrinterDataInit, printerData } = useStore(settingsStore);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  // Functions
  const startPrintingProduct = () => {
    const { isLocalConnection, printerId } = printerData;
    startClosing();
    if (!isLocalConnection) {
      defaultPrint({ printerId, printData: dataToPrint });
    }
  };

  return (
    <>
      <RenderIf
        condition={isPrinterDataInit && Boolean(dataToPrint) && Boolean(printerData.isOnline)}
      >
        <Button
          color="warning"
          variant={getLayout() === 'DESKTOP' ? 'outlined' : 'text'}
          height="56px"
          width="56px"
          handleClick={() => {
            setIsPrintModalOpen(true);
          }}
        >
          <LocalPrintshopOutlinedIcon />
        </Button>
      </RenderIf>

      <ConfirmationModal
        isOpen={isPrintModalOpen}
        progress={progress}
        headerTitle={t('production.print')}
        text={t('production.printQuestion')}
        onClose={() => setIsPrintModalOpen(false)}
        onConfirm={startPrintingProduct}
      />
    </>
  );
};

interface IPrintButton {
  dataToPrint: any;
}
