import Input from '@mui/material/TextField';
import { useStore } from 'effector-react';
import { globalStore } from 'shared/store';
import { changeSearchText } from 'shared/store/globalStore';

export const SearchBar = () => {
  const { searchText } = useStore(globalStore);

  return (
    <Input
      value={searchText}
      variant="outlined"
      // TODO: translation
      label="Search"
      onChange={(e: any) => changeSearchText(e.currentTarget.value)}
    />
  );
};
