import { DistributedComponent } from 'entities/DistributedComponent';
import { getStatusColor } from 'shared/helpers/getStatusColor';
import { getStatusText } from 'shared/helpers/getStatusText';
import { RenderIf } from 'shared/helpers/renderIf';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Box } from '@mui/material';
import { ProductImage } from 'widgets/ProductionListGrid/consts';
import { getLayout } from 'shared/helpers/getLayout';

// TODO: 99 refactor all columns across the app

// TODO: 99 check possibility to move StatusComponent to entities
export const StatusComponent = (el: any) => {
  const productStatus = el?.row?.productStatus || 'To do';
  const t = useTranslations();
  return <p style={{ color: getStatusColor(productStatus) }}>{getStatusText(t, productStatus)}</p>;
};

// TODO: 99 check possibility to move distributedCell to entities
export const distributedCell = (products: any[]) => (el: any) => {
  const getBgColor = () =>
    products.some((p) => p.id === `${el.row.customerNum}_${el.row.deliveryNoteNum}`)
      ? '#ffc7c3'
      : '#ffffaab2';
  return (
    <>
      <RenderIf condition={el.isEditable && el.value}>
        <DistributedComponent bgColor={getBgColor()}>{el.value}</DistributedComponent>
      </RenderIf>
      <RenderIf condition={!el.isEditable || !el.value}>{el.value || ''}</RenderIf>
    </>
  );
};

export const columns = (t: (v: string) => string, products: any[]) => [
  ...(getLayout() === 'DESKTOP'
    ? [
        {
          field: 'imageUrl',
          headerName: t('common.picture'),
          width: 72,
          renderCell: (params: any) => {
            if (params.value) {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <ProductImage src={params.value} alt={t('common.picture')} />
                </Box>
              );
            } else {
              return '';
            }
          },
        },
      ]
    : [
        {
          field: 'productStatus',
          headerName: t('common.productStatus'),
          width: 150,
          renderCell: StatusComponent,
        },
      ]),
  {
    field: 'articleNum',
    headerName: t('common.productNumber'),
    width: 130,
  },
  {
    field: 'description',
    headerName: t('common.productName'),
    minWidth: 220,
    flex: 1,
  },
  {
    field: 'sumAmount',
    headerName: t('common.ordered'),
    width: 100,
  },
  {
    field: 'distributed',
    headerName: t('common.distributed'),
    width: 150,
    editable: true,
    type: 'number',
    renderCell: distributedCell(products),
  },
  {
    field: 'difference',
    headerName: t('common.difference'),
    width: 150,
    valueGetter: (params: any) => {
      if (params.row.distributed) {
        return Number(params.row.distributed || 0) - Number(params.row.sumAmount || 0);
      }
    },
  },
  ...(getLayout() === 'DESKTOP'
    ? [
        {
          field: 'productStatus',
          headerName: t('common.productStatus'),
          width: 150,
          renderCell: StatusComponent,
        },
      ]
    : []),
  {
    field: 'shippingPointName',
    headerName: t('common.shippingPoint'),
    width: 200,
  },
];

export const orderColumnVM = {
  articleId: false,
  deliveryNoteNum: false,
  shippingPointName: false,
};
