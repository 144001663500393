import { FC, useState } from 'react';
import { Text } from 'entities/Text';
import LogOutModal from 'widgets/LogOutModal';
import { SettingsModal } from 'widgets/SettingsModal';
import { MenuIcon, IconContainer, DrawerInnerContainer } from './styled';
import { useTranslations } from 'shared/hooks/useTranslations';

import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

export const SidebarHandlers: FC<SidebarHandlersProps> = ({ themeColor }) => {
  const t = useTranslations();

  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingModalOpen] = useState(false);

  return (
    <>
      <DrawerInnerContainer>
        <IconContainer onClick={() => setIsSettingModalOpen(true)}>
          <MenuIcon>
            <SettingsIcon style={{ color: themeColor === 'dark' ? '#fff' : 'unset' }} />
          </MenuIcon>
          <Text>{t('settings.settings')}</Text>
        </IconContainer>
        <IconContainer onClick={() => setIsLogOutModalOpen(true)}>
          <MenuIcon>
            <LogoutIcon style={{ color: themeColor === 'dark' ? '#fff' : 'unset' }} />
          </MenuIcon>
          <Text>{t('login.logOut')}</Text>
        </IconContainer>
      </DrawerInnerContainer>
      <SettingsModal
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingModalOpen(false)}
        headerTitle={t('settings.settings')}
      />
      <LogOutModal
        isOpen={isLogOutModalOpen}
        headerTitle={t('login.logOut')}
        onClose={() => setIsLogOutModalOpen(false)}
      />
    </>
  );
};

type SidebarHandlersProps = {
  themeColor: 'light' | 'dark';
};
