import { Moment } from 'moment';
import { ProductType, ProductionList } from 'shared/types';

export enum AvailableStatuses {
  isAllDone = 'IS_ALL_DONE',
  noOrders = 'NO_ORDERS',
  default = 'DEFAULT',
}
export type HandleInfoProps = {
  list: ProductionList;
};

export type ProductsListsType = {
  [key: string]: {
    lastUpdated: number;
    productsList: ProductionList;
  };
};

export type ProductionListStoreType = {
  isProdListLoading: boolean;
  isDoneShown: boolean;
  productsData: ProductsListsType;
  currentOpenProduct: ProductType | null;
  infoStatus: AvailableStatuses;
};

export const initProductionListStore = {
  isProdListLoading: false,
  isDoneShown: false,
  productsData: {},
  currentOpenProduct: null,
  infoStatus: AvailableStatuses.default,
};

export type getProdListProps = {
  date: Moment;
  forceLoad?: boolean;
};
