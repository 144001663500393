import styled from 'styled-components';
import DeletIcon from '@mui/icons-material/DeleteOutline';

export const DeleteCellComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const deleteCell = (onDelete: (productId: string) => void) => (el: any) => {
  const onDeleteClick = () => {
    onDelete(el.row.id);
  };
  return (
    <DeleteCellComponent onClick={onDeleteClick}>
      <DeletIcon />
    </DeleteCellComponent>
  );
};

export const columns = (t: (value: string) => string, onDelete?: (productId: string) => void) => {
  return [
    {
      field: 'articleNo',
      headerName: t('common.productNumber'),
      width: 140,
    },
    {
      field: 'description',
      headerName: t('common.productName'),
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: t('production.amount'),
      width: 80,
    },
    ...(onDelete
      ? [
          {
            field: 'delete',
            headerName: t('buttons.delete'),
            width: 70,
            renderCell: deleteCell(onDelete),
          },
        ]
      : []),
  ];
};
