import { FC, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import {
  DateTime,
  Indicator,
  IsNotReadIcon,
  Message,
  MessagesContainer,
  NoNotificationsText,
  Notification,
  NotificationsContainer,
} from './styles';
import { RenderIf } from 'shared/helpers/renderIf';
import moment from 'moment';
import { useStore } from 'effector-react';
import { notificationsStore } from 'shared/store';
import { useTranslations } from 'shared/hooks/useTranslations';
import BellIcon from '@mui/icons-material/NotificationsNone';
import { Button } from 'entities/Button';
import { Text } from 'entities/Text';
import { readNotifications } from 'shared/store/notificationsStore';

export const Notifications: FC = () => {
  const t = useTranslations();
  const { notifications } = useStore(notificationsStore);
  const numberToShow = notifications?.reduce((acc, curr) => {
    if (!curr?.isRead) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);

  const [isOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleReadNotification = (notificationId: string) => {
    readNotifications([notificationId]);
  };

  return (
    <>
      <Button
        handleClick={handleOpenDrawer}
        width="unset"
        variant="outlined"
        sx={{ position: 'relative' }}
      >
        <BellIcon />
        <RenderIf condition={Boolean(numberToShow)}>
          <Indicator>{numberToShow}</Indicator>
        </RenderIf>
      </Button>
      <Drawer anchor="right" open={isOpen} onClose={() => setIsDrawerOpen(false)}>
        <NotificationsContainer>
          <RenderIf condition={Boolean(notifications?.length)}>
            <Text variant="h4" sx={{ margin: '30px 0' }}>
              {t('common.notifications')}
            </Text>
            <MessagesContainer>
              {notifications
                ?.sort((a, b) => b.timestamp - a.timestamp)
                // ?.sort((a, b) => Number(a.isRead) - Number(b.isRead))
                ?.slice(0, 10)
                ?.map((notification) => (
                  <Notification
                    key={notification?.notificationId}
                    onClick={() => handleReadNotification(notification?.notificationId)}
                  >
                    <IsNotReadIcon isRead={Boolean(notification.isRead)} />
                    <Message>
                      {t(`notifications.${notification.message}`) || notification.message || ''}
                    </Message>
                    <DateTime>
                      <Text>{moment(notification.timestamp).format('MM.DD')}</Text>
                      <Text>{moment(notification.timestamp).format('HH:mm')}</Text>
                    </DateTime>
                  </Notification>
                ))}
            </MessagesContainer>
          </RenderIf>
          <RenderIf condition={Boolean(!notifications?.length)}>
            <NoNotificationsText>
              <Text>{t('common.notificationsAreClear')}</Text>
            </NoNotificationsText>
          </RenderIf>
        </NotificationsContainer>
      </Drawer>
    </>
  );
};
