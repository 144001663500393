import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { DATE_FORMAT } from 'shared/globalConsts';
import { settingDeviceStatus } from 'shared/gql/common.gql';
import { gqlClient } from 'shared/gql/gqlClient';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import { distributionStore, globalStore, productionStore, settingsStore } from 'shared/store';

export const useUpdateDeviceStatus = (page: 'production' | 'distribution') => {
  const { displaysData, isDisplaysLoading } = useStore(settingsStore);
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const { productsData } = useStore(productionStore);
  const { lists } = useStore(distributionStore);

  const shippingName = handleLocalStorage('shippingPointName');

  const uniqueCustomersNum = useMemo(() => {
    if (page === 'production') {
      return [
        ...new Set(
          productsData?.[date]?.productsList
            ?.flatMap((product) => product?.productionListOrders ?? [])
            ?.map((customer) => customer?.customerNum as string)
            ?.filter(Boolean),
        ),
      ];
    } else {
      return [...new Set(lists[date]?.ordersList.map((order) => order?.customerNum))];
    }
  }, [page, date, productsData, lists]); //eslint-disable-line

  const hasNoOrder = useMemo(() => {
    return displaysData
      .filter((device) => device?.sku && !uniqueCustomersNum.includes(device.sku))
      .map((device) => device?.sku);
  }, [displaysData, uniqueCustomersNum]);

  useEffect(() => {
    if (!isDisplaysLoading) {
      gqlClient(settingDeviceStatus, {
        date,
        shippingName,
        customersSku: hasNoOrder,
      });
    }
  }, [date, isDisplaysLoading, hasNoOrder?.length]); //eslint-disable-line
};
