import { createEffect, createEvent } from 'effector';
import {
  bindDisplays,
  getAllCustomers,
  getDeviceList,
  getDevicesInfo,
  getPrinterSettings,
  getShippingPointsList,
  getTranslations,
  savePrinterSettings,
  unbindDisplays,
  updateCustomersPriorities,
} from 'shared/gql/common.gql';
import { gqlClient } from 'shared/gql/gqlClient';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import { updateShippingPoint } from '../appStore';
import { LangType, initPrinterData } from './types';
import { getPreparedTranslations } from 'shared/helpers/getPreparedTranslations';
import { detectDefaultLanguage } from 'shared/hooks/initApp/functions/detectDefaultLanguage';
import { errorHandler } from 'shared/helpers/errorHandler';
import {
  BindDeviceMutationVariables,
  SavePrinterSettingsMutationVariables,
  UpdateCustomersPrioritiesMutationVariables,
} from 'graphql/generatedModel';
import { settingsStore } from '..';
import { getGuid } from 'shared/helpers/getGUID';
import { notificationReceived } from '../notificationsStore';

export const initTranslations = createEffect(async () => {
  const data = await gqlClient(getTranslations);
  const translations = getPreparedTranslations(data.smartpicking?.getTranslations || '{}');
  const defaultLanguage = detectDefaultLanguage();
  return { translations, defaultLanguage };
});

export const getShippingPoints = createEffect(async () => {
  const shippingPoints = settingsStore.getState().shippingPoints;
  if (shippingPoints?.length) {
    if (shippingPoints?.length === 1) {
      updateShippingPoint(shippingPoints[0]?.description || null);
    }
    return shippingPoints;
  } else {
    try {
      const res = await gqlClient(getShippingPointsList);
      const shippingPointsList = res.smartpicking?.shippingPointsList;

      if (shippingPointsList?.length === 1) {
        updateShippingPoint(shippingPointsList[0]?.description || null);
      }
      return shippingPointsList;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const updatePriorities = createEffect(
  async (params: UpdateCustomersPrioritiesMutationVariables) => {
    await gqlClient(updateCustomersPriorities, params);
    return params.params;
  },
);

export const getDisplaysData = createEffect(async () => {
  const { displaysData } = settingsStore.getState();
  if (!displaysData?.length) {
    try {
      startDisplaysLoading();
      const allPromise = Promise.all([gqlClient(getDeviceList), gqlClient(getDevicesInfo)]).then(
        ([hanshowData, displaysData]) => [
          hanshowData.smartpicking.getDeviceList,
          displaysData.smartpicking.getDevicesInfo,
        ],
      );
      const [hanshowData, displaysData] = await allPromise;
      const res = hanshowData?.map((el: any) => {
        const dispItem = displaysData.find((d: any) => d?.serialNumber === el?.physicalId);
        if (dispItem) {
          return dispItem;
        } else {
          return { serialNumber: el?.physicalId, sku: null };
        }
      });
      return res;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const getClisentsList = createEffect(async () => {
  const { customersList } = settingsStore.getState();
  if (!customersList?.length) {
    try {
      startCustomersLoading();
      const res = await gqlClient(getAllCustomers);
      const customersList = res.smartpicking?.allCustomers;
      return customersList;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const updatePrinterSettings = createEffect(
  async (printerData: SavePrinterSettingsMutationVariables) => {
    startPrinterLoading();
    await gqlClient(savePrinterSettings, printerData);
    return printerData;
  },
);

export const getPrinterData = createEffect(async () => {
  const { isPrinterDataInit } = settingsStore.getState();
  if (!isPrinterDataInit) {
    try {
      const shippingPoint = handleLocalStorage('shippingPointName');
      const res = await gqlClient(getPrinterSettings, { shippingPoint });
      const printerData = res.smartpicking?.getPrinterByGuid;
      const guid = await getGuid();
      if (guid) {
        const socket = new WebSocket(
          `wss://j4goni1lh1.execute-api.eu-central-1.amazonaws.com/production/?guid=${guid}&sn=${
            printerData?.printerId || ''
          }`,
        );
        socket.addEventListener('message', (e) => notificationReceived(JSON.parse(e.data)));
      }
      return printerData;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const bindDisplay = createEffect(async (params: BindDeviceMutationVariables) => {
  startDisplaysLoading();
  await gqlClient(bindDisplays, params);
  return params.params as any[];
});

export const unbindDisplay = createEffect(async (removedSN: string) => {
  startDisplaysLoading();
  await gqlClient(unbindDisplays, {
    params: [
      {
        serialNumber: removedSN,
      },
    ],
  });
  return removedSN;
});

export const startPrinterLoading = createEvent();
export const startDisplaysLoading = createEvent();
export const startCustomersLoading = createEvent();
export const setLanguage = createEvent<LangType>();
export const updatePrinterStatus = createEvent<boolean>();

settingsStore
  .on(startPrinterLoading, (state) => {
    return {
      ...state,
      isPrinterDataInit: false,
    };
  })
  .on(updatePrinterStatus, (state, newStatus) => {
    return {
      ...state,
      printerData: {
        ...state.printerData,
        isOnline: newStatus,
      },
    };
  })
  .on(updatePriorities, (state, newPriorities) => {
    return {
      ...state,
      displaysData: state.displaysData?.map((el) => {
        const priorities = newPriorities?.params as any[];
        const updatedPriority = priorities?.find((item) => item.sku === el?.sku);
        if (updatedPriority) {
          return { ...el, priority: updatedPriority.priority };
        }
        return el;
      }),
    };
  })
  .on(startDisplaysLoading, (state) => {
    return {
      ...state,
      isDisplaysLoading: true,
    };
  })
  .on(setLanguage, (state, newLang) => {
    handleLocalStorage({ lang: newLang });
    return {
      ...state,
      lang: newLang,
    };
  })
  //
  .on(getShippingPoints.doneData, (state, newShippingPoints) => {
    return {
      ...state,
      shippingPoints: newShippingPoints,
    };
  })
  .on(getPrinterData.doneData, (state, newPrinterData) => {
    return {
      ...state,
      isPrinterDataInit: true,
      printerData: newPrinterData || initPrinterData,
    };
  })
  .on(getDisplaysData.doneData, (state, newDisplaysData: any) => {
    if (!newDisplaysData) return state;
    return {
      ...state,
      isDisplaysLoading: false,
      displaysData: newDisplaysData,
    };
  })
  .on(startCustomersLoading, (state) => {
    return {
      ...state,
      isCustomersLoading: true,
    };
  })
  .on(getClisentsList.doneData, (state, newCustomersList) => {
    if (!newCustomersList)
      return {
        ...state,
        isCustomersLoading: false,
      };
    return {
      ...state,
      isCustomersLoading: false,
      customersList: newCustomersList,
    };
  })

  //
  .on(updatePrinterSettings.doneData, (state, newPrinterData) => {
    return {
      ...state,
      isPrinterDataInit: true,
      printerData: { ...state.printerData, ...newPrinterData },
    };
  })
  .on(bindDisplay.doneData, (state, bindDisplays) => {
    return {
      ...state,
      isDisplaysLoading: false,
      displaysData: state?.displaysData?.map((display) => {
        const modifiedItem = bindDisplays?.find((d1) => d1.serialNumber === display?.serialNumber);
        if (modifiedItem) {
          return {
            ...display,
            ...modifiedItem,
          };
        } else {
          return display;
        }
      }),
    };
  })
  .on(unbindDisplay.doneData, (state, removedSN) => {
    return {
      ...state,
      isDisplaysLoading: false,
      displaysData: state?.displaysData?.map((display) => {
        if (display?.serialNumber === removedSN) {
          return {
            ...display,
            sku: null,
          };
        } else {
          return display;
        }
      }),
    };
  })

  .on(initTranslations.doneData, (state, { translations: translationsToSet, defaultLanguage }) => {
    return {
      ...state,
      translations: translationsToSet,
      lang: defaultLanguage,
      isTranslationsInit: true,
    };
  });
