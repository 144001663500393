import { FC } from 'react';
import { DesktopHeader } from './DesktopHeader';
import { RenderIf } from 'shared/helpers/renderIf';
import { getLayout } from 'shared/helpers/getLayout';
import { MobileHeader } from './MobileHeader';

export const AppHeader: FC<IAppHeader> = ({ pageName, children }) => {
  return (
    <>
      <RenderIf condition={getLayout() === 'DESKTOP'}>
        <DesktopHeader pageName={pageName}>{children}</DesktopHeader>
      </RenderIf>
      <RenderIf condition={getLayout() === 'MOBILE'}>
        <MobileHeader pageName={pageName}>{children}</MobileHeader>
      </RenderIf>
    </>
  );
};

interface IAppHeader {
  pageName?: string;
}
