import { useStore } from 'effector-react';
import { Button } from 'entities/Button';
import { Modal } from 'entities/Modal';
import { Text } from 'entities/Text';
import { appStore } from 'shared/store';
import { DisclaimerCntainer } from './styles';
import { useTranslations } from 'shared/hooks/useTranslations';

export const NewVersionDisclaimer = () => {
  const { isNewVersionReleased } = useStore(appStore);
  const t = useTranslations();

  return (
    <Modal isOpen={isNewVersionReleased} onClose={() => console.log('unknown action')}>
      <DisclaimerCntainer>
        {/* TODO: translations */}
        {/* TODO: styles */}
        <Text>{t('notifications.NEW_VERSION_IS_DEPLOYED')}</Text>
        <Button handleClick={() => window?.location?.reload()}>{t('buttons.restart')}</Button>
      </DisclaimerCntainer>
    </Modal>
  );
};
