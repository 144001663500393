import { Text } from 'entities/Text';
import styled from 'styled-components';

interface LinkLabelProps {
  color: string;
}

export const LinkIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 65px;
  min-width: 65px;
  & svg {
    width: 30px;
    height: auto;
  }
`;

export const LinkLabel = styled('div')<LinkLabelProps>`
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding-left: 5px;
  color: ${({ color }) => color ?? 'unset'};
`;

export const LinkText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
