import { Moment } from 'moment';
import { createEffect, createEvent } from 'effector';
import { gqlClient } from 'shared/gql/gqlClient';
import { getAllProducts } from 'shared/gql/common.gql';
import { errorHandler } from 'shared/helpers/errorHandler';
import { globalStore } from '..';

export const loadAllProducts = createEffect(async () => {
  const { isProductsListLoad } = globalStore.getState();

  if (!isProductsListLoad) {
    try {
      const res = await gqlClient(getAllProducts);
      return res.smartpicking?.getArticles;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const setNewDate = createEvent<Moment>();
export const setNewPart = createEvent<number>();
export const changeSearchText = createEvent<string>();

globalStore
  .on(setNewDate, (state, newDate) => {
    if (state.date === newDate) return state;
    return {
      ...state,
      date: newDate,
    };
  })
  .on(changeSearchText, (state, newText) => {
    return {
      ...state,
      searchText: newText,
    };
  })
  .on(setNewPart, (state, newPart) => {
    if (state.selectedPart === newPart) return state;
    return {
      ...state,
      selectedPart: newPart,
    };
  })
  .on(loadAllProducts.doneData, (state, newList) => {
    if (!newList) return state;
    return {
      ...state,
      isProductsListLoad: true,
      allProductsList: newList,
    };
  });
