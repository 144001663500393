import styled from 'styled-components';

export const DisclaimerCntainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 20px;
  gap: 50px;
`;
