import { Text } from 'entities/Text';
import styled from 'styled-components';

export const MainContainer = styled.div`
  @media (max-width: 800px) {
    margin: 40px auto;
  }
`;

export const DistributingContainer = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 350px;

  margin: 25px;
  grid-row-gap: 0px;
  grid-column-gap: 25px;

  max-width: 100%;
`;

export const TableContainer = styled.div`
  grid-column: 2 / -1;
  @media (max-width: 800px) {
    grid-column: 1 / -1;
  }
`;

export const HeaderText = styled(Text)`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.99px;
  letter-spacing: 0.25px;
`;

export const SubheaderText = styled(HeaderText)`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin-bottom: 50px;
`;
