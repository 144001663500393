import { FC, useCallback, useEffect, useState } from 'react';
import { getColumns } from './consts';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { useTranslations } from 'shared/hooks/useTranslations';
import { ConfirmationModal } from 'entities/ConfirmationModal';
import { Table } from 'entities/Table';
import { DisplaysContainer } from './styles';
import { settingsStore } from 'shared/store';
import { unbindDisplay, updatePriorities } from 'shared/store/settingsStore';
import { useStore } from 'effector-react';
import { GetDevicesInfo } from 'shared/types';
import { clearDisplays } from 'shared/api';
import { ButtonsContainer } from 'entities/SettingsTemplate/styles';
import { Button } from 'entities/Button';
import { errorHandler } from 'shared/helpers/errorHandler';

export const Displays: FC = () => {
  //
  // Hooks
  const t = useTranslations();
  const apiRef = useGridApiRef();

  // Store
  const {
    displaysData: displaysDataToSet,
    customersList,
    isDisplaysLoading,
    isCustomersLoading,
  } = useStore(settingsStore);

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openSN, setOpenSN] = useState<number>(0);
  const [resettingSKU, setResettingSKU] = useState<string | null>(null);

  const [displaysData, setDisplaysData] = useState<GetDevicesInfo>([]);
  const [displaysDataPrioritiesUpd, setDisplaysDataPrioritiesUpd] = useState<GetDevicesInfo>([]);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (displaysDataToSet?.length) {
      setDisplaysData(
        displaysDataToSet
          ?.filter((display) => display?.sku?.length)
          ?.sort((a, b) => Number(a?.priority || Infinity) - Number(b?.priority || Infinity))
          ?.map((el) => ({
            ...el,
            customerName:
              customersList?.find((item) => item?.customerNo === el?.sku)?.internalOrFullName || '',
          })),
      );
    }
  }, [displaysDataToSet, customersList]);

  useEffect(() => {
    setIsLoading(isDisplaysLoading || isCustomersLoading);
  }, [isDisplaysLoading, isCustomersLoading]);

  //

  const unbind = async () => {
    await unbindDisplay(`${openSN}`);
    setOpenSN(0);
  };

  const resetDisplay = async () => {
    if (resettingSKU) {
      await clearDisplays([resettingSKU]);
      setResettingSKU(null);
    }
  };

  const resetAllDisplays = async () => {
    if (!success && displaysData?.length) {
      setIsLoading(true);
      await clearDisplays(displaysData?.map((el) => el?.sku || ''));
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(false);
      }, 1300);
    }
  };

  const updateRowPosition = async () => {
    try {
      setIsLoading(true);
      await updatePriorities({
        params: displaysDataPrioritiesUpd
          ?.sort((a, b) => Number(a?.priority || Infinity) - Number(b?.priority || Infinity))
          ?.map((el, i) => ({
            priority: `${i + 1}`,
            sku: el?.sku || '',
          })),
      });
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowEditCommit = useCallback(
    (newValue) => {
      const newArr = displaysDataPrioritiesUpd?.length ? displaysDataPrioritiesUpd : displaysData;
      setDisplaysDataPrioritiesUpd(
        newArr?.map((el) => {
          if (el?.serialNumber === newValue?.serialNumber) {
            return newValue;
          } else {
            return el;
          }
        }),
      );
      return { ...newValue, isNew: false };
    },
    [displaysData, displaysDataPrioritiesUpd], // eslint-disable-line
  );

  return (
    <DisplaysContainer>
      {/* RESET MODAL */}
      <div />
      <ConfirmationModal
        isOpen={Boolean(resettingSKU)}
        text={t('settings.resetDisplayQuestion')}
        onClose={() => setResettingSKU(null)}
        onConfirm={resetDisplay}
      />
      {/* DELETE MODAL */}
      <ConfirmationModal
        isOpen={Boolean(openSN)}
        text={t('settings.unbindQuestion')}
        onClose={() => setOpenSN(0)}
        onConfirm={unbind}
      />
      <Table
        apiRef={apiRef}
        loading={isLoading}
        getRowId={(item: any) => `${item?.customerName} x ${item?.serialNumber}`}
        columns={getColumns(setOpenSN, setResettingSKU, t)}
        // columns={[
        //   {
        //     field: 'priority',
        //     headerName: '',
        //     width: 30,
        //     editable: true,
        //     align: 'center',
        //     // renderCell: resetCell(openReset),
        //   },
        // ]}
        rows={displaysData}
        processRowUpdate={handleRowEditCommit}
        onProcessRowUpdateError={(error: any) => console.error(error?.message)}
      />
      <ButtonsContainer>
        <Button
          variant={success ? 'contained' : 'outlined'}
          color={success ? 'success' : 'error'}
          isLoading={isLoading}
          handleClick={resetAllDisplays}
          height="42px"
          width="240px"
        >
          {t('settings.resetAllDisplays')}
        </Button>
        <Button
          variant="contained"
          isLoading={isLoading}
          handleClick={updateRowPosition}
          isDisabled={Boolean(!displaysDataPrioritiesUpd?.length)}
          height="42px"
          width="240px"
        >
          {t('settings.updatePriorities')}
        </Button>
      </ButtonsContainer>
    </DisplaysContainer>
  );
};
