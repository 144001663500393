import { FC, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslations } from 'shared/hooks/useTranslations';
import { ConfirmationModal } from 'entities/ConfirmationModal';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';
import { useStore } from 'effector-react';
import { appStore, globalStore } from 'shared/store';
import { DATE_FORMAT } from 'shared/globalConsts';
import { productionStore } from 'shared/store';
import { updateOrders } from 'shared/store';
import { AvailableStatus, PageNamesEnum } from 'graphql/generatedModel';
import { Button } from 'entities/Button';
import { getLayout } from 'shared/helpers/getLayout';

export const MoveToDoneButton: FC<IMoveToDoneButton> = ({ closeMotherModal }) => {
  // Hooks
  const t = useTranslations();
  const [progress, startClosing] = useCloseModalWithDelay(closeMotherModal, 3500);

  // Store
  const { shippingPoint: shippingPointName } = useStore(appStore);
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const { currentOpenProduct: product } = useStore(productionStore);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const startResettingProduct = async () => {
    startClosing();
    ///
    await updateOrders({
      date,
      productId: Number(product?.articleId),
      productName: `${product?.description}`,
      pageName: PageNamesEnum.PRODUCTION,
      shippingName: shippingPointName || '',
      fields: product?.productionListOrders?.map((order: any) => ({
        orderId: Number(order?.orderId),
        productStatus: AvailableStatus.DONE,
      })) as [],
    });
    closeMotherModal();
  };

  return (
    <>
      <Button
        color="success"
        variant={getLayout() === 'DESKTOP' ? 'outlined' : 'text'}
        handleClick={() => {
          setIsModalOpen(true);
        }}
        height="56px"
        width="56px"
      >
        <CheckIcon />
      </Button>

      <ConfirmationModal
        isOpen={isModalOpen}
        progress={progress}
        headerTitle={t('common.areYouSure')}
        text={t('production.markAsDone')}
        onClose={() => setIsModalOpen(false)}
        onConfirm={startResettingProduct}
      />
    </>
  );
};

interface IMoveToDoneButton {
  closeMotherModal: () => void;
}
