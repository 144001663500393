import { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Autocomplete, TextField } from '@mui/material';
import { Button } from 'entities/Button';
import { RenderIf } from 'shared/helpers/renderIf';
import { useTranslations } from 'shared/hooks/useTranslations';

import { ProductsTable } from './productsTable';
import { globalStore } from 'shared/store';
import { loadAllProducts } from 'shared/store/globalStore';
import { AddProductSelectContanier, AddProductsContanier, StepName } from './styles';
import { Product } from './types';
import { Loader } from 'app/pageLoader/loaderComponent';

export const AddProducts: FC<AddProductsProps> = ({
  toNextStep,
  selectedProducts,
  addProduct,
  deleteProduct,
}) => {
  //
  // Hooks
  const t = useTranslations();
  const { allProductsList: availableProducts } = useStore(globalStore);

  // State
  const [selectedId, setSelectedId] = useState<number | null>();
  const [amount, setAmount] = useState<number | null>();

  // useEffect
  useEffect(() => {
    loadAllProducts();
  }, []);

  // Functions
  const handleProductSelection = (_event: any, newValue: any) => {
    setSelectedId(newValue.id);
  };

  const saveProduct = () => {
    if (selectedId && amount) {
      addProduct({ linkArticle: `${selectedId}`, quantity: +amount });
      setSelectedId(null);
      setAmount(null);
    }
  };

  return (
    <AddProductsContanier>
      <StepName>{t('common.addProducts')}</StepName>
      <Loader isLoading={Boolean(!availableProducts?.length)} />
      <Button
        variant="outlined"
        handleClick={saveProduct}
        isDisabled={Boolean(!selectedId || !amount)}
      >
        {t('common.addProductPlus')}
      </Button>
      <AddProductSelectContanier>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={availableProducts || []}
          sx={{ width: '100%' }}
          onChange={handleProductSelection}
          getOptionLabel={(option: any) => option.description || ''}
          renderInput={(params) => <TextField {...params} label={t('common.productName')} />}
          value={
            availableProducts?.find((el: any) => {
              return el?.id === selectedId;
            }) || null
          }
          renderOption={(props, option) => (
            <li {...props} key={option?.id}>
              {`${option?.articleNo} ${option?.description}`}
            </li>
          )}
        />
        <TextField
          key={selectedId}
          value={amount}
          onChange={(e: any) => setAmount(e.target.value)}
          type="number"
          placeholder={t('production.amount')}
          label={t('production.amount')}
          sx={{ input: { textAlign: 'center', width: '100px' } }}
        />
      </AddProductSelectContanier>
      <RenderIf condition={Boolean(selectedProducts.length)}>
        <StepName>{t('distribution.ordersList')}</StepName>
        <ProductsTable onDelete={deleteProduct} selectedProducts={selectedProducts} />
      </RenderIf>

      <Button
        variant="contained"
        width="100%"
        handleClick={toNextStep}
        isDisabled={Boolean(!selectedProducts.length)}
      >
        {t('buttons.confirm')}
      </Button>
    </AddProductsContanier>
  );
};

interface AddProductsProps {
  toNextStep: () => void;
  selectedProducts: Product[];
  addProduct: (newProduct: Product) => void;
  deleteProduct: (productId: string) => void;
}
