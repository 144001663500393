const consts = require('./consts');
const common = require('./common');
///////
export class SUNMIReport {
  #content = [];

  feedLines(lines) {
    if (lines > 0) {
      for (let i = 0; i < lines; i++) {
        this.#content.push(`0a`);
      }
    }
  }

  cutPaper() {
    this.feedLines(3);
    this.#content.push(`1d56${common.intToHexStr(1)}`);
  }

  setAlignment(alignment) {
    // eslint-disable-next-line default-case
    switch (alignment) {
      case consts.alignMap.LEFT: {
        this.#content.push(`1b61${common.intToHexStr(0)}`);
        break;
      }

      case consts.alignMap.CENTER: {
        this.#content.push(`1b61${common.intToHexStr(1)}`);
        break;
      }

      case consts.alignMap.RIGHT: {
        this.#content.push(`1b61${common.intToHexStr(2)}`);
        break;
      }
    }
  }

  setFont({ font, fontStyle = consts.fontStylesMap.DEFAULT }) {
    // eslint-disable-next-line default-case
    switch (font) {
      case consts.fontsMap.FONT_A: {
        this.#content.push(`1b4d${common.intToHexStr(0)}`);
        break;
      }

      case consts.fontsMap.FONT_B: {
        this.#content.push(`1b4d${common.intToHexStr(1)}`);
        break;
      }

      case consts.fontsMap.FONT_C: {
        this.#content.push(`1b4d${common.intToHexStr(2)}`);
        break;
      }
    }

    // eslint-disable-next-line default-case
    switch (fontStyle) {
      case consts.fontStylesMap.DEFAULT: {
        this.#content.push(`1d21${common.intToHexStr(0)}`);
        this.#content.push(`1b45${common.intToHexStr(0)}`);
        break;
      }

      case consts.fontStylesMap.DOUBLE_HEIGHT: {
        this.#content.push(`1b45${common.intToHexStr(0)}`);
        this.#content.push(`1d21${common.intToHexStr(1)}`);
        break;
      }

      case consts.fontStylesMap.DOUBLE_WIDTH: {
        this.#content.push(`1d2110`);
        this.#content.push(`1b45${common.intToHexStr(0)}`);
        break;
      }

      case consts.fontStylesMap.DOUBLE_HW: {
        this.#content.push(`1d2111`);
        this.#content.push(`1b45${common.intToHexStr(0)}`);
        break;
      }

      case consts.fontStylesMap.BOLD: {
        this.#content.push(`1d21${common.intToHexStr(0)}`);
        this.#content.push(`1b45${common.intToHexStr(1)}`);
        break;
      }

      case consts.fontStylesMap.BOLD_DOUBLE_HEIGHT: {
        this.#content.push(`1d21${common.intToHexStr(1)}`);
        this.#content.push(`1b45${common.intToHexStr(1)}`);
        break;
      }

      case consts.fontStylesMap.BOLD_DOUBLE_WIDTH: {
        this.#content.push(`1d2110`);
        this.#content.push(`1b45${common.intToHexStr(1)}`);
        break;
      }

      case consts.fontStylesMap.BOLD_DOUBLE_HW: {
        this.#content.push(`1d2111`);
        this.#content.push(`1b4501`);
        break;
      }
    }
  }

  addLine({ line, fLineEndChar = true }) {
    this.#content.push(
      common
        .toUTF8Array(line)
        .map(function (c) {
          return common.intToHexStr(c);
        })
        .join(''),
    );

    if (fLineEndChar) {
      this.feedLines(1);
    }
  }

  addSeparationLine() {
    this.setFont({ font: consts.fontsMap.FONT_A });
    this.setAlignment(consts.alignMap.LEFT);
    this.addLine({ line: '------------------------------------------------' });
  }

  twoColumnsLine({ font, leftFontStyle, leftStr, rightFontStyle, rightStr }) {
    let lineWidth = 0;
    let leftCharWidth = 0;
    let rightCharWidth = 0;
    let leftStrFixed = leftStr;

    this.setFont({ font: font, fontStyle: leftFontStyle });
    this.setAlignment(consts.alignMap.LEFT);
    this.addLine({ line: leftStr, fLineEndChar: false });

    // eslint-disable-next-line default-case
    switch (font) {
      case consts.fontsMap.FONT_A: {
        leftCharWidth = 12; // Character width from printer specs
        rightCharWidth = 12; // Character width from printer specs
        lineWidth = 576;

        if (
          leftFontStyle === consts.fontStylesMap.DOUBLE_WIDTH ||
          leftFontStyle === consts.fontStylesMap.DOUBLE_HW ||
          leftFontStyle === consts.fontStylesMap.BOLD_DOUBLE_WIDTH ||
          leftFontStyle === consts.fontStylesMap.BOLD_DOUBLE_HW
        ) {
          leftCharWidth = 24;
        }

        if (
          rightFontStyle === consts.fontStylesMap.DOUBLE_WIDTH ||
          rightFontStyle === consts.fontStylesMap.DOUBLE_HW ||
          rightFontStyle === consts.fontStylesMap.BOLD_DOUBLE_WIDTH ||
          rightFontStyle === consts.fontStylesMap.BOLD_DOUBLE_HW
        ) {
          rightCharWidth = 24;
        }

        break;
      }

      case consts.fontsMap.FONT_B: {
        leftCharWidth = 9;
        rightCharWidth = 9;
        lineWidth = 576;

        if (
          leftFontStyle === consts.fontStylesMap.DOUBLE_WIDTH ||
          leftFontStyle === consts.fontStylesMap.DOUBLE_HW ||
          leftFontStyle === consts.fontStylesMap.BOLD_DOUBLE_WIDTH ||
          leftFontStyle === consts.fontStylesMap.BOLD_DOUBLE_HW
        ) {
          leftCharWidth = 18;
        }

        if (
          rightFontStyle === consts.fontStylesMap.DOUBLE_WIDTH ||
          rightFontStyle === consts.fontStylesMap.DOUBLE_HW ||
          rightFontStyle === consts.fontStylesMap.BOLD_DOUBLE_WIDTH ||
          rightFontStyle === consts.fontStylesMap.BOLD_DOUBLE_HW
        ) {
          rightCharWidth = 18;
        }

        break;
      }

      case consts.fontsMap.FONT_C: {
        leftCharWidth = 9;
        rightCharWidth = 9;
        lineWidth = 576;

        if (
          leftFontStyle === consts.fontStylesMap.DOUBLE_WIDTH ||
          leftFontStyle === consts.fontStylesMap.DOUBLE_HW ||
          leftFontStyle === consts.fontStylesMap.BOLD_DOUBLE_WIDTH ||
          leftFontStyle === consts.fontStylesMap.BOLD_DOUBLE_HW
        ) {
          leftCharWidth = 18;
        }

        if (
          rightFontStyle === consts.fontStylesMap.DOUBLE_WIDTH ||
          rightFontStyle === consts.fontStylesMap.DOUBLE_HW ||
          rightFontStyle === consts.fontStylesMap.BOLD_DOUBLE_WIDTH ||
          rightFontStyle === consts.fontStylesMap.BOLD_DOUBLE_HW
        ) {
          rightCharWidth = 18;
        }

        break;
      }
    }

    const spaceLeft =
      lineWidth - leftStrFixed.length * leftCharWidth - rightStr.length * rightCharWidth;

    if (spaceLeft > 0) {
      this.setFont({ font: font, fontStyle: rightFontStyle });

      const whiteSpaceCount = Math.floor(spaceLeft / rightCharWidth);
      for (let i = 1; i <= whiteSpaceCount; i++) {
        this.addLine({ line: ' ', fLineEndChar: false });
      }
    }

    this.setFont({ font: font, fontStyle: rightFontStyle });
    this.addLine({ line: rightStr });
  }

  twoColumnsFontA({ leftFontStyle, leftStr, rightFontStyle, rightStr }) {
    this.twoColumnsLine({
      font: consts.fontsMap.FONT_A,
      leftFontStyle: leftFontStyle,
      leftStr: leftStr,
      rightFontStyle: rightFontStyle,
      rightStr: rightStr,
    });
  }

  twoColumnsFontB({ leftFontStyle, leftStr, rightFontStyle, rightStr }) {
    this.twoColumnsLine({
      font: consts.fontsMap.FONT_B,
      leftFontStyle: leftFontStyle,
      leftStr: leftStr,
      rightFontStyle: rightFontStyle,
      rightStr: rightStr,
    });
  }

  threeColumnsFontA({ leftStr, centerStr, rightStr }) {
    const charWidth = 12;
    const lineWidth = 576;

    this.setFont({ font: consts.fontsMap.FONT_A });
    this.setAlignment(consts.alignMap.LEFT);
    this.addLine({ line: leftStr, fLineEndChar: false });

    const textWidth = (leftStr.length + centerStr.length + rightStr.length) * charWidth;
    const spacesBetween = Math.floor((lineWidth - textWidth) / 2 / charWidth);

    if (spacesBetween > 0) {
      for (let i = 0; i < spacesBetween; i++) {
        this.addLine({ line: ' ', fLineEndChar: false });
      }
    }

    this.addLine({ line: centerStr, fLineEndChar: false });

    if (spacesBetween > 0) {
      for (let i = 0; i < spacesBetween; i++) {
        this.addLine({ line: ' ', fLineEndChar: false });
      }
    }
    this.addLine({ line: rightStr });
  }

  addQRCode(qrCode) {
    try {
      const content = common.toUTF8Array(qrCode);

      let text_length = content.length;
      let module_size = 5;
      let ec_level = 1;

      if (text_length === 0) return;

      if (text_length > 65535) text_length = 65535;
      if (module_size < 1) {
        module_size = 1;
      } else if (module_size > 16) {
        module_size = 16;
      }
      if (ec_level < 0) {
        ec_level = 0;
      } else if (ec_level > 3) {
        ec_level = 3;
      }

      this.#content.push(`1d286b040031410000`);
      this.#content.push(`1d286b03003143${common.intToHexStr(module_size)}`);
      this.#content.push(`1d286b03003145${common.intToHexStr(ec_level + 48)}`);
      this.#content.push(
        `1d286b${common.intToHexStr((text_length + 3) & 0xff)}${common.intToHexStr(
          ((text_length + 3) >> 8) & 0xff,
        )}315030`,
      );

      for (let i = 0; i < text_length; i++) {
        this.#content.push(common.intToHexStr(content[i]));
      }

      this.#content.push(`1d286b0300315130`);
    } catch (e) {
      console.error('SUNMIReport', `addQRCode error: ${e.message ? e.message : e.toString()}`);
    }
  }

  getReportBody() {
    return this.#content.join('');
  }
}
