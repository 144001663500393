/*
  Is used to work with deep nested sessionStorage values.
  'get' and 'set' methods works similarly as lodash methods
  and accepts 'path' argument in the same syntax ('obj.key[0].key').
  Important, 'set' accepts any values to set. Don't use JSON.stringify() for it value!
*/

class SessionStorageHelper {
  get(path: string): any | null | undefined {
    const keys = this.getKeys(path);
    const mainValue = sessionStorage.getItem(keys[0]);
    if (mainValue === null) {
      return mainValue; // null
    }
    let parsedValue: any;
    try {
      parsedValue = JSON.parse(mainValue);
    } catch {
      console.error(
        `Unable to get sessionStorage value by key '${path}'. sessionStorage value ${mainValue} isn't a valid json string.`,
      );
      return parsedValue; // undefined
    }
    if (keys.length === 1) {
      return parsedValue;
    }
    return keys.reduce((outValue, k, i) => {
      return outValue?.[k];
    }, parsedValue);
  }

  set(path: string, v: any): void {
    const keys = this.getKeys(path);
    if (keys.length === 1) {
      sessionStorage.setItem(keys[0], JSON.stringify(v));
      return;
    }
    const mainValue = sessionStorage.getItem(keys[0]);
    let parsedValue: any;
    try {
      parsedValue = mainValue ? JSON.parse(mainValue) : {};
    } catch {
      console.error(
        `Unable to set sessionStorage value by key '${path}'. Existing sessionStorage value ${mainValue} isn't a valid json string.`,
      );
      return;
    }
    if (typeof parsedValue !== 'object') parsedValue = {};
    keys.reduce((outV, key, i, keysArr) => {
      const isArrInd = !isNaN(Number(keysArr[i + 1]));
      const isLastKey = i === keysArr.length - 1;
      if (isLastKey) {
        outV[key] = v;
      } else {
        // Value by current key doesn't exist or not an object. Assign/reassign empty object or array.
        if (typeof outV[key] !== 'object' || outV?.[key] === null) {
          outV[key] = isArrInd ? [] : {};
        } else {
          // Reassign current value if it's not an Array and current key is Array index
          if (isArrInd && !Array.isArray(outV[key])) {
            outV[key] = [];
            // Reassign current value it it's an Array
          } else if (!isArrInd && Array.isArray(outV[key])) {
            outV[key] = {};
          }
        }
      }
      return outV[key];
    }, parsedValue);
    sessionStorage.setItem(keys[0], JSON.stringify(parsedValue));
  }

  private getKeys(path: string): string[] {
    return path?.split('.')?.reduce((outArr: string[], k: string) => {
      // Check for array indexes f.e. 'items[0]'
      const arrKeys = k.split('[');
      arrKeys.forEach((el) => {
        const keyToPush = el[el.length - 1] === ']' ? el.slice(0, el.length - 1) : el;
        outArr.push(keyToPush);
      });
      return outArr;
    }, []);
  }
}

export const sessionStorageHelper = new SessionStorageHelper();

export enum SessionStoragePaths {
  language = 'sys_data.language',
  appVersion = 'sys_data.appVersion',
}
