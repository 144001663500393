import { useStore } from 'effector-react';
import { Button } from 'entities/Button';
import { appStore, settingsStore } from 'shared/store';
import TerminalIcon from '@mui/icons-material/Terminal';
import { SPItem, SPItemsContainer } from './styles';
import { useState } from 'react';
import { RenderIf } from 'shared/helpers/renderIf';
import { updateShippingPoint } from 'shared/store/appStore';

export const ShippingPointSelector = () => {
  const { shippingPoint } = useStore(appStore);
  const { shippingPoints } = useStore(settingsStore);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleShippingPointClick = (spToSet: string) => {
    updateShippingPoint(spToSet);
    setIsMenuOpen(false);
  };

  const toggleMenu = (newValue: boolean) => {
    if (shippingPoints?.length > 1) {
      setIsMenuOpen(newValue);
    } else {
      setIsMenuOpen(false);
    }
  };

  return (
    <div>
      <Button
        handleClick={() => toggleMenu(!isMenuOpen)}
        width="unset"
        variant="outlined"
        sx={{
          position: 'relative',
          zIndex: '1000',
        }}
      >
        <TerminalIcon />
        <p style={{ marginLeft: '10px' }}>{shippingPoint}</p>
      </Button>
      <RenderIf condition={isMenuOpen && shippingPoints?.length > 1}>
        <SPItemsContainer>
          {shippingPoints?.map((sp, i) => (
            <SPItem
              key={i}
              onClick={() => handleShippingPointClick(sp?.description || '')}
              isSelected={sp?.description === shippingPoint}
            >
              {sp?.description}
            </SPItem>
          ))}
        </SPItemsContainer>
      </RenderIf>
    </div>
  );
};
