import { FC, useEffect, useState } from 'react';
import { Moment } from 'moment';

import 'moment/locale/de';
import 'moment/locale/en-gb';
import { deDE, enUS } from '@mui/x-date-pickers/locales';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { useStore } from 'effector-react';
import { settingsStore } from 'shared/store';
import { useTranslations } from 'shared/hooks/useTranslations';

export const MuiCalendar: FC<ICalendar> = ({ date, onChangeDate }) => {
  const t = useTranslations();

  const { lang } = useStore(settingsStore);
  const [localeText, setLocaleText] = useState(
    deDE.components.MuiLocalizationProvider.defaultProps.localeText,
  );

  useEffect(() => {
    switch (lang) {
      case 'de':
        setLocaleText(deDE.components.MuiLocalizationProvider.defaultProps.localeText);
        break;
      case 'en':
        setLocaleText(enUS.components.MuiLocalizationProvider.defaultProps.localeText);
        break;
      default:
        setLocaleText(deDE.components.MuiLocalizationProvider.defaultProps.localeText);
    }
  }, [lang]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de" localeText={localeText}>
      <MobileDatePicker label={t('common.forDate')} onChange={onChangeDate} value={date} />
    </LocalizationProvider>
  );
};

interface ICalendar {
  date: Moment | undefined;
  onChangeDate: (e: Moment | null) => void;
}
