import { Auth as aws } from 'aws-amplify';
import { createEffect, createEvent } from 'effector';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import { appStore } from '..';
import { checkIsLoggedIn } from 'shared/hooks/initApp/functions/checkIsLoggedIn';
import { AuthErrors, ConfirmMFAOptions, SignInOptions, ThemeColor, initAppStore } from './types';
import { errorHandler } from 'shared/helpers/errorHandler';

export const signIn = createEffect(async (params: SignInOptions) => {
  try {
    const { user: username, password } = params;
    const user = await aws.signIn({ username, password });
    if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      return [AuthErrors.MFA_REQUIRED, user];
    } else {
      checkIsLoggedIn();
    }
  } catch (e) {
    errorHandler(e);
  }
});

export const confirmMFA = createEffect(async (params: ConfirmMFAOptions) => {
  try {
    const { mfaCode, cognitoUser } = params!;
    const mfaType =
      cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : null;

    await aws.confirmSignIn(cognitoUser, mfaCode, mfaType);
    checkIsLoggedIn();
  } catch (e) {
    errorHandler(e);
  }
});

export const setIsLoggedIn = createEvent<boolean>();
export const setHasAccess = createEvent<boolean>();
export const setShippingPoint = createEvent<string | null>();
export const updateShippingPoint = createEvent<string | null>();
export const setTheme = createEvent<ThemeColor>();
export const updateTheme = createEvent<ThemeColor>();
export const newVersionIsReleased = createEvent();

export const signOut = createEvent();

appStore
  ?.on(setIsLoggedIn, (state, isLoggedIn) => ({
    ...state,
    isLoggedIn,
  }))
  ?.on(setHasAccess, (state, hasAccess) => ({
    ...state,
    hasAccess,
  }))
  ?.on(setShippingPoint, (state, newShippingPoint) => {
    return {
      ...state,
      shippingPoint: newShippingPoint,
      isShippingPointSet: Boolean(newShippingPoint?.length),
    };
  })
  ?.on(updateShippingPoint, (state, newShippingPoint) => {
    handleLocalStorage({ shippingPointName: newShippingPoint });
    return {
      ...state,
      shippingPoint: newShippingPoint,
      isShippingPointSet: Boolean(newShippingPoint?.length),
    };
  })
  ?.on(setTheme, (state, newThemeColor) => {
    return {
      ...state,
      themeColor: newThemeColor,
    };
  })
  ?.on(newVersionIsReleased, (state) => {
    return {
      ...state,
      isNewVersionReleased: true,
    };
  })
  ?.on(updateTheme, (state, newThemeColor) => {
    handleLocalStorage({ selectedTheme: newThemeColor });
    return {
      ...state,
      themeColor: newThemeColor,
    };
  })
  ?.on(signOut, () => {
    aws.signOut();
    window.localStorage.removeItem('shippingPointName');
    return {
      ...initAppStore,
    };
  });
