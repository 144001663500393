import { ProductToUpdate } from 'shared/types';
import {
  DescriptionComponentContainer,
  DescriptionComponentSubItem,
  DescriptionComponentTitle,
  deliveryNoteNumCell,
  distributedCell,
  editableCell,
} from './styles';
import { getLayout } from 'shared/helpers/getLayout';
import { useTranslations } from 'shared/hooks/useTranslations';

// TODO: 99 refactor

const DescriptionComponent = (el: any) => {
  const t = useTranslations();
  const { distributed = 0, justProduced = 0, amount = 0 } = el?.row || {};
  return (
    <DescriptionComponentContainer>
      <DescriptionComponentTitle>
        {`${el?.row?.customerNum} ${el?.row?.internalName}`}
      </DescriptionComponentTitle>
      <DescriptionComponentSubItem>
        <p>{t('common.ordered')}:</p>
        <p>{amount || 0}</p>
      </DescriptionComponentSubItem>
      <DescriptionComponentSubItem>
        <p>{t('common.distributed')}:</p>
        <p>{distributed || 0}</p>
      </DescriptionComponentSubItem>
      <DescriptionComponentSubItem>
        {/* TODO: translations */}
        <p>{`${t('common.toDistribute')}`}:</p>
        <p>{justProduced || 0}</p>
      </DescriptionComponentSubItem>
      <DescriptionComponentSubItem>
        {/* TODO: translations */}
        <p>{`${t('common.difference')}`}:</p>
        <p>{Number(distributed) + Number(justProduced) - Number(amount) || 0}</p>
      </DescriptionComponentSubItem>
    </DescriptionComponentContainer>
  );
};

export const getColumns = (t: (v: string) => string, products: ProductToUpdate[]) => {
  switch (getLayout()) {
    case 'MOBILE':
      return mobileColumns(t, products);
    default:
      return columns(t, products);
  }
};

export const mobileColumns = (t: (v: string) => string, products: ProductToUpdate[]) => [
  // {
  //   field: 'customerNum',
  //   headerName: t('common.customerNumber'),
  //   width: 120,
  // },
  {
    field: 'mainField',
    headerName: t('common.productNumber'),
    flex: 1,
    renderCell: DescriptionComponent,
  },
];

export const columns = (t: (v: string) => string, products: ProductToUpdate[]) => [
  {
    field: 'customerNum',
    headerName: t('common.customerNumber'),
    width: 120,
  },
  {
    field: 'internalName',
    headerName: t('common.companyName'),
    width: 200,
    flex: 1,
  },
  {
    field: 'deliveryNoteNum',
    headerName: t('common.deliveryNote'),
    width: 125,
    renderCell: deliveryNoteNumCell,
  },
  {
    field: 'amount',
    headerName: t('common.ordered'),
    width: 125,
    type: 'number',
    valueFormatter: (e: any) => +e.value,
  },
  {
    field: 'distributed', // already distributed amount
    headerName: t('common.distributed'),
    width: 125,
    type: 'number',
    editable: true,
    renderCell: distributedCell(products),
  },

  {
    field: 'justProduced',
    headerName: t('common.toDistribute'),
    width: 125,
    editable: true,
    type: 'number',
    renderCell: editableCell,
  },

  {
    // (To distribute + Distributed) - Ordered
    field: 'difference',
    headerName: t('common.difference'),
    type: 'number',
    width: 125,
    valueGetter: ({ row }: any) => {
      const { distributed = 0, justProduced = 0, amount = 0 } = row;
      return Number(distributed) + Number(justProduced) - Number(amount) || 0;
    },
  },
];

export const aggregationModel = {
  amount: 'sum',
  distributed: 'sum',
  justProduced: 'sum',
  difference: 'sum',
};
