import styled from 'styled-components';

export const MobileCellComponentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  width: 100%;
  padding: 20px 0;
  p,
  div {
    font-weight: 600;
  }
`;

export const MobileCellHeader = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
`;

export const MobileCellStatus = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / -1;
  display: flex;
  flex-direction: column;
`;

export const MobileCellAmount = styled(MobileCellStatus)`
  grid-column: 2 / -1;
`;
