export const getProductStatus = (product: any) => {
  const listOrders = product?.productionListOrders || [];
  const statuses = listOrders?.map((order: any) => order.productStatus || 'To do');

  const uniqueStatuses = [...new Set(statuses)];

  let productStatus: string;
  if (uniqueStatuses.length === 1) {
    productStatus = uniqueStatuses[0] as string;
  } else {
    productStatus = 'Pending';
  }
  return product ? getStatusText(productStatus) : undefined;
};

export const getStatusText = (status: string) => {
  if (status === 'To do' || status === 'TO_DO') return 'To do';
  if (status === 'In progress' || status === 'IN_PROGRESS') return 'In progress';
  if (status === 'Pending' || status === 'PENDING') return 'Pending';
  if (status === 'Done' || status === 'DONE') return 'Done';
  return '';
};
