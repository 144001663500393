import { Text } from 'entities/Text';
import styled from 'styled-components';

type SettingsTemplateContainerProps = {
  ignoreHeight?: boolean;
};

export const SettingsTemplateContainer = styled('div')<SettingsTemplateContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  min-height: calc(470px - 49px);
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled(Text)`
  // TODO: remove importants
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: 0.15px !important;
  text-align: left;
  align-self: flex-start;
  margin-top: 50px !important;
  margin-bottom: 34px !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
