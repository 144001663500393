import { FC, useState } from 'react';
import Button from '@mui/lab/LoadingButton';
import { ButtonContainer, ConfirmationModalStyled } from './styles';
import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Text } from 'entities/Text';

export const ConfirmationModalComponent: FC<IConfirmationModal> = ({
  text,
  onClose,
  onConfirm,
  isDisabled,
  noText,
  yesText,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslations();

  const onYes = () => {
    setIsLoading(true);
    onConfirm();
  };

  return (
    <ConfirmationModalStyled>
      <Text>{text}</Text>
      {children}
      <ButtonContainer>
        <Button onClick={onClose} disabled={isDisabled || isLoading} variant="outlined">
          {noText || t('common.no')}
        </Button>
        <Button disabled={isDisabled} onClick={onYes} loading={isLoading} variant="contained">
          {yesText || t('common.yes')}
        </Button>
      </ButtonContainer>
    </ConfirmationModalStyled>
  );
};

interface IConfirmationModal {
  serialNumber: string;
  onClose: () => void;
  onConfirm: () => void;
  text: string;
  isDisabled?: boolean;
  noText?: string;
  yesText?: string;
}

export const ConfirmationModal = withModalHOC(ConfirmationModalComponent);
