import { createEvent } from 'effector';
import { initStartModalStore } from './types';
import { ProductToUpdate } from 'shared/types';
import { startModalStore } from '..';

export const updateAmountType = createEvent<'fixed' | 'calculate'>();
export const updateFixedNumber = createEvent<number>();
export const updateCalcNumber = createEvent<number>();
export const setProductsToUpdDistributed = createEvent<ProductToUpdate[]>();
export const discardStartModalState = createEvent();

startModalStore
  .on(discardStartModalState, () => {
    return {
      ...initStartModalStore,
    };
  })
  .on(updateAmountType, (state, newType) => {
    return {
      ...state,
      amountType: newType,
    };
  })
  .on(updateFixedNumber, (state, newFixedNumber) => {
    return {
      ...state,
      fixedNumber: newFixedNumber,
    };
  })
  .on(updateCalcNumber, (state, newCalcNumber) => {
    return {
      ...state,
      calcNumber: newCalcNumber,
    };
  })
  .on(setProductsToUpdDistributed, (state, newProductsToUpdateDistributed) => {
    return {
      ...state,
      productsToUpdateDistributed: newProductsToUpdateDistributed,
    };
  });
