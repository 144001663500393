import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import { FC, SyntheticEvent } from 'react';

export const TabsController: FC<ITabsController> = ({ tabs, tabsState }) => {
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    tabsState[1](newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        key={tabsState?.[0]}
        value={tabsState?.[0]}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tabs?.map((tab, index) => (
          <Tab
            key={`${tab.label}_${index}`}
            label={tab.label}
            disabled={tab.isDisabled}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

type TabType = {
  label: string;
  isDisabled?: boolean;
};

interface ITabsController {
  tabs: TabType[];
  tabsState: [number, (value: number) => void];
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
